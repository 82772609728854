.partReDraw {
  width: 1100px;
  height: 650px;
  border-radius: 30px;
  background-color: var(--background-color);
  padding: 30px 40px 40px;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titleText {
      display: flex;
      align-items: center;

      .name {
        font-weight: bold;
        font-size: calc(var(--font-size-add) + 18px);
        color: var(--text-color);
        margin-right: 10px;
      }

      .tips {
        color: #FF7B00;
        font-size: calc(var(--font-size-add) + 12px);
      }
    }

    .close {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .content {
    margin-top: 24px;
    display: flex;

    .imgBox {
      width: 600px;
      height: 536px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .canvas {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        z-index: 9;
        user-select: none;
        left: 0;
        top: 0;
        opacity: 0.4;
      }
    }

    .tools {
      margin-left: 30px;
      flex: 1;

      .style,
      .brush,
      .prompt {
        margin-bottom: 30px;
      }

      .title {
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--text-color);
        margin-bottom: 16px;
        font-weight: bold;
      }


      .sliderStyle {
        position: relative;
        color: red;
        height: 6px;
        width: 100%;
        padding: 16px 0;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        touch-action: none;
        -webkit-tap-highlight-color: transparent;
      }

      .sliderRail {
        display: block;
        position: absolute;
        width: 100%;
        height: 6px;
        border-radius: 5px;
        background-color: var(--secondary-bg);
      }

      .sliderMark {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 99%;
        transform: translateX(-50%);
      }

      .sliderTrack {
        display: block;
        position: absolute;
        height: 6px;
        border-radius: 5px;
        background: var(--text-color);
      }

      .sliderMark {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 99%;
        transform: translateX(-50%);
      }

      .sliderThumb {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: -6px;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border-radius: 50%;
        outline: 0;
        border: 2px solid var(--text-color);
        background-color: #fff;
        transition-property: box-shadow, transform;
        transition-timing-function: ease;
        transition-duration: 120ms;
        transform-origin: center;
      }

      .inputs {
        width: 100% !important;
        height: 160px;
        background-color: var(--secondary-bg);
        border-radius: 20px;
        border: 1px solid transparent;
        padding: 16px;
        box-sizing: border-box;
        font-size: calc(var(--font-size-add) + 16px);
        position: relative;
        border: 1px solid transparent;

        &:focus-within {
          border-color: rgb(var(--primary-color));
        }

        .area {
          border: none;
          background-color: transparent;
          width: 100%;
          height: 100%;
          font-size: calc(var(--font-size-add) + 14px);
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        }

        .length {
          position: absolute;
          right: 16px;
          bottom: 16px;
          font-size: calc(var(--font-size-add) + 14px);
          color: #666666;
        }
      }

      .post {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.vip {

          .num {
            text-decoration: line-through;
          }

          .btn {
            background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%);
            color: #A54500;

            svg {
              width: 22px;
              height: 22px;
            }
          }
        }

        .price {
          display: flex;
          align-items: center;
          font-size: calc(var(--font-size-add) + 14px);

          .num {
            font-weight: bold;
            color: --var(text-color);
            font-size: calc(var(--font-size-add) + 16px);
          }
        }
      }
    }
  }
}