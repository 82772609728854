.modalbox {
  background-color: var(--background-color);
  border-radius: 30px;
  padding: 24px;
  min-width: 440px;
  max-width: 600px;
  box-sizing: border-box;

  .titlebox {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .alertTitle {
      font-size: calc(var(--font-size-add) + 18px);
      color: var(--text-color);
      font-weight: bold;
    }
  }

  .titleheadbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .titleClosebut {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }

  .content {
    margin-top: 20px;
    color: var(--text-color);
    font-size: calc(var(--font-size-add) + 16px);
    padding-left: 16px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .cancelbutton {
      margin-right: 16px;
    }
  }
}