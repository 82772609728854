.title {
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 20px;
}

.titleBox {
  margin-top: 30px;
  margin-bottom: 15px;
}