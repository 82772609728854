.version {
  width: 660px;
  font-size: 0;

  .topBg {
    width: 100%;
    height: 200px;
  }

  .content {
    width: 100%;
    height: 344px;
    padding: 0 50px 40px;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: -18px;
    border-radius: 0px 0px 30px 30px;

    .versionText {
      height: 170px;
      width: 100%;
      padding: 0 18px;
      box-sizing: border-box;
      margin-bottom: 25px;
      font-size: calc(var(--font-size-add) + 16px);
      font-weight: 400;
      color: var(--text-color);
    }

    .tips {
      display: flex;
      align-items: flex-start;
      font-size: calc(var(--font-size-add) + 14px);
      color: var(--secondary-text);
      margin-bottom: 20px;

      &.hidden {
        visibility: hidden;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        margin-top: 2px;
      }
    }

    .btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: calc(var(--font-size-add) + 16px);

      .ignore {
        color: rgba(var(--secondary-text-rbg), 0.5);
        margin-right: 40px;
        cursor: pointer;
      }

      .btn {
        background: linear-gradient(90deg, #594CF6 0%, #9583FF 100%);
        border-radius: 18px;
        color: #fff;
        width: 144px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        svg {
          margin-right: 10px;
          width: 24px;
          height: 24px;

          path {
            fill: #fff;
          }
        }
      }
    }
  }
}