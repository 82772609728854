.optimization {
  width: 510px;
  height: 350px;
  padding: 24px 30px 30px 30px;
  box-sizing: border-box;
  background-color: var(--background-color);
  border-radius: 30px;

  .title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 18px);
    color: var(--text-color);

    .iconText {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  .generateArea {
    height: 180px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background-color: var(--secondary-bg);
    margin-bottom: 30px;
    margin-top: 20px;
    border-radius: 18px;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ok {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;

      .loading {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 4px;
        animation: zoomStopIcon 0.5s infinite alternate;
      }
    }
  }
}

@keyframes zoomStopIcon {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.1);
  }
}