.swiper {
  width: 800px;
  height: 800px;
  border-radius: 30px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(30px);
  position: relative;
}

.swiperbefore {
  position: absolute;
  background-image: url('../../../../assets/png/speechBG.png');
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(0.7) blur(10px);
}

.musicPlayer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.content {
  display: flex;
  margin: 0px 100px;
  width: calc(100% - 200px);
  height: 70%;
  align-items: center;
  justify-content: space-around;
}

.cover {
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 20px;
  width: 240px;
  height: 240px;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.lyricsContainer {
  height: 400px;
  max-width: 100%;
  overflow-y: auto;
  position: relative;
}

.lyricsContainer::-webkit-scrollbar {
  display: none;
}

.lyricsContainer>div {
  transition: scroll-behavior 0.3s ease-in-out;
  font-size: 16px;
  line-height: 40px;
}

.activeLyric {
  font-weight: bold;
  transition: scroll-behavior 0.3s ease-in-out;
}

.lyrics {
  flex-grow: 1;
  width: 200px;
  max-width: 200px;
  overflow-y: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding: 10px;
  height: 150px;
  color: #ddd;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 44px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.boldLyric {
  font-weight: bold;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0px 100px;
  z-index: 3;

  svg {
    cursor: pointer;
  }

  button {
    background: #444;
    border: none;
    color: #fff;
    padding: 5px 15px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #666;
    }
  }

  .loader {
    display: inline-block;
    width: 38px;
    height: 38px;
    border: 2px solid #fff;
    margin: 0px 30px;
    border-radius: 50%;
    border-top: 2px solid transparent;
    animation: spin 1s linear infinite;
    position: relative;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.audioControls {
  width: calc(100% - 200px) !important;
  z-index: 3;
  display: flex;
  align-items: center;

  .slider {
    flex: 1;
    margin: 0 10px;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: rgbs(255, 255, 255, 0.3);
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }

  .slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: red;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }

  .slider {
    margin: 0px 100px;
    width: 100% !important;
    margin: 10px 0;
    z-index: 3;
  }

  .verticalSlider {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 150px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
    overflow: hidden;
    margin-left: 20px;
    position: relative;
    transform: rotate(-90deg);
    z-index: 3;
  }

  .verticalSlider::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, blue, #ddd);
    z-index: 3;
  }

  .verticalSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: white;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #999;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .verticalSlider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: white;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #999;
  }
}


.time {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 200px);
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
  z-index: 3;
}