.quality {
  .title {
    margin-top: 0;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--text-color);
    font-weight: 400;

    .item {
      margin-right: 110px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.active {
        .radio {
          border: 2px solid rgb(var(--primary-color));
        }
      }

      .radio {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
        border: 2px solid var(--secondary-text);
        display: flex;
        align-items: center;
        justify-content: center;

        .selected {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: rgb(var(--primary-color));
        }
      }
    }
  }
}