.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-height: 900px) {
  .parent {
    margin-top: 100px;
  }
}

.child {
  padding: 40px 20px 0px;
  position: relative;
}

.allbox {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.boxtext {
  font-weight: 900;
  font-size: 30px;
  color: var(--text-color);
}

.signInbox {
  width: 520px;
  // height: 504px;
  border-radius: 30px;
  margin-bottom: 0px;
  padding: 20px 40px;
  background-image: url('../../../../assets/png/InviteBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  flex-direction: column;
}

.signInboxpng {
  position: absolute;
  top: 15px;
  right: 50px;
  width: 170px;
  height: 170px;
  opacity: 0.9;
}

.signInbutton {
  width: 100% !important;
  height: 48px;
  background: #565CFA;
  color: var(--text-reverse-color);
  border-radius: 30px
}

.signInten {
  margin-right: 10px;
}

.signInbuttonbox {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.signIntitlebox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invitetext {
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 40px);
  color: var(--text-color);
}

.signIncontentsbox {
  width: 100%;
  margin: 20px 0px;
  justify-content: space-between;
}

.signInboxtext {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
}

.signInboxvalue {
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 20px);
  color: var(--text-color);
}

.signInbuttontext {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
}

.rightbox {
  justify-content: end;
  margin-bottom: 10px;

  .balancebutton {
    color: var(--text-reverse-color);
    width: 124px;
    height: 40px;
    background: #565CFA;
    border-radius: 12px;

    svg {
      width: 20px;
      height: 20px;
      margin-top: 3px;
      margin-right: 3px;
    }
  }

  .sharebutton {
    margin-left: 30px;
    color: var(--text-reverse-color);
    width: 124px;
    height: 40px;
    background: #09AD19;
    border-radius: 12px;

    svg {
      width: 20px;
      height: 20px;
      margin-top: 3px;
      margin-right: 3px;
    }
  }
}

.fatherbox {
  flex-direction: column;
}

.boxtitletext {
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
}

.contentbox {
  width: 540px;
  height: 28px;
  padding: 10px 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  border-radius: 30px;

  .balancetext {
    font-weight: 500;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
  }
}

.fatherbox {
  max-width: 600px;
  margin: auto;
  padding: 12px;
  border-radius: 8px;
}

.fathertitlebox {
  justify-content: space-between;
}

.fathericonbox {
  justify-content: center;
  align-items: center;
}

.boxtitletext {
  font-size: calc(var(--font-size-add) + 24px);
  font-weight: bold;
  margin-bottom: 8px;
}

.icon {
  margin-right: 8px;
}

.listbox {
  height: 360px;
  width: 100%;
}

.boxContents {
  height: 400px;
  width: 100%;
  // max-height: 400px;
  // width: 600px;
  flex-wrap: wrap;
  // overflow-y: auto;
  // overflow-x: hidden;
  gap: 10px;
}

.commissionItem {
  width: 250px;
  height: 131px;
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: var(--background-color);
  flex-direction: column;
}

.unInvitbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  img {
    width: 100px;
    height: 100px;
  }
}

.unInvittext {
  font-weight: 500;
  font-size: 14px;
  color: #999999;
}