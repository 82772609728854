.listbox {
  width: 338px;
  height: 100%;
  background: #FFFFFF;
  border-right: 1px solid #DDDDDD;
  transition: all 0.3s ease;
}

.listbox.hide {
  width: 0px;
  padding: 0px;
  overflow: hidden;
}

.topBox {
  margin: 20px 15px;
  cursor: pointer;

  svg {
    width: 24px !important;
    height: 24px !important;
    transform: rotate(180deg);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #999999;
  }
}

.titlebox {
  width: 278px;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
  margin: 30px 29px 20px;
  align-items: center;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 19px;
  }

}

.flexContainer {
  width: 278px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: hidden;
  margin: 0px 29px;
  transition: max-height 0.3s ease-out;
}

.flexContainerCollapsed {
  max-height: 100px;
  /* 根据行高调整这个值，确保两行的高度 */
}

.flexContainerExpanded {
  max-height: none;
}

.tagsbox {
  // width: 94px;
  height: 16px;
  padding: 10px 16px;
  border-radius: 14px 14px 14px 14px;
  border: 1px solid rgba(153, 153, 153, 0.4);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    width: 18px;
    height: 18px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  }
}

.selected {
  border: 1px solid rgba(250, 250, 250, 0.1);
  background-color: rgba(86, 92, 250, 0.1);
  // border: 1px solid #f5f5f5;
}

.tagstext {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  margin-left: 3px;
}

.tagsbox:hover .tagstext {
  color: #565CFA;
}

// .selected:hover .tagstext {
//   color: #fff;
// }

.selected .tagstext {
  color: #565CFA;
  font-weight: bold;
  font-size: 14px;
}

.specialTag {
  background-color: #dce8ff;
  color: #007bff;
}

.toggleButton {
  margin-top: 10px;
  font-size: 12px;
  color: rgba(153, 153, 153, 0.6);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }
}

.titleinput {
  width: 100%;
  height: 66px !important;
  background: #F5F5F5;
  border-radius: 16px;
  border: 0px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #999999;
}

.toolslist {
  height: calc(100% - 50px);
}

.toolsBox {
  margin-top: 20px;
  height: calc(100% - 295px);
  flex-direction: column;
}

.opentoolsBox {
  height: calc(100% - 290px);
  flex-direction: column;
}

.toolsbox {
  width: 278px;
  flex-direction: column;
  margin: 0px 29px;
}

.toolsBoxtitle {
  width: 278px;
  margin: 0px 29px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.toolBox {
  width: calc(100% - 20px);
  height: 50px;
  justify-content: space-between;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 10px;

  .contName {
    width: 200px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .contSynopsis {
    width: 200px;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.noResults {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;

  svg {
    width: 140px;
    height: 140px;
  }

  p {
    width: 140px;
    justify-content: center;
    text-align: center;
  }
}

.selecttoolBox {
  width: calc(100% - 20px);
  height: 50px;
  justify-content: space-between;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #EEEFFE;

  .contName {
    width: 200px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    color: #565CFA;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .contSynopsis {
    width: 200px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(86, 92, 250, 0.4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.toolBox:hover {
  background-color: #f5f5f5;
}

.avatar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 16px;
  margin-right: 8px;
}

.contentbox {
  width: calc(100%);
  height: 40px;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}