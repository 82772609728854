.allbox {
  width: calc(100% - 50px);
  padding: 0px 50px;
  justify-content: end;
}

.casebutton {
  padding: 0px 10px;
  min-width: 120px;
  width: auto !important;
  height: 43px;
  background-color: #fff;
  margin-left: 30px;
  font-weight: bold;
  font-size: 16px;
  color: #565CFA;
  border-radius: 14px 14px 14px 14px;
  border: 1px solid #565CFA;

  p {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }
}

.casebuttonbl {
  padding: 0px 10px;
  min-width: 120px;
  width: auto !important;
  height: 43px;
  background-color: transparent;
  margin-left: 30px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  border-radius: 14px;
  border: 1px solid #fff;

  p {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }
}

.stopicon {
  margin-right: 5px;
  background: #fff;
  border-radius: 4px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  animation: zoomStopIcon 0.4s infinite alternate;
}

.vipstopicon {
  margin-right: 5px;
  background: #A54500;
  border-radius: 4px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  animation: zoomStopIcon 0.4s infinite alternate;
}

@keyframes zoomStopIcon {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.2);
  }
}

.vipstartbutton {
  padding: 0px 10px;
  min-width: 120px;
  width: auto !important;
  height: 44px;
  background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%), #565CFA;
  box-shadow: 0px 4px 10px 0px rgba(186, 89, 0, 0.24);
  border-radius: 14px;
  font-weight: bold;
  font-size: 16px;
  color: #A54500;
  margin-left: 30px;

  p {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }
}

.vipstartbutton_disabled {
  padding: 0px 10px;
  min-width: 120px;
  width: auto !important;
  height: 44px;
  background: rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 0px 0px;
  border-radius: 14px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  margin-left: 30px;

  p {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  svg {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }
}

.startbutton {
  padding: 0px 10px;
  min-width: 120px;
  width: auto !important;
  height: 43px;
  color: #fff;
  background: #565CFA;
  box-shadow: 0px 4px 10px 0px rgba(14, 0, 186, 0.24);
  border-radius: 14px;
  margin-left: 30px;

  p {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }
}

.startbutton_disabled {
  padding: 0px 10px;
  min-width: 120px;
  width: auto !important;
  height: 43px;
  color: #fff;
  background: rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 0px 0px;
  border-radius: 14px;
  margin-left: 30px;

  p {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }
}

.modalicon {
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.selecttext {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  align-items: center;
  text-align: center;
}

.modalbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 164px;
  height: 27px;
  padding: 10px 15px;
  border-radius: 14px 14px 14px 14px;
  border: 1px solid rgba(153, 153, 153, 0.5);
  transition: all 0.2s ease;

  .pricetext {
    font-size: 10px;
    color: #999999;
    align-items: center;

    svg {
      width: 10px;
      height: 10px;
      padding-top: 2px;
      margin: 0px 1px 0px 2px;

      path {
        fill: #999
      }
    }
  }

  .Vippricetext {
    margin-left: 3px;
    font-weight: 400;
    font-size: 10px;
    color: #FF7B00;
    white-space: nowrap;
  }

  .freetext {
    width: 52px;
    height: 14px;
    background: #FF7A00;
    justify-content: center;
    border-radius: 10px;
    font-size: 10px;
    color: #fff;
  }

  .modalboxiconbl {
    width: 22px;
    height: 22px;
    margin-right: 5px;

    path {
      fill: #fff
    }
  }

  .modalboxicon {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  .modalboxopen {
    margin-left: 5px;
    width: 16px;
    height: 16px;
  }

  .modalboxopenbl {
    margin-left: 5px;
    width: 16px;
    height: 16px;

    path {
      fill: #fff
    }
  }
}

.selectmodalbox {
  border: 1px solid #565CFA;
}

.selectmodels {
  position: absolute;
  top: -160px;
  left: 0px;
  width: 280px;
  height: 128px;
  padding: 5px 20px 10px;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  border-radius: 20px;
  overflow: hidden;

  .selectmodel {
    padding: 14px 10px;
    // border-bottom: 1px solid #ddd;
    position: relative;
    cursor: pointer;

    .Select {
      position: absolute;
      left: -10px;
      top: 30px;
    }

    .selectName {
      font-weight: bold;
      font-size: 14px;
      color: #565CFA;
    }

    .selectTips {
      font-size: 14px;
      color: rgba(86, 92, 250, 0.60);
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .selectname {
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }

    .selecttips {
      font-size: 14px;
      color: #999;
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .selectmodel:first-child {
    padding: 14px 10px 16px;
    border-bottom: 1px solid #ddd;
  }

  .tagtext {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 17px;
    width: 70px;
    height: 24px;
    color: #fff;
    background: #FF7A00;
    border-radius: 10px 10px 10px 0px;
  }

  .selectprice {
    margin-left: 13px;
    justify-content: center;
    align-items: center;
    color: #565CFA;

    svg {
      margin-top: 2px;
      padding: 0px 1px 0px 2px;
    }

    svg path {
      fill: #565CFA;
    }
  }

  .pricetext {
    margin-left: 13px;
    justify-content: center;
    align-items: center;

    svg {
      margin-top: 2px;
      padding: 0px 1px 0px 2px;
    }
  }
}