.header {
  justify-content: space-between;
  padding: 22px 20px;
  border-bottom: 1px solid var(--border-color);
  position: relative;
  height: 80px;
  box-sizing: border-box;

  .hiddeNav {
    width: 34px;
    height: 34px;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: absolute;
    left: 20px;
    box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);

    &.hide {
      transform: rotate(180deg);
    }
  }

  .left {
    display: flex;
    align-items: center;

    .icon {
      width: 36px;
      height: 36px;
      margin-right: 14px;
    }

    .title {
      font-size: calc(var(--font-size-add) + 20px);
      font-weight: 900;
      color: var(--text-color);
    }
  }
}