.selectBox {
  width: 100%;
  background-color: var(--secondary-bg);
  height: 50px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  .select {
    border: none;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 17px 20px;
    cursor: pointer;
  }

  .arrow {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s;

    &.open {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}


.listbox {
  width: 100%;
  max-height: 300px;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  padding: 0 10px 0 20px;
  border-radius: 16px;
  background-color: var(--background-color);
  margin-top: 10px;
  box-sizing: border-box;

  &.closed {
    display: none;
  }
}