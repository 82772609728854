.container {
  height: calc(100% - 200px);
}

.content {
  padding: 30px 87px 30px;
  box-sizing: border-box;
  width: 730px;
  margin: 0 auto;

  .type {
    width: 330px;
    height: 70px;
    border-radius: 40px;
    padding: 0px 10px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    margin: 0px auto;

    .chatType {
      width: 110px;
      padding: 5px 20px;
      height: 40px;
      border-radius: 30px;
      margin-right: 30px;
      font-size: calc(var(--font-size-add) + 16px);
      color: #999;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &:last-of-type {
        margin-right: 0;
      }

      .icon {
        width: 0;
        height: 0;
        transition: all 0.3s ease;
      }

      &.active {
        color: #fff;
        background: #565CFA;
        font-weight: bold;
        font-size: calc(var(--font-size-add) + 18px);

        .icon {
          width: 18px;
          height: 8px;
        }
      }
    }
  }
}

.create {
  position: sticky;
  bottom: 0;
  height: 112px;
  background-color: var(--background-color);
  border-top: 1px solid rgba(var(--secondary-text-rbg), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 87px;

  .btn {
    width: 442px;
    height: 60px;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 16px);
    line-height: 20px;

    .start {
      margin-right: 6px;
    }

    .price {
      display: flex;
      align-items: center;

      svg {
        margin-left: 3px;
        width: 16px;
        height: 16px;

        path {
          fill: #fff;
        }
      }
    }
  }

  .vipbtn {
    width: 442px;
    height: 59px;
    background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%);
    border-radius: 20px;

    .vipprice {
      display: flex;
      align-items: center;

      svg {
        width: 22px;
        height: 22px;
      }

      p {
        margin-left: 5px;
        height: 22px;
        font-weight: bold;
        font-size: 16px;
        color: #A54500;
      }
    }

  }
}

.historyTitle {
  height: 80px;
  border-bottom: 1px solid var(--border-color);
  padding: 28px 20px;
  box-sizing: border-box;

  .text {
    font-size: calc(var(--font-size-add) + 20px);
    font-weight: bold;
    color: var(--text-color);
    line-height: 20px;
  }
}

.listBox {
  padding: 30px 0px 110px;
  width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  .historyItem {
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 160px;
    position: relative;

    &.historyItemGen3 {
      width: 280px;
    }


    &:not(:nth-last-of-type(-n+2)):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 220px;
      height: 1px;
      background-color: rgba(221, 221, 221, 0.5);
    }

    .bottomInfo {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      .infoBox {
        display: flex;

        &:first-child {
          margin-bottom: 6px;
        }
      }

      .priceBox {
        align-items: center;
        justify-content: space-between;

        .aimark {
          padding-top: 4px;
          border-radius: 20px;
          color: var(--secondary-text);
          font-size: calc(var(--font-size-add) + 12px);
          font-weight: 400;
        }
      }
    }
  }
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 140px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;

  .moreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }

    &.del p {
      color: #FF2922;
    }
  }
}

.balancebox {
  width: 440px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 24px 30px;
  border-radius: 30px;
  position: relative;
}

.notbalancebox {
  width: 890px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 24px 30px;
  border-radius: 30px;
  position: relative;
}

.notbalanceTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  p {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}

.texttips {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  padding: 0px 10px;
}

.modelbuttonbox {
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
}

.Savebutton {
  background: #565CFA;
  color: var(--text-reverse-color);
  width: 130px;
  margin-left: 10px;
}

.Savebutton:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
}

.lyricBox {
  width: 410px;
  height: 310px;
  padding: 15px;
  background: #F5F5F5;
  border-radius: 18px;
}

.typeText {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
}

.groupBox {
  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    margin-bottom: 150px;
  }

  .title {
    font-weight: 400;
    font-size: 16px;
    color: #999999;
  }

  .title.active {
    font-weight: 400;
    font-size: 16px;
    color: #565CFA;
  }

  .valuesBox {
    position: absolute;
    left: 0px;
    top: 90px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;

    .selectedvalue {
      padding: 8px 15px;
      background: rgba(86, 92, 250, 0.16);
      border-radius: 30px;
      margin-right: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #565CFA;
      cursor: pointer;
    }

    .value {
      padding: 8px 15px;
      background: #F5F5F5;
      border-radius: 30px;
      margin-right: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
    }
  }
}

.selectValuesBox {
  margin-top: 30px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;

  p {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }

  .selectValueBox {
    padding: 0px 20px;
    height: 34px;
    background: #565CFA;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;

    p {
      max-width: 100px;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    div {
      padding: 0px 1px 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      font-weight: bold;
      font-size: 12px;
      background: #FFFFFF;
      color: #565CFA;
      border-radius: 50%;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.fullbox {
  width: 100%;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    width: 150px;
    height: 150px;
  }

  .prompt {
    color: #999;
  }
}