.flexColumn {
  background: #f9f9f9;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.centerItemsbox {
  height: 31px !important;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;

  .title {
    height: 31px;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    margin-right: 10px;
  }

  .prompt {
    height: 31px;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FF7B00;
  }
}

.fullbox {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullnobox {
  height: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullHeightbox {
  height: 100%;
  // margin: 19px 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-left: 1px solid #DDDDDD;

  svg {
    width: 150px;
    height: 150px;
  }

  .notextarea {
    height: 0px;
    border: 0px;
    padding: 0px;
    margin: 0px;
    background-color: #f9f9f9;
    resize: none;
  }

  .textarea {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    width: calc(100% - 61px);
    height: calc(100% - 30px);
    border: 0px;
    padding: 30px 30px 0px;
    background-color: #f9f9f9;
    resize: none;
  }
}

.text {
  &.title {
    font-size: 16px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  &.prompt {
    font-size: 14px;
    color: #f7a955;
  }

  &.loading {
    color: rgba(153, 153, 153, 0.5);
    font-size: 14px;
  }
}

.button {
  height: 40px;
  width: 92px;
  color: #333;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  pointer-events: all;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(110, 110, 110, 0.24);
  margin-right: 1rem;

  svg {
    margin-top: 1px;
    margin-right: 3px;
  }

  &.disabled {
    color: #999;
    pointer-events: none;
    background-color: rgba(153, 153, 153, 0.2);
    box-shadow: none;
  }
}

.button:hover {
  color: #565CFA;
}

.footBox {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 36px 30px;
}

.footTextBox {
  align-items: center;
}

.buttonBox {
  display: flex;
  justify-content: space-between;
}

.contentsflex {
  background-color: #f9f9f9;
  font-size: 14px;
  color: #333333;
  width: calc(100% - 61px);
  height: calc(100% - 30px);
  border: 0px;
  padding: 30px;
}

// Modal.module.scss
.modalOverlayCustom {
  background-color: rgba(0, 0, 0, 0.5); // 改变遮罩背景颜色
}

.modalContentCustom {
  background-color: #fff; // 改变内容区域背景颜色
  border-radius: 8px; // 添加圆角
  padding: 20px; // 增加内边距
}

.modalHeaderCustom {
  font-size: 18px; // 改变标题文字大小
  color: #333; // 改变标题文字颜色
  border-bottom: 1px solid #e5e5e5; // 增加底部边框
  padding-bottom: 10px; // 标题底部内边距
}

.modalBodyCustom {
  font-size: 16px; // 改变正文文字大小
  color: #666; // 改变正文文字颜色
  margin: 10px 0; // 增加正文的外边距
}

.modalFooterCustom {
  border-top: 1px solid #e5e5e5; // 增加顶部边框
  padding-top: 10px; // 添加顶部内边距
  display: flex;
  justify-content: flex-end; // 右对齐按钮
}

.cancelButton {
  background-color: #f5f5f5; // 改变取消按钮背景颜色
  color: #333; // 改变取消按钮文字颜色
  border: none; // 移除边框
  padding: 10px 15px; // 增加内边距
  border-radius: 4px; // 圆角
  cursor: pointer; // 鼠标悬浮样式
}

.cancelButton:hover {
  background-color: #e0e0e0; // 悬浮状态下背景颜色
}

.confirmButton {
  background-color: #007bff; // 改变确认按钮背景颜色
  color: #fff; // 改变确认按钮文字颜色
  border: none; // 移除边框
  padding: 10px 15px; // 增加内边距
  border-radius: 4px; // 圆角
  cursor: pointer; // 鼠标悬浮样式
}

.confirmButton:hover {
  background-color: #0056b3; // 悬浮状态下背景颜色
}

.hisFlex {
  background: #f9f9f9;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  border-left: 1px solid #DDDDDD;

  .centerItemsbox {
    height: 31px !important;
    padding: 24px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    border-left: 0px;

    .title {
      height: 31px;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      margin-right: 10px;
    }

    .prompt {
      height: 31px;
      margin-top: 10px;
      margin-left: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #FF7B00;
    }
  }

  .hisBox {
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .historyValue {
      width: 530px;
      background: #FFFFFF;
      border-radius: 20px;
      margin: 0px auto 20px;
      position: relative;

      .historyValuebox {
        width: 490px;
        padding: 20px;
        flex-direction: column;

        .hisTitleBox {
          display: flex;
          justify-content: space-between;

          p {
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }

          .more {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }

        .hisContentsBox {
          margin: 8px 0px 8px;
          height: 20px;
          max-width: 450px;
          font-size: 14px;
          color: #999999;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .hisDateBox {
          justify-content: end;
          align-items: center;

          p {
            font-weight: 400;
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
  }
}

.prohibitBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(254, 254, 254, 0.4);
  z-index: 2;
}

.fullbox {
  height: 500px;
  width: 100%;
  justify-content: center;
  align-items: center;

  svg {
    width: 150px;
    height: 150px;
  }

  .prompt {
    color: #999;
  }
}

/* Skeleton.module.css */
.skeletonCard {
  display: flex;
  align-items: center;
  width: 500px;
  margin: 0px auto 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 20px;
  position: relative;
}

.skeletonIcon {
  width: 190px;
  height: 107px;
  background: #dedede;
  border-radius: 10px;
  margin: 10px 0px;
}

.skeletonContent {
  flex: 1;
}

.skeletonTitle {
  width: 250px;
  height: 16px;
  background: #e0e0e0;
  margin-bottom: 8px;
  border-radius: 4px;
}

.skeletonDescription {
  width: 350px;
  height: 12px;
  background: #e0e0e0;
  border-radius: 4px;
}

.skeletonHeart {
  width: 24px;
  height: 24px;
  background: #dedede;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.skeletonIcon,
.skeletonTitle,
.skeletonDescription,
.skeletonHeart {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 100%;
  animation: loading 1.5s infinite;
}