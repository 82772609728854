.toollistBox {
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: width 0.3s ease;
}

.listBox {
  width: 50%;
  height: calc(100% - 120px);
  padding-bottom: 120px;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.contentsBox {
  display: contents;
  height: calc(100% - 10px);

  .imageBox {
    width: 556px;
    height: 300px;
    margin: 20px auto 0px;
    cursor: pointer;
    position: relative;

    .fade {
      transition: opacity 0.5s ease;
      position: absolute;
      /* 保证组件重叠显示 */
      top: 0;
      left: 0;
    }

    .hidden {
      opacity: 0;
    }

    .visible {
      opacity: 1;
    }

    .image {
      width: 556px;
      height: 300px;
    }
  }
}

.footbutton {
  width: 100%;
  position: absolute;
  border-top: 1px solid #DDDDDD;
  padding-top: 28px;
  bottom: 0;
  right: 0px;
  justify-content: center;
  padding: 28px 87px;
  box-sizing: border-box;

  .button {
    width: 442px;
    height: 59px;
    border-radius: 20px;

    p {
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-left: 5px;

      path {
        fill: #fff;
      }
    }
  }

  .vipbtn {
    width: 442px;
    height: 59px;
    background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%);
    border-radius: 20px;

    .vipprice {

      display: flex;
      align-items: center;

      svg {
        width: 22px;
        height: 22px;
      }

      p {
        margin-left: 5px;
        height: 22px;
        font-weight: bold;
        font-size: 16px;
        color: #A54500;
      }
    }

  }
}

.previewBox {
  width: 50%;
  height: 100%;
}