:root{
  --font-size-add: 0px; /* 字体大小调整 */
  --exp-color: 255, 122, 0;
}

@media (prefers-color-scheme: light) {
  :root{
    --primary-color: 86, 92, 250; /* 主色调：#565cfa，使用rbg方便添加透明度 */
    --sencondary-color: 97, 84, 255; /* 次色调：#6154ff */
    --text-color: #333333; /* 主文本颜色 */
    --text-reverse-color: #ffffff; /* 主文本反转色 */
    --secondary-text: #999999; /* 次文本颜色 */
    --secondary-text-rbg: 153, 153, 153; /* 次文本颜色 */
    --tips-text: #666666; /* 提示文本颜色 */
    --border-color: #dddddd; /* 边框颜色 */
    --secondary-border-color: #eeeeee; /* 次边框颜色 */
    --background-color:#ffffff; /* 主背景颜色 */
    --bg-reverse-color: #1D2125; /* 主背景反转色 */
    --warining-color: #FF5852; /* 警告颜色 */
    --secondary-bg: #f5f5f5; /* 次背景颜色 */
    --secondary-bg-rgb: 245,245,245; /* 次背景颜色rgb */
    ---element-bg: #f9f9f9; /* element背景颜色 */
    --element-acitve-bg: #e5e5e5; /* 激活背景颜色 */
    --bg-light: 86, 92, 250;
    --white-bg: 255, 255, 255;
    --main-color: #ffffff;
  }
}

@media (prefers-color-scheme: dark) {
  :root{
    --primary-color: 86, 92, 250; /* 主色调：#565cfa，使用rbg方便添加透明度 */
    --sencondary-color: 97, 84, 255; /* 次色调：#6154ff */
    --text-color: #333333; /* 主文本颜色 */
    --text-reverse-color: #ffffff; /* 主文本反转色 */
    --secondary-text: #999999; /* 次文本颜色 */
    --secondary-text-rbg: 153, 153, 153; /* 次文本颜色 */
    --tips-text: #666666; /* 提示文本颜色 */
    --border-color: #dddddd; /* 边框颜色 */
    --secondary-border-color: #eeeeee; /* 次边框颜色 */
    --background-color:#ffffff; /* 主背景颜色 */
    --bg-reverse-color: #1D2125; /* 主背景反转色 */
    --warining-color: #FF5852; /* 警告颜色 */
    --secondary-bg: #f5f5f5; /* 次背景颜色 */
    --secondary-bg-rgb: 245,245,245; /* 次背景颜色rgb */
    ---element-bg: #f9f9f9; /* element背景颜色 */
    --element-acitve-bg: #e5e5e5; /* 激活背景颜色 */
    --bg-light: 86, 92, 250;
    --white-bg: 255, 255, 255;
    --main-color: #ffffff;
  }
}