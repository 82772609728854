.square {
  flex: 1;
  display: flex;
  flex-direction: column;

  .mediaType {
    border-bottom: 1px solid var(--border-color);
    padding-left: 70px;
    margin-bottom: 30px;

    .category {
      display: flex;
      align-items: center;
      font-size: calc(var(--font-size-add) + 18px);
      color: var(--secondary-text);
      margin-bottom: 30px;

      .item {
        margin-right: 60px;
        cursor: pointer;

        &.active {
          color: rgb(var(--primary-color));
          font-weight: bold;
        }
      }
    }
  }

  .filter {
    margin: 30px auto;
    display: flex;

    .tag {
      padding: 10px 22px;
      border-radius: 33px;
      border: 1px solid rgba(var(--secondary-text-rbg), 0.5);
      cursor: pointer;
      margin-right: 16px;
      transition: all 0.3s;

      &.active {
        background-color: rgba(var(--primary-color), 0.1);
        color: rgb(var(--primary-color)) !important;
        border-color: transparent;
      }

      &:hover {
        border-color: rgb(var(--primary-color));
        color: rgb(var(--primary-color));
      }
    }
  }

  .container {
    flex: 1;
    height: 0;
  }

  .flowList {
    display: grid;
    grid-auto-flow: dense;
    grid-auto-rows: 1px;
    column-gap: 30px;
    grid-template-columns: repeat(4, auto-fill);
    grid-template-rows: masonry;
    justify-content: start;
    place-items: start;
    align-items: start;
    font-size: 0;
    align-content: stretch;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    min-height: 100%;
    box-sizing: border-box;

    .noMore {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 10px;
      z-index: 9;
      height: 20px;
      color: rgba(var(--secondary-text-rbg), 1);
      font-size: calc(var(--font-size-add) + 16px);
    }

    .loadMore {
      position: absolute;
      bottom: 10px;
    }
  }
}

.imgContent {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  cursor: zoom-in;
  width: 100%;

  &:hover {

    img,
    video {
      scale: 1.2;
    }
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
  }

  .mediaIconBox {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 90%);
    border-radius: 0px 0px 20px 20px;
    display: flex;
    align-items: center;
    padding: 18px;
    box-sizing: border-box;
    justify-content: flex-end;

    .mediaIcon {
      width: 24px;
      height: 24px;
    }
  }
}


.delete {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
}