.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-height: 900px) {
  .parent {
    margin-top: 50px;
  }
}

.child {
  padding: 20px;
  flex-direction: column;
}

.listbox {
  width: 600px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.contentsbox {
  flex-direction: column;

  .titletext {
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 20px);
    color: var(--text-color);
    margin-bottom: 10px;
  }

  .contenttext {
    font-weight: 400;
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--secondary-text);
  }
}

.imagetextbox {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.imagebox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 18px;
  background-color: var(--background-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  margin-bottom: 20px;
}

.imagetext {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
}

.image {
  width: 110px;
  height: 110px;
}

.detailbox {
  width: 440px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .copybox {
    display: contents;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    color: #333333;

    p {
      margin: 20px 0px;
    }

    .copybut {
      color: #fff;
      width: 92px;
      height: 42px;
      background: #565CFA;
      border-radius: 16px
    }

    span {
      color: #565CFA;
    }

  }
}

.detailimg {
  width: 148px;
  height: 148px;
}