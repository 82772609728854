.styles {
  .title {
    margin-bottom: 30px;
  }

  .styleList {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .item {

      .img {
        width: 76px;
        height: 76px;
        border-radius: 20px;
        overflow: hidden;
        background-color: var(--secondary-bg);
        position: relative;
        border: 2px solid transparent;
        opacity: 0.5;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .selected {
          position: absolute;
          width: 28px;
          height: 28px;
          bottom: -2px;
          right: -2px;
          border-radius: 10px 0 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgb(var(--primary-color));

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .name {
        text-align: center;
        font-size: calc(var(--font-size-add) + 12px);
        color: var(--text-color);
        font-weight: 500;
        margin-top: 14px;
      }

      &.active {
        .name {
          color: rgb(var(--primary-color));
        }

        .img {
          border-color: rgb(var(--primary-color));
          opacity: 1;
        }
      }
    }

    .more {
      width: 76px;
      height: 76px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--secondary-bg);
      font-size: calc(var(--font-size-add) + 12px);
      font-weight: 500;
      color: var(--secondary-text);
    }
  }

}


.allList {
  background-color: var(--background-color);
  border-radius: 30px;
  width: 932px;
  height: 632px;
  padding: 30px 40px 40px;
  box-sizing: border-box;

  .title {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 18px);
    color: var(--text-color);

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .tags {
    display: flex;
    padding-bottom: 10px;

    .tag {
      padding: 8px 14px;
      border-radius: 12px;
      border: 1px solid rgb(var(--secondary-text-rbg), 0.4);
      cursor: pointer;
      margin-right: 20px;
      white-space: nowrap;

      &.active {
        color: #fff !important;
        background-color: rgb(var(--primary-color));
      }

      &:hover {
        color: rgb(var(--primary-color));
        border-color: rgb(var(--primary-color));
      }
    }
  }

  .allStyle {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    height: 382px;

    .item {
      margin-right: 25px;
      margin-bottom: 25px;
      cursor: pointer;

      &:nth-child(6n) {
        margin-right: 0;
      }

      .img {
        width: 116px;
        height: 116px;
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 14px;
        position: relative;
        border: 2px solid transparent;

        .selected {
          position: absolute;
          width: 32px;
          height: 32px;
          bottom: -2px;
          right: -2px;
          border-radius: 10px 0 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgb(var(--primary-color));

          svg {
            width: 20px;
            height: 20px;
          }
        }

        &:hover .mask {
          opacity: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.5;
        }
      }

      .name {
        text-align: center;
        font-weight: 400;
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--text-color);
      }

      &.active {
        .name {
          color: rgb(var(--primary-color));
          font-weight: bold;
        }

        .img {
          border-color: rgb(var(--primary-color));
        }

        img {
          opacity: 1;
        }
      }
    }
  }

  .btns {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-right: 20px;
      width: 92px;
      height: 42px;
      border-radius: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}