.search {
  display: flex;
  width: 100%;

  .searchInput {
    background-color: var(--secondary-bg);
    flex: 1;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    cursor: pointer;
    height: 52px;
    box-sizing: border-box;


    .icon {
      width: 24px;
      height: 24px;
      margin-right: 17px;
    }

    .text {
      position: relative;
      color: var(--secondary-text);
      font-size: calc(var(--font-size-add) + 16px);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      .input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 16px);
      }

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 40%;
        background-color: rgba(var(--secondary-text-rbg), 0.2);
        border-radius: 50%;
        top: 50%;
        left: -8px;
        transform: translate(-50%, -50%);
      }
    }
  }
}