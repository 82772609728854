.errorImg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 80%;
    height: 80%;
  }
}