.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.child {
  padding: 20px;
}

.titlebox {
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .avataricon {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.updateavataricon {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  right: 230px;
  bottom: 25px;
  cursor: pointer;
}

.contentbox {
  width: 600px;
  height: 320px;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 30px
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 540px;
  height: 80px;
  border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  margin: 0px 30px;
}

// .item:last-child {
//   border-bottom: none;
// }

.label {
  height: 21px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 21px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.valuebox {
  justify-content: center;
  align-items: center;
}

.value {
  height: 19px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 0px 5px;
  cursor: pointer;
}

.erroricon {
  width: 20px !important;
  height: 20px !important;
}

.righticon {
  width: 24px !important;
  height: 24px !important;
}

.cbutton {
  margin-top: 50px;
  background: #565CFA;
  color: var(--text-reverse-color);
  width: 600px;
}

.cmodelbox {
  width: 330px;
  // height: 450px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 30px 40px;
  border-radius: 30px;
  position: relative;


  .pwdNotSame {
    display: flex;
    position: absolute;
    bottom: 85px;
    left: 50px;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      margin-top: 2px;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      margin-left: 4px;
      color: #FF5852;
    }
  }

  p {
    font-weight: bold;
    font-size: 22px;
    color: #333333;
  }
}

.input_group {
  display: flex;
  align-items: center;
  border: 0px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  margin: 10px 0px;
  position: relative;

  .input_count {
    position: absolute;
    right: 12px;
    top: 20px;
    color: #999;
    font-size: calc(var(--font-size-add) + 14px);
    font-weight: 400;
  }

  .input_but {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 12px;
    top: 21px;
    cursor: pointer;
  }
}

.input_left_addon {
  background-color: #eee;
  height: 20px;
  padding: 20px;
  border-right: 1px solid #ccc;
  color: #555;
  font-size: 16px;
  border-radius: 20px 0px 0px 20px;
}

.phoneinput_field {
  border: 0px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  flex: 1;
  outline: none;
  border-radius: 0px 20px 20px 0px;
  background-color: #f5f5f5;
}

.input_field {
  border: 0px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  flex: 1;
  outline: none;
  border-radius: 20px;
  background-color: #f5f5f5;
}

.input_field::placeholder {
  color: #aaa;
}

.modelbuttonbox {
  margin-top: 30px;
  justify-content: end;
  align-items: center;
}

.Savebutton {
  background: #565CFA;
  color: var(--text-reverse-color);
  width: 130px;
  margin-left: 10px;
}

.Savebutton:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
}

.Cancelbutton {
  background-color: transparent;
  width: 130px;
  border: 1px solid #999;
}

.avatarcmodelbox {
  width: 510px;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 40px;
  flex-direction: column;

  p {
    font-weight: bold;
    font-size: 22px;
    color: #333333;
  }
}

.avatar_group {
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  margin-top: 20px;
}

.avatar_box {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.avatar_box img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.avatar_box svg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.avatar_boxSelected {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;

  svg {
    width: 28px !important;
    height: 28px !important;

  }
}

.cmodelTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}

.cmodelpaybox {
  width: 362px;
  height: 302px;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 40px;
  flex-direction: column;
}

.payimgbox {
  width: 100%;
  padding: 30px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin-bottom: 5px;
  }
}

.payimage {
  width: 128px !important;
  height: 128px !important;
  padding: 10px;
  margin-bottom: 20px;
}