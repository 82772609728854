.waitingAnimation::after {
  display: inline-block;
  content: '';
  width: 4px;
  height: 14px;
  transform: translate(4px, 2px) scaleY(1.3);
  background-color: var(--chakra-colors-chakra-body-text);
  animation: blink 0.6s infinite;
}

.animation {
  > :last-child::after {
    display: inline-block;
    content: '';
    width: 4px;
    height: 14px;
    transform: translate(4px, 2px) scaleY(1.3);
    background-color: var(--chakra-colors-chakra-body-text);
    animation: blink 0.6s infinite;
  }
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.markdown>*:first-child {
  margin-top: 0 !important;
}

.markdown>*:last-child {
  margin-bottom: 0 !important;
}

.markdown a.absent {
  color: #cc0000;
}

.markdown a.anchor {
  bottom: 0;
  cursor: pointer;
  display: block;
  left: 0;
  margin-left: -30px;
  padding-left: 30px;
  position: absolute;
  top: 0;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  cursor: text;
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  position: relative;
}

.markdown h1 .mini-icon-link,
.markdown h2 .mini-icon-link,
.markdown h3 .mini-icon-link,
.markdown h4 .mini-icon-link,
.markdown h5 .mini-icon-link,
.markdown h6 .mini-icon-link {
  display: none;
}

.markdown h1:hover a.anchor,
.markdown h2:hover a.anchor,
.markdown h3:hover a.anchor,
.markdown h4:hover a.anchor,
.markdown h5:hover a.anchor,
.markdown h6:hover a.anchor {
  line-height: 1;
  margin-left: -22px;
  padding-left: 0;
  text-decoration: none;
  top: 15%;
}

.markdown h1:hover a.anchor .mini-icon-link,
.markdown h2:hover a.anchor .mini-icon-link,
.markdown h3:hover a.anchor .mini-icon-link,
.markdown h4:hover a.anchor .mini-icon-link,
.markdown h5:hover a.anchor .mini-icon-link,
.markdown h6:hover a.anchor .mini-icon-link {
  display: inline-block;
}

.markdown h1 tt,
.markdown h1 code,
.markdown h2 tt,
.markdown h2 code,
.markdown h3 tt,
.markdown h3 code,
.markdown h4 tt,
.markdown h4 code,
.markdown h5 tt,
.markdown h5 code,
.markdown h6 tt,
.markdown h6 code {
  font-size: inherit;
}

.markdown h1 {
  font-size: 28px;
}

.markdown h2 {
  font-size: calc(var(--font-size-add) + 16px);
}

.markdown h3 {
  font-size: 18px;
}

.markdown h4 {
  font-size: 16px;
}

.markdown h5 {
  font-size: 14px;
}

.markdown h6 {
  font-size: 12px;
}

.markdown p,
.markdown blockquote,
.markdown ul,
.markdown ol,
.markdown dl,
.markdown pre {
  margin: 10px 0;
}

.markdown {
  width: '100%';
  overflow-x: hidden;
}

.markdown table {
  width: auto;
  overflow-x: auto;
  display: block;
}

.markdown>h2:first-child,
.markdown>h1:first-child,
.markdown>h1:first-child+h2,
.markdown>h3:first-child,
.markdown>h4:first-child,
.markdown>h5:first-child,
.markdown>h6:first-child {
  margin-top: 0;
  padding-top: 0;
}

.markdown a:first-child h1,
.markdown a:first-child h2,
.markdown a:first-child h3,
.markdown a:first-child h4,
.markdown a:first-child h5,
.markdown a:first-child h6 {
  margin-top: 0;
  padding-top: 0;
}

.markdown h1+p,
.markdown h2+p,
.markdown h3+p,
.markdown h4+p,
.markdown h5+p,
.markdown h6+p {
  margin-top: 0;
}

.markdown li p.first {
  display: inline-block;
}

.markdown ul,
.markdown ol {
  padding-left: 2em;
}

.markdown ul.no-list,
.markdown ol.no-list {
  list-style-type: none;
  padding: 0;
}

.markdown ul li>*:first-child,
.markdown ol li>*:first-child {
  margin-top: 0;
}

.markdown ul ul,
.markdown ul ol,
.markdown ol ol,
.markdown ol ul {
  margin-bottom: 0;
}

.markdown dl {
  padding: 0;
}

.markdown dl dt {
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  margin: 15px 0 5px;
  padding: 0;
}

.markdown dl dt:first-child {
  padding: 0;
}

.markdown dl dt>*:first-child {
  margin-top: 0;
}

.markdown dl dt>*:last-child {
  margin-bottom: 0;
}

.markdown dl dd {
  margin: 0 0 15px;
  padding: 0 15px;
}

.markdown dl dd>*:first-child {
  margin-top: 0;
}

.markdown dl dd>*:last-child {
  margin-bottom: 0;
}

.markdown blockquote {
  border-left: 4px solid #dddddd;
  color: #777777;
  padding: 0 15px;
}

.markdown blockquote>*:first-child {
  margin-top: 0;
}

.markdown blockquote>*:last-child {
  margin-bottom: 0;
}

.markdown table th {
  font-weight: bold;
}

.markdown table th,
.markdown table td {
  padding: 6px 13px;
}

.markdown table tr {
  background-color: #ffffff;
}

.markdown table tr:nth-child(2n) {
  background-color: #f0f0f0;
}

.markdown img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.markdown span.frame {
  display: block;
  overflow: hidden;
}

.markdown span.frame>span {
  border: 1px solid #dddddd;
  display: block;
  float: left;
  margin: 13px 0 0;
  overflow: hidden;
  padding: 7px;
  width: auto;
}

.markdown span.frame span img {
  display: block;
  float: left;
}

.markdown span.frame span span {
  clear: both;
  color: #333333;
  display: block;
  padding: 5px 0 0;
}

.markdown span.align-center {
  clear: both;
  display: block;
  overflow: hidden;
}

.markdown span.align-center>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}

.markdown span.align-center span img {
  margin: 0 auto;
  text-align: center;
}

.markdown span.align-right {
  clear: both;
  display: block;
  overflow: hidden;
}

.markdown span.align-right>span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}

.markdown span.align-right span img {
  margin: 0;
  text-align: right;
}

.markdown span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}

.markdown span.float-left span {
  margin: 13px 0 0;
}

.markdown span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}

.markdown span.float-right>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}

.markdown code,
.markdown tt {
  border: 1px solid #eaeaea;
  border-radius: 3px 3px 3px 3px;
  margin: 0 2px;
  padding: 0 5px;
  background: #cceae0;
}

.markdown pre>code {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  margin: 0;
  padding: 0;
}

.markdown .highlight pre,
.markdown pre {
  border: 1px solid #cccccc;
  border-radius: 3px 3px 3px 3px;
  font-size: max(0.9em, 14px);
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
}

.markdown pre code,
.markdown pre tt {
  background-color: transparent;
  border: medium none;
}

.markdown {
  text-align: justify;
  tab-size: 4;
  word-spacing: normal;
  width: 100%;

  * {
    word-break: break-all;
  }

  pre {
    display: block;
    width: 100%;
    padding: 10px 20px;
    margin: 0;
    border: none;
    border-radius: 0;
    background-color: #555555 !important;
    overflow-x: auto;
    color: #fff;
    box-sizing: border-box;
  }

  pre code {
    background-color: #555555 !important;
    width: 100%;
  }

  a {
    text-decoration: underline;
    color: var(--chakra-colors-blue-600);
  }

  table {
    border-collapse: separate;
    border-spacing: 0px;
    color: var(--chakra-colors-gray-700);

    thead tr:first-child th {
      border-bottom-width: 1px;
      border-left-width: 1px;
      border-top-width: 1px;
      border-color: #ccc;
      background-color: rgba(236, 236, 241, 0.2);
      overflow: hidden;

      &:first-child {
        border-top-left-radius: 0.375rem;
      }

      &:last-child {
        border-right-width: 1px;
        border-top-right-radius: 0.375rem;
      }
    }

    td {
      border-bottom-width: 1px;
      border-left-width: 1px;
      border-color: #ccc;

      &:last-of-type {
        border-right-width: 1px;
      }
    }

    tbody tr:last-child {
      overflow: hidden;

      td {
        &:first-child {
          border-bottom-left-radius: 0.375rem;
        }

        &:last-child {
          border-bottom-right-radius: 0.375rem;
        }
      }
    }
  }
}

.mermaid {
  overflow-x: auto;
}

.article {
  width: 960px;
  max-width: 100%;
  margin: 60px auto;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.navigation {
  position: fixed;
  right: 20px;
  top: 60px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #eee;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  transform: translate(0, 0);
  transition: transform 500ms ease;
}

.nav-container.hide {
  transform: translate(calc(20px + 100%), 0);
}

.toggle-btn {
  position: absolute;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(255, 115, 0);
  font-size: 18px;
  line-height: 40px;
  padding: 0 11px;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  transform: translate(calc(-12px - 100%), 0);
  transition: color 300ms ease, background-color 300ms ease;
}

.toggle-btn:active {
  background-color: rgb(255, 115, 0);
  color: #fff;
}