.share {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #E4E5FF 0%, #FFFFFF 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .shareContent {
    width: 908px;
    height: 830px;
    border-radius: 30px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

    .title {
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 30px);
      color: var(--text-color);
      line-height: 35px;
      text-align: center;
      padding: 60px 0 30px;
    }

    .subTitle {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      width: 436px;
    }

    .userInfo {
      display: flex;
      align-items: center;
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--text-color);
      line-height: 19px;

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        margin-right: 12px;
      }
    }

    .download {
      background-color: var(--text-color);
      color: #ffffff;
      border-radius: 33px;
      width: 100px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }

    .image,
    .video,
    .audio {
      width: 667px;
      height: 376px;
      display: block;
      margin: 0 auto;
      object-fit: cover;
      border-radius: 24px;
    }
  }

  .shareBtn {
    width: 326px;
    height: 60px;
    border-radius: 48px;
    margin-top: 20px;
    margin-bottom: 20px;

    .icon {
      width: 24px;
      height: 24px;
      margin-left: 4px;
    }
  }

  .shareType {
    font-size: calc(var(--font-size-add) + 12px);
    color: var(--text-color);
    line-height: 14px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .share {
    background: #ffffff;
    height: auto;
  }

  .shareContent {

    .header {
      height: 88px;
      width: 100%;
      background: linear-gradient(180deg, #DFE0FF 0%, #FFFFFF 55%);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-weight: bold;
      color: var(--text-color);

      .title {
        font-size: calc(var(--font-size-add) + 16px);
        padding-bottom: 12px;
      }
    }

    .subTitle {
      padding: 12px 20px;
      width: 100vw !important;
      border-bottom: 1px solid rgba(153, 153, 153, 0.20);
      margin-bottom: 20px !important;
    }

    .image,
    .video {
      width: 335px !important;
      height: 470px !important;
    }
  }

  .footer {
    width: 345px;
    height: 70px;
    background: #333333;
    border-radius: 24px;
    margin: 0 auto;
    box-shadow: 0px 6px 10px 0px rgba(0, 7, 64, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .shareBtn {
      width: 100% !important;
      height: auto !important;
      margin: 0 !important;
      background-color: transparent !important;
      text-align: center;
    }

    .shareType {
      font-size: calc(var(--font-size-add) + 12px);
      color: #ffffff;
      line-height: 12px;
      margin-top: 4px;
    }
  }
}