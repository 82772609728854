body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: var(--text-color);
  user-select: none;
  overflow: hidden;
}
html,body{
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root{
  overflow: hidden;
  height: 100%;
}

:focus-visible{
  outline: none;
}

p{
  margin: 0;
}

video{
  zoom: 1.1111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ms-track{
  border-left: none !important;
}
.ms-track:hover{
  border: none !important;
}
.ms-track .ms-thumb{
  background: rgba(153,153,153,0.2) !important;
}

.swiper-pagination-bullet{
  background: rgba(255,255,255,0.7) !important;
}
.swiper-pagination-bullet-active {
  background-color: rgb(var(--primary-color)) !important;
}

.swiper-pagination-bullets.swiper-pagination-horizontal{
  bottom: 20px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
.base-Option-root {
  border-bottom: 1px solid var(--secondary-bg);
  padding-right: 10px;
  padding: 17px 10px 17px 0px;
  list-style: none;
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--text-color);
  cursor: pointer;
}
.base-Option-root:hover{
  opacity: 0.8;
}
.base-Option-root:last-child {
  border-bottom: none;
}

.base-Option-root.base--selected{
  color: rgb(var(--primary-color));
}


.div-editable:empty::before,
.content_item:empty::before {
  content: attr(placeholder);
}

.content_item:empty::before {
  content: attr(placeholder);
  color: #999999;
}

.content_item {
  word-break: break-all;
  white-space: pre-wrap;
  min-height: 20px;
  font-size: 16px;
  margin-bottom: 12px;
  line-height: 1.3;
}

.content_item::selection {
  background: #D3E2FF;
  color: var(--text-color);
}

textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgba(var(--secondary-text-rbg), 0.5);
  border-radius: 11px;
}

.PhotoView__PhotoBox{
  zoom: 1.1111;
}