.allbox {
  flex-direction: column;
  width: 556px;
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
  }
}

.listbox {
  z-index: 0;

  .selbox {
    min-width: 76px;
    padding: 0px 10px;
    height: 48px;
    background: #565CFA;
    border-radius: 14px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 3px;
    }
  }

  .sexbox {
    min-width: 76px;
    padding: 0px 10px;
    height: 46px;
    border-radius: 14px 14px 14px 14px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 3px;
    }
  }
}