.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-height: 900px) {
  .parent {
    margin-top: 50px;
  }
}

.child {
  padding: 20px;
  position: relative;
}

.allbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.boxtext {
  font-weight: 900;
  font-size: 30px;
  color: var(--text-color);
}

.box {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 500;
  color: #333333;
  font-size: calc(var(--font-size-add) + 18px);
}

.specialIcon {
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;
}

.specialText {
  margin-right: 4px;
  font-weight: 500;
  color: #FF7B00;
  font-size: calc(var(--font-size-add) + 18px);
}

.detailbox {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  p {
    font-weight: 400;
    font-size: calc(var(--font-size-add) + 16px);
    color: #333333;
  }
}

.li {
  color: #999999;
  margin-bottom: 10px;

  div {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    color: #999999;
  }
}

.signInbox {
  width: 520px;
  // height: 504px;
  border-radius: 30px;
  margin-bottom: 20px;
  padding: 20px 40px;
  background-image: url('../../../../assets/png/signInBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  flex-direction: column;
}

.signInboxpng {
  position: absolute;
  top: -25px;
  right: 40px;
  width: 170px;
  height: 170px;
  opacity: 0.9;
}

.calendarbox {
  margin: 0px auto;
  grid-template-columns: repeat(7, 1fr);
  gap: 1;
}

.weekdays {
  width: 60px;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  text-align: center;
  color: var(--text-color);
  margin: 0px 7px 20px;
}

.statusbox {
  width: 60px;
  height: 60px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 7px 10px;
}

.expired {
  background: #E7E7E7;
}

.today {
  background: #FF7B00;
}

.signedIn {
  background: rgba(255, 123, 0, 0.1);
}

.unsigned {
  background: transparent;
}

.todaytextok {
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 12px);
  color: var(--text-reverse-color);
}

.expiredtextno {
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 12px);
  color: #666666;
}

.statustext {
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 16px);

}

.todaytext {
  color: var(--text-reverse-color);
}

.expiredtext {
  color: #666666;
}

.signedIntext {
  color: #666666;
}

.unsigned {
  color: var(--secondary-text);
}

.signInbutton {
  width: 100% !important;
  height: 48px;
  background: #565CFA;
  color: var(--text-reverse-color);
  border-radius: 30px
}

.signInedbutton {
  width: 100% !important;
  height: 48px;
  background: rgba(153, 153, 153, 0.5);
  color: var(--text-reverse-color);
  border-radius: 30px
}

.signInten {
  margin-right: 10px;
}

.signInbuttonbox {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.signInbuttontext {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  columns: #999;
}

.signInbuttonbills {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: #7C91FF;
}

.signInbuttonicon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 50px;
  margin-top: 2px;
}

.cumulative {
  // height: 152px;
  width: 540px;
  padding: 20px 30px;
  background-color: var(--background-color);
  border-radius: 30px;
}

.reward {
  display: flex;
  align-items: center;
}

.rewardtitle {
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
}

.rewardtips {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: #666666;
}

.rewardays {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: #F76560 !important;
}

.starkbox {
  width: 540px;
  position: relative;
}

.filled_track {
  position: absolute;
  height: 10px;
  background: #FF5852;
  border-radius: 5px;
  top: 40px;
  left: 50px;
  // z-index: 2;
}

.unfilled_track {
  position: absolute;
  height: 10px;
  background: #DADADA;
  border-radius: 5px;
  top: 40px;
  right: 50px;
  // z-index: 2;
}

.stepper {
  width: 100%;
  gap: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.stepsbox {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  position: relative;
  margin: 0 10px;
}

.signInIconBox {
  margin: 15px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.signInBoxicon {
  width: 60px !important;
  height: 50px !important;
  // z-index: 3;
}

.signInBoxtext {
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
}

.signInButton {
  width: 69px !important;
  height: 30px !important;
  background: #565CFA;
  border-radius: 30px
}

.signInButtontext {
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--text-reverse-color);
}

.signInButtonicon {
  width: 10px;
  height: 10px;
  margin-left: 3px;
  margin-bottom: 2px;
}

.cmodel {
  background-color: transparent;
  width: 400px;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cmodelbox {
  width: 300px;
  height: 296px;
  padding: 20px 0px;
  background-image: url('../../../../assets/png/signInSuccess.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cmodeltitle {
  font-weight: 900;
  font-size: 28px;
  color: #565CFA;
  margin: 20px 0px;
}

.cmodeltextbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #999999;
  }
}

.cmodelclose {
  width: 34px !important;
  height: 34px !important;
  cursor: pointer;
}