.login {
  width: 1014px;
  height: 620px;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  position: relative;


  .close {
    position: absolute;
    top: -40px;
    right: -40px;
    cursor: pointer;

    .icon {
      width: 30px;
      height: 30px;
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.loginLeft {
  // padding-top: 20px;
  margin-right: 25px;

  .title {
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 10px;
  }

  .desc {
    font-weight: 400;
    color: var(--secondary-text);
    font-size: calc(var(--font-size-add) + 14px);
    max-width: 170px;
  }

  .lingOne {
    display: flex;
    margin-bottom: 20px;

    .logo {
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 90%);

      .logoImg {
        width: 70px;
        height: 70px;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 15px;
        background-color: var(--background-color);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }

    .llms {
      width: 300px;
      height: 140px;
      padding: 20px;
      box-sizing: border-box;
      margin-left: 20px;
    }
  }

  .lineTwo {
    display: flex;

    .left {
      .top {
        display: flex;
        margin-bottom: 20px;
      }

      .generate {
        width: 140px;
        height: 140px;
        padding: 20px 14px;
        box-sizing: border-box;
        margin-right: 20px;
      }

      .creation {
        width: 140px;
        height: 140px;
        padding: 20px 14px;
        box-sizing: border-box;

        .title {
          font-size: calc(var(--font-size-add) + 20px);
        }
      }

      .cloud {
        width: 300px;
        height: 140px;
        padding: 20px;
        box-sizing: border-box;
      }
    }

    .tools {
      width: 140px;
      height: 300px;
      padding: 20px 14px;
      box-sizing: border-box;
      margin-left: 20px;
    }
  }

  .lineThree {
    display: flex;
    align-items: center;
    position: relative;

    .threeText {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      cursor: pointer;
    }

    .arrow {
      width: 18px;
      height: 18px;
      margin-left: 5px;
      transition: transform 0.3s;
      transform: rotate(-90deg);

      &.open {
        transform: rotate(90deg);
      }
    }

    .dropdownMenu {
      width: 150px;
      height: 100px;
      border-radius: 15px;
      border: 1px solid #eee;
      background-color: #fff;
      position: absolute;
      top: 30px;
      left: -20px;

      .hoverBox {
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .hoverBox:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .download {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    color: rgb(var(--primary-color));
    cursor: pointer;
  }

}

.leftGrandient {
  border-radius: 20px 20px 0px 0px;
  position: relative;

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}