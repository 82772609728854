.share {
  border-radius: 30px;
  background-color: var(--background-color);
  padding: 30px 40px 40px 40px;
  width: 918px;

  .title {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: calc(var(--font-size-add) + 18px);
      color: var(--text-color);
      font-weight: bold;
    }

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .content {
    height: 340px;
    width: 100%;
    border-radius: 30px;
    border: 1px solid rgba(153, 153, 153, 0.2);
    background-color: var(--secondary-bg);
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .shareType {
    margin-top: 20px;

    .title {
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--text-color);
      margin-bottom: 10px;
    }

    .shareTypeItem {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      font-size: calc(var(--font-size-add) + 14px);
      color: var(--text-color);
      margin-right: 40px;

      img {
        box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
        border-radius: 16px;
        margin-bottom: 8px;
      }
    }
  }
}