.wisePage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    max-width: 600px;
  }

  .page {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--text-color);
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: 400;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      color: rgb(var(--primary-color));
    }

    &.active {
      color: #fff;
      background-color: rgb(var(--primary-color));

    }
  }

  .l,
  .r {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    border: 1px solid rgba(51, 51, 51, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-left: 25px;
    cursor: pointer;

    &:hover {
      border-color: rgb(var(--primary-color));

      svg path {
        fill: rgb(var(--primary-color));
      }
    }

    &.disable {
      cursor: not-allowed;
      border-color: rgba(51, 51, 51, 0.2);

      svg path {
        fill: rgba(51, 51, 51, 0.2);
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .l svg {
    transform: rotate(180deg);
  }
}