.allbox {
  width: 100%;
  align-items: center;
  justify-content: start;
  margin: 0px 0px 20px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 10px;
  position: relative;

  .title {
    width: 70px;
    font-weight: 400;
    font-size: 16px;
    color: #999999;
  }

  .titletipsicon {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 35px;
    cursor: pointer;

    path {
      fill: #ccc
    }
  }
}

.menuList {
  padding: 5px 10px;
  background: #FFFFFF;
  border: 1px solid #ccc;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  border-radius: 18px
}

.selectmenu {
  width: 100%;
  height: 52px;
  padding: 18px 20px;
  background: #F5F5F5;
  border-radius: 18px;
  border: 0px;
  cursor: pointer;

  .top {
    transform: rotate(270deg);
  }

  .bottom {
    transform: rotate(90deg);
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.scrollbar {
  max-height: 320px;
}

.menuitem {
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border: 0px;
  background-color: #fff;
  position: relative;

  .uploadIcon {
    width: 14px;
    height: 14px;
    margin-left: 20px;
  }
}

.menuitem:hover {
  background: #F5F5F5;
  cursor: pointer;
}

.playbox {
  position: absolute;
  right: 10px;

  .playicon {
    width: 24px;
    height: 24px;
  }
}

.audioWave {
  display: flex;
  align-items: flex-end;
  gap: 3px;
}

.bar {
  width: 2px;
  height: 10px;
  background-color: #748ef8;
  animation: bounce 1s infinite ease-in-out;
}

.bar:nth-child(2) {
  animation-delay: -0.2s;
}

.bar:nth-child(3) {
  animation-delay: -0.4s;
}

.bar:nth-child(4) {
  animation-delay: -0.6s;
}

@keyframes bounce {

  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(1.5);
  }
}


.notbalancebox {
  width: 344px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 24px 30px;
  border-radius: 30px;
  position: relative;
}

.notbalanceTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  p {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}

.texttips {
  font-weight: 400;
  font-size: 16px;
  color: #999;
}

.modelbuttonbox {
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
}

.Savebutton {
  background: transparent;
  color: var(--text-reverse-color);
  width: 130px;
  color: #333;
}