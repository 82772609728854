.progressContainer {
  display: flex;
  align-items: center;
  margin: 0px 0px 10px;
  z-index: 3;
}

.progressBar {
  width: 100%;
  height: 6px;
  background-color: #E0E0E0;
  border-radius: 18px;
  overflow: hidden;
  margin-right: 10px;
}

.progress {
  height: 100%;
  background-color: #646AFF;
  /* 蓝色进度条 */
  transition: width 0.25s;
}

.progressText {
  min-width: 50px;
  text-align: right;
}