.scale {
  .list {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 70px;
      height: 70px;
      border-radius: 18px;
      background-color: var(--secondary-bg);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 27px;
      border: 1px solid transparent;
      color: var(--tips-text);
      transition: all 0.3s;
      margin-right: 20px;

      &:hover {
        background-color: var(--secondary-border-color);
        opacity: 0.8;
      }

      &.active {
        border: 1px solid rgb(var(--primary-color));
        background-color: var(--background-color);
        color: rgb(var(--primary-color));

        .icon rect {
          stroke: rgb(var(--primary-color));
        }
      }

      .icon {
        width: 35px;
        height: 35px;
        margin-bottom: 3px;
      }
    }
  }
}