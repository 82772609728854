.allbox {
  flex-direction: column;
  width: 556px;
  height: calc(100% - 30px);
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
  }
}

.contentsbox {
  height: 100vh;
}

.textarea {
  width: 520px;
  height: calc(100% - 50px);
  min-height: 300px;
  // height: 308px;
  background: #F5F5F5;
  border-radius: 20px;
  font-size: 16px;
  border: 0px;
  padding: 16px;
  resize: none;
  border: 1px solid #F5F5F5;
  color: #333;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }
}

.erroricon {
  position: absolute;
  left: 15px;
  top: 18px;
  width: 20px !important;
  height: 20px !important;
}

.textareaerror {
  color: #FF5852;
  text-indent: 20px;
}

.textarea:focus {
  border: 1px solid #565CFA;
}