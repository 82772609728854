.lang {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .box {
    width: 600px;

    .btn {
      text-align: right;
      margin-top: 30px;
    }
  }

  .title {
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 20px);
    color: var(--text-color);
    margin-bottom: 40px;
  }
}