.message {
  font-size: calc(var(--font-size-add) + 16px);
  border-radius: 16px;
  margin-top: 20px;
  // background-color: var(--background-color);
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 20px;
  // box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: auto;

  // &.success {
  //   color: #09AD19;
  // }

  // &.error {
  //   color: #FF5852;
  // }

  // &.tips {
  //   color: #565CFA;
  // }

  // &.warning {
  //   color: #FF7B00;
  // }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
}