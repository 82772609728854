.allbox {
  width: calc(100% - 100px);
  padding: 20px 50px;
  justify-content: end;
  flex-direction: column;
  border-top: 1px solid #ddd;
}

.textbox {
  display: flex;
  justify-content: end;
  font-size: 14px;
  color: #333333;

  .ischeck {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-right: 3px;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid #333 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .checked {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-right: 3px;
    font-size: 12px;
    border-radius: 50%;
    color: #fff;
    border: 1px solid #333 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #333 !important;
  }

  input {
    margin-bottom: 3px;
  }

  span {
    color: #565CFA;
    cursor: pointer;
  }
}

.buttonBox {
  margin-top: 10px;
  flex-direction: row;
  justify-content: end;
}

.casebutton {
  min-width: 118px;
  width: auto !important;
  height: 44px;
  background: rgba(153, 153, 153, 0.1);
  margin-left: 30px;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  border-radius: 16px;
  border: 0px solid #000;

  svg {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 4px;
  }
}

.wxoutbutton {
  min-width: 118px;
  width: auto !important;
  height: 44px;
  margin-left: 30px;
  background: #FF5852;
  border-radius: 16px;
  color: #fff;
  border: 0px solid #000;

  svg {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 4px;
  }
}

.wxbutton {
  min-width: 118px;
  width: auto !important;
  height: 44px;
  margin-left: 30px;
  background: #09AD19;
  border-radius: 16px;
  color: #fff;
  border: 0px solid #000;

  svg {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 4px;
  }
}

.detailbox {
  width: 400px;
  height: 290;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #999999;
    margin: 20px auto;
  }
}

.detailbox_butbox {
  width: 100%;
  justify-content: flex-end;
}

.detailbox_calbut {
  width: 92px;
  height: 42px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #999999;
}

.detailbox_okbut {
  margin-left: 10px;
  width: 92px;
  height: 42px;
  background: #565CFA;
  border-radius: 16px;
  border: 0px;
  color: #fff;
}