.creationContent {
  display: flex;
  flex: 1;
  min-width: 1460px;
}

.inputParams {
  flex: 1;
}

.history {
  flex: 1;
  border-left: 1px solid var(--border-color);
  background-color: var(---element-bg);
}