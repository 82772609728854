.chatContainer {
  height: 100%;
  overflow: hidden;
  padding-right: 3px;
  position: relative;

  .headerPos {
    height: 80px;
    flex-shrink: 0;
  }

  .bottomPos {
    height: 160px;
    flex-shrink: 0;
  }

  .toBottom {
    position: absolute;
    box-shadow: 0px 4px 10px 0px rgba(15, 11, 54, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    left: 50%;
    bottom: 130px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    display: none;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    .listBox {
      &::-webkit-scrollbar-thumb {
        background-color: rgb(var(--secondary-text-rbg), 0.2);
        width: 4px;
      }
    }
  }

  .listBox {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100% - 20px);
    overflow: auto;

    .loadMore {
      width: 0;
      height: 0;
    }

    .showLoadMore {
      display: none;
      justify-content: center;
      position: absolute;
      top: 90px;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    .agent {
      width: 600px;
      margin: 40px auto;
      text-align: center;

      .avatar {
        width: 150px;
        height: 150px;
        border-radius: 50px;
      }

      .title {
        font-size: calc(var(--font-size-add) + 24px);
        color: var(--text-color);
        font-weight: bold;
        margin-top: 30px;
      }

      .desc {
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--secondary-text);
        margin-top: 16px;
        text-align: center;
      }
    }
  }

  .chatItemBox {
    padding-top: 30px;
    width: 960px;
    margin: 0 auto;
  }

  .chatItemContent {
    position: relative;
  }

  .collectMask {
    position: absolute;
    top: 50%;
    left: -36px;
    width: 26px;
    height: 26px;
    z-index: 1;
    border: 2px solid var(--text-color);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: var(--text-color);
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .chatItem {
    font-size: calc(var(--font-size-add) + 16px);
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;

    &.collect {
      opacity: 0.3;
    }

    &.collected {
      opacity: 1;
    }

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 20px;
      background-color: var(--background-color);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      max-width: 80%;

      .app {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 16px);
        font-weight: bold;
      }

      .docFiles {
        display: flex;
        margin-bottom: 20px;

        .docFile {
          width: 235px;
          height: 106px;
          border-radius: 14px;
          background: #fff;
          padding: 16px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 30px;
          cursor: pointer;

          .name {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
            line-height: 1;
          }

          .fileType {
            display: flex;
            align-items: center;
            color: var(--secondary-text);
            font-size: calc(var(--font-size-add) + 16px);

            svg {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }
        }
      }

      .appDesc {
        display: flex;

        .model {
          color: var(--secondary-text);
          margin-right: 15px;
        }
      }

      .time {
        font-size: calc(var(--font-size-add) + 14px);
        font-weight: 500;
        color: var(--secondary-text);
        line-height: 1;
        margin-top: 6px;
        margin-bottom: 15px;
      }

      .content {
        padding: 16px 18px;
        position: relative;

        .reasonTime {
          background: var(--secondary-border-color);
          padding: 8px 18px;
          border-radius: 49px;
          margin-bottom: 10px;
          display: inline-flex;
          align-items: center;
          font-size: calc(var(--font-size-add) + 14px);
          color: var(--text-color);

          .light {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }

          .arrow {
            width: 12px;
            height: 12px;
            margin-left: 4px;
            cursor: pointer;
            transition: all 0.3s linear;

            &.down {
              transform: rotate(180deg);
            }
          }
        }

        .reason {
          border-left: 1px solid #CCCCCC;
          padding-left: 8px;
          white-space: pre-line;
          margin-bottom: 10px;
          color: var(--secondary-text);
          font-size: calc(var(--font-size-add) + 14px);
          line-height: 24px;
          height: auto;
          overflow: hidden;
          transition: all 0.3s linear;
          user-select: text;
        }

        &.risk {
          .text {
            text-indent: 24px;
            display: flex;
          }

          .operates {
            display: none;
          }
        }

        &.risk .text:not(.ai) {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none !important;
        }

        .text {
          border-bottom: 2px solid rgba(255, 255, 255, 0.3);
          padding-bottom: 12px;
          margin-bottom: 12px;
          font-size: calc(var(--font-size-add) + 16px);
          user-select: text;
          position: relative;

          &.ai {
            border-bottom: 2px solid rgba(153, 153, 153, 0.2);
          }

          &.ing {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }

          .riskIcon {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            display: inline-block;
            vertical-align: middle;
            z-index: 9;
            position: absolute;
            top: 4px;
          }
        }

        .loading {
          display: flex;
          margin-top: 4px;
          align-items: center;

          .ing {
            margin-right: 10px;
            font-size: calc(var(--font-size-add) + 16px);
            color: rgb(var(--primary-color));
          }

          .dot1,
          .dot2,
          .dot3 {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 5px;
          }

          .dot1 {
            animation: dotLoadingOne 1s linear infinite;
          }

          .dot2 {
            animation: dotLoadingTwo 1s linear infinite;
          }

          .dot3 {
            animation: dotLoadingThree 1s linear infinite;
          }
        }
      }

      .bottomInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .vipprice {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          color: #FF7B00;

          svg {
            width: 18px;
            height: 18px;
          }

          p {
            height: 24px;
          }
        }

        .price {
          display: flex;
          align-items: center;
          color: var(--secondary-text);
          font-size: calc(var(--font-size-add) + 14px);
          font-weight: 400;

          svg {
            width: 14px;
            height: 14px;
            margin-left: 4px;
            margin-right: 20px;

            path {
              fill: var(--secondary-text);
            }
          }

          .vipsvg {
            width: 18px;
            height: 18px;
            margin-right: 0px !important;

            path {
              fill: #AB5200;
            }
          }

          .viptext {
            height: 24px;
            margin-left: 5px;
            font-weight: 400;
            font-size: 14px;
            color: #AB5200;
            cursor: pointer;
          }
        }
      }

      .operates {
        display: flex;

        .icon {
          width: 36px;
          height: 36px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          cursor: pointer;
          transition: opacity 0.3s;

          &:hover {
            opacity: 0.7;
          }

          svg {
            width: 24px;
            height: 24px;
          }

          &.regen {
            background-color: var(--secondary-text);
            opacity: 0.6;
            margin-left: 40px;

            &:hover {
              opacity: 1;
            }

            svg path {
              fill: #fff;
            }
          }
        }

        .playing {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          &.humanPlaying .line {
            background-color: #fff !important;
          }

          .line {
            width: 2px;
            height: 20px;
            border-radius: 4px;
            background-color: rgb(var(--primary-color));

            &:first-child {
              height: 40%;
              animation: line1 0.8s linear infinite;
            }

            &:nth-child(2) {
              height: 70%;
              animation: line2 0.8s linear infinite;
            }

            &:nth-child(3) {
              height: 60%;
              animation: line3 0.8s linear infinite;
            }

            &:last-child {
              height: 40%;
              animation: line1 0.8s linear infinite;
            }
          }
        }
      }


      .aiMark {
        position: absolute;
        right: 14px;
        bottom: 4px;
        font-size: calc(var(--font-size-add) + 12px);
        color: var(--secondary-text);
      }

      .quote {
        background-color: var(--secondary-border-color);
        padding: 10px 16px;
        border-radius: 14px;
        margin-top: 20px;
        font-size: calc(var(--font-size-add) + 14px);
        color: var(--secondary-text);
        font-weight: bold;

        p {
          max-width: 100%;
          overflow: hidden;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-break: break-all;
          white-space: pre-wrap;
          display: -webkit-box;
        }
      }
    }

    &.ai {
      justify-content: flex-start;

      .info .content {
        background-color: var(--background-color);
        color: var(--text-color);
        border-radius: 4px 30px 30px 30px;

        &.risk {
          background-color: #FF5852;
          color: #fff;

          .text {
            border-bottom: 2px solid rgba(255, 255, 255, 0.3);
          }

          .price {
            svg path {
              fill: #fff;
            }
          }

          .price,
          .viptext,
          .vipprice {
            color: #fff;
          }
        }
      }



      .avatar {
        margin-right: 10px;
        width: 40px;
        height: 40px;

        img {
          width: 28px;
          height: 28px;
        }
      }

      .icon {
        background: rgba(153, 153, 153, 0.1);
      }
    }

    &.user {
      justify-content: flex-end;

      .avatar {
        margin-left: 10px;
      }

      .info {

        .app,
        .desc {
          text-align: right;
        }

        .appDesc {
          justify-content: flex-end;
        }

        .userImg {
          margin-bottom: 20px;
          display: flex;
          justify-content: flex-end;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .content {
          background-color: rgb(var(--primary-color));
          border-radius: 30px 4px 30px 30px;
          color: #fff;

          &.risk {
            background-color: #FF5852;
          }
        }
      }

      .icon {
        background: rgba(255, 255, 255, 0.1);

        svg path,
        svg rect {
          fill: #fff;
        }

        &.copy {
          svg path {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }

    .references {
      font-size: calc(var(--font-size-add) + 14px);
      color: var(--secondary-text);
      font-weight: bold;
      white-space: nowrap;
      gap: 10px;
      padding: 20px 20px 14px;

      .reference {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        width: 280px;
        height: 100px;
        border-radius: 12px;
        background: var(---element-bg);
        padding: 10px;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
          opacity: 0.8;
        }

        .ref_info {
          display: flex;
        }

        .title {
          font-size: calc(var(--font-size-add) + 14px);
          color: var(--text-color);
          font-weight: bold;
          margin-bottom: 4px;
          white-space: wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }

        .image {
          width: 40px;
          height: 40px;
          margin-left: auto;
          border-radius: 4px;
          object-fit: cover;
        }
      }
    }
  }

}

@keyframes dotLoadingOne {
  0% {
    background-color: rgba(var(--primary-color), 1);
  }

  25% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  50% {
    background-color: rgba(var(--primary-color), 0.2);
  }

  50% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  100% {
    background-color: rgba(var(--primary-color), 1);
  }
}

@keyframes dotLoadingTwo {
  0% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  25% {
    background-color: rgba(var(--primary-color), 1);
  }

  50% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  75% {
    background-color: rgba(var(--primary-color), 0.2);
  }

  100% {
    background-color: rgba(var(--primary-color), 0.5);
  }
}

@keyframes dotLoadingThree {
  0% {
    background-color: rgba(var(--primary-color), 0.2);
  }

  25% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  50% {
    background-color: rgba(var(--primary-color), 1);
  }

  75% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  100% {
    background-color: rgba(var(--primary-color), 0.2);
  }
}

@keyframes line1 {
  0% {
    height: 50%;
  }

  25% {
    height: 60%;
  }

  50% {
    height: 70%;
  }

  75% {
    height: 60%;
  }

  100% {
    height: 50%;
  }
}

@keyframes line2 {
  0% {
    height: 60%;
  }

  25% {
    height: 50%;
  }

  50% {
    height: 40%;
  }

  75% {
    height: 50%;
  }

  100% {
    height: 60%;
  }
}

@keyframes line3 {
  0% {
    height: 70%;
  }

  25% {
    height: 60%;
  }

  50% {
    height: 50%;
  }

  75% {
    height: 40%;
  }

  100% {
    height: 50%;
  }
}

.functionCall {
  color: var(--text-color);
  display: flex;
  align-items: center;

  .loading {
    animation: functionCall 1s linear infinite;
    margin-right: 10px;
  }

  .svg {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    path {
      fill: rgb(var(--primary-color));
    }
  }
}

.functionFinish {
  position: relative;
  color: rgb(var(--primary-color));
  margin-bottom: 16px;

  .svg {
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: rgb(var(--primary-color));
      }
    }
  }
}

@keyframes functionCall {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}