.allbox {
  flex-direction: column;
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;

    .titletips {
      color: red;
      margin: 0px 3px;
    }
  }

  .titlemax {
    margin-left: 3px;
  }

  svg {
    margin-left: 3px;
    cursor: pointer;
  }

  .titletipsbox {
    width: 300px;
    height: 50px;
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
  }
}

.textareabox {
  width: 100%;
  position: relative;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #F5F5F5;

  .textarea {
    padding: 10px 15px 0px;
    // height: 20px;
    font-size: 16px;
    width: calc(100% - 34px);
    border-radius: 40px 40px 20px 20px;
    resize: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }
  }

  .Onetextarea {
    padding: 10px 15px 7px;
    font-size: 16px;
    width: calc(100% - 34px);
    background: #F5F5F5;
    border-radius: 40px 40px 20px 20px;
    border: 1px solid #F5F5F5;
    resize: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      display: none;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }
  }

  .minText {
    position: absolute;
    bottom: 12px;
    right: 20px;
    font-size: 14px;
    color: rgba(153, 153, 153, 0.6);
  }

  .maxText {
    position: absolute;
    bottom: 5px;
    right: 20px;
    font-size: 14px;
    color: rgba(153, 153, 153, 0.6);
  }

  .tipstext {
    position: absolute;
    bottom: 3px;
    left: 20px;
    color: #FF5852;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    p {
      height: 20px;
      font-weight: 500;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .tipsOnetext {
    position: absolute;
    bottom: 14px;
    right: 10px;
    color: #FF5852;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    p {
      height: 20px;
      font-weight: 500;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

.focused {
  border: 1px solid #565CFA;
}

.error {
  border: 1px solid #FF2922;
}

.notbalancebox {
  width: 344px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 24px 30px;
  border-radius: 30px;
  position: relative;
}

.notbalanceTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  p {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}

.texttips {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}

.modelbuttonbox {
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
}

.Savebutton {
  background: #565CFA;
  color: var(--text-reverse-color);
  width: 130px;
}