.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.child {
  padding: 20px;
}

.contentbox {
  width: 600px;
  height: 244px;
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 30px
}

.fontsizebox {
  width: 540px;
  height: 184px;
  display: flex;
  margin-bottom: 30px;
  padding: 30px;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 30px
}

.boxtext {
  height: 23px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 20px);
  color: var(--text-color);
  line-height: 23px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 30px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 540px;
  height: 80px;
  border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  margin: 0px 30px;
}

.label {
  height: 22px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.righticon {
  width: 24px;
  height: 24px;
}

.avatar {
  width: 50px;
  height: 50px;
}

.usercontentflex {
  justify-content: flex-end;
}

.usercontentbox {
  padding: 16px;
  display: flex;
  height: 24px;
  background: #565CFA;
  border-radius: 18px 4px 18px 18px;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.usercontent {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-reverse-color);
}

.systemcontentbox {
  padding: 16px;
  display: flex;
  height: 24px;
  background: #F5F5F5;
  border-radius: 4px 18px 18px 18px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 10px;
}

.systemcontent {
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);
}

/* FontSizeSlider.css */
.contentlistbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.slider_container {
  width: 560px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slider_labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-size: calc(var(--font-size-add) + 14px);
}

.slider-value {
  color: blue;
  font-weight: bold;
}

.slider_marks {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.mark {
  font-size: calc(var(--font-size-add) + 12px);
  color: var(--text-color);
}