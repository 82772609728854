.shareChat {
  background: var(--secondary-bg);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 126px;
    border-bottom: 1px solid var(--border-color);
    padding: 30px 0 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    .title {
      font-size: calc(var(--font-size) + 18px);
      color: var(--text-color);
      margin-bottom: 16px;
      font-weight: bold;
    }

    .user {
      display: flex;
      align-items: center;
      color: var(--secondary-text);
      font-size: calc(var(--font-size) + 16px);

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }

  .content {
    width: 100%;
    flex: 1;
    height: 0;

    .item {
      width: 828px;
      margin: 0 auto;
      margin-top: 30px;
    }
  }

  .hiddenFooter {
    visibility: hidden;
    height: 122px;
  }

  .footer {
    height: 112px;
    border-top: 1px solid var(--border-color);
    background: rgba(245, 245, 245, 0.86);
    padding: 28px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      width: 208px;
      height: 55px;
      border-radius: 20px;

      svg {
        margin-left: 4px;
      }
    }
  }
}