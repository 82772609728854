.chatContainer {
  height: 100%;
  background-color: #f5f5f5;
  overflow: hidden;

  .headerPos {
    height: 20px;
    flex-shrink: 0;
  }

  .bottomPos {
    height: 20px;
    flex-shrink: 0;
  }

  &:hover {
    .listBox {
      &::-webkit-scrollbar-thumb {
        background-color: rgb(var(--secondary-text-rbg), 0.2);
        width: 4px;
      }
    }
  }

  .listBox {
    display: flex;
    flex-direction: column-reverse;
    border-left: 1px solid #ddd;
    height: 100%;
    overflow: auto;

    .loadMore {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    .agent {
      width: 600px;
      margin: 40px auto;
      text-align: center;

      .avatar {
        width: 150px;
        height: 150px;
        border-radius: 50px;
      }

      .title {
        font-size: calc(var(--font-size-add) + 24px);
        color: var(--text-color);
        font-weight: bold;
        margin-top: 30px;
      }

      .desc {
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--secondary-text);
        margin-top: 16px;
        text-align: left;
      }
    }
  }

  .chatItemBox {
    padding: 30px 10px 0px;
    width: calc(100% - 20px);
    margin: 0 auto;
  }

  .chatItem {
    font-size: calc(var(--font-size-add) + 16px);
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 20px;
      background-color: var(--background-color);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      max-width: 80%;

      .app {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 16px);
        font-weight: bold;
      }

      .appDesc {
        display: flex;

        .model {
          color: var(--secondary-text);
          margin-right: 15px;
        }
      }

      .time {
        font-size: calc(var(--font-size-add) + 14px);
        font-weight: 500;
        color: var(--secondary-text);
        line-height: 1;
        margin-top: 6px;
        margin-bottom: 15px;
      }

      .content {
        padding: 16px 18px;

        &.risk {
          .text {
            text-indent: 24px;
            display: flex;
          }

          .operates {
            display: none;
          }
        }

        &.risk .text:not(.ai) {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none !important;
        }

        .text {
          border-bottom: 2px solid rgba(255, 255, 255, 0.3);
          padding-bottom: 12px;
          margin-bottom: 12px;
          font-size: calc(var(--font-size-add) + 16px);
          user-select: text;
          position: relative;

          &.ai {
            border-bottom: 2px solid rgba(153, 153, 153, 0.2);
          }

          &.ing {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }

          .riskIcon {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            top: 4px;
          }
        }

        .loading {
          display: flex;
          margin-top: 4px;
          align-items: center;

          .ing {
            margin-right: 10px;
            font-size: calc(var(--font-size-add) + 16px);
            color: rgb(var(--primary-color));
          }

          .dot1,
          .dot2,
          .dot3 {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 5px;
          }

          .dot1 {
            animation: dotLoadingOne 1s linear infinite;
          }

          .dot2 {
            animation: dotLoadingTwo 1s linear infinite;
          }

          .dot3 {
            animation: dotLoadingThree 1s linear infinite;
          }
        }
      }

      .bottomInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .vipprice {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          color: #FF7B00;
          position: relative;

          svg {
            width: 18px;
            height: 18px;
          }

          p {
            height: 24px;
          }

          .aitext {
            position: absolute;
            right: 0px;
            bottom: -20px;
            font-size: 10px;
            font-weight: bold;
            color: #999;
          }
        }

        .price {
          display: flex;
          align-items: center;
          color: var(--secondary-text);
          font-size: calc(var(--font-size-add) + 14px);
          font-weight: 400;
          position: relative;

          svg {
            width: 14px;
            height: 14px;
            margin-left: 4px;
            margin-right: 20px;

            path {
              fill: var(--secondary-text);
            }
          }

          .vipsvg {
            width: 18px;
            height: 18px;
            margin-right: 0px !important;

            path {
              fill: #AB5200;
            }
          }

          .viptext {
            height: 24px;
            margin-left: 5px;
            font-weight: 400;
            font-size: 14px;
            color: #AB5200;
            cursor: pointer;
          }

          .aitext {
            position: absolute;
            right: 0px;
            bottom: -20px;
            font-size: 10px;
            font-weight: bold;
            color: #999;
          }
        }
      }

      .operates {
        display: flex;

        .icon {
          width: 36px;
          height: 36px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          cursor: pointer;
          transition: opacity 0.3s;

          &:hover {
            opacity: 0.7;
          }

          svg {
            width: 24px;
            height: 24px;
          }

          &.regen {
            background-color: var(--secondary-text);
            opacity: 0.6;
            margin-left: 40px;

            &:hover {
              opacity: 1;
            }

            svg path {
              fill: #fff;
            }
          }
        }

        .playing {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          &.humanPlaying .line {
            background-color: #fff !important;
          }

          .line {
            width: 2px;
            height: 20px;
            border-radius: 4px;
            background-color: rgb(var(--primary-color));

            &:first-child {
              height: 40%;
              animation: line1 0.8s linear infinite;
            }

            &:nth-child(2) {
              height: 70%;
              animation: line2 0.8s linear infinite;
            }

            &:nth-child(3) {
              height: 60%;
              animation: line3 0.8s linear infinite;
            }

            &:last-child {
              height: 40%;
              animation: line1 0.8s linear infinite;
            }
          }
        }
      }

      .quote {
        background-color: var(--secondary-border-color);
        padding: 10px 16px;
        border-radius: 14px;
        margin-top: 20px;
        font-size: calc(var(--font-size-add) + 14px);
        color: var(--secondary-text);
        font-weight: bold;

        p {
          max-width: 100%;
          overflow: hidden;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-break: break-all;
          white-space: pre-wrap;
          display: -webkit-box;
        }
      }
    }

    &.ai {
      justify-content: flex-start;

      .info .content {
        background-color: var(--background-color);
        color: var(--text-color);
        border-radius: 4px 30px 30px 30px;

        &.risk {
          background-color: #FF5852;
          color: #fff;

          .text {
            border-bottom: 2px solid rgba(255, 255, 255, 0.3);
          }

          .price {
            svg path {
              fill: #fff;
            }
          }

          .price,
          .viptext,
          .vipprice {
            color: #fff;
          }
        }
      }

      .avatar {
        margin-right: 10px;
      }

      .icon {
        background: rgba(153, 153, 153, 0.1);
      }
    }

    &.user {
      justify-content: flex-end;

      .avatar {
        margin-left: 10px;
      }

      .info {

        .app,
        .desc {
          text-align: right;
        }

        .appDesc {
          justify-content: flex-end;
        }

        .userImg {
          margin-bottom: 20px;
          display: flex;
          justify-content: flex-end;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .content {
          background-color: rgb(var(--primary-color));
          border-radius: 30px 4px 30px 30px;
          color: #fff;

          &.risk {
            background-color: #FF5852;
          }
        }
      }

      .icon {
        background: rgba(255, 255, 255, 0.1);

        svg path,
        svg rect {
          fill: #fff;
        }
      }
    }
  }
}

@keyframes dotLoadingOne {
  0% {
    background-color: rgba(var(--primary-color), 1);
  }

  25% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  50% {
    background-color: rgba(var(--primary-color), 0.2);
  }

  50% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  100% {
    background-color: rgba(var(--primary-color), 1);
  }
}

@keyframes dotLoadingTwo {
  0% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  25% {
    background-color: rgba(var(--primary-color), 1);
  }

  50% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  75% {
    background-color: rgba(var(--primary-color), 0.2);
  }

  100% {
    background-color: rgba(var(--primary-color), 0.5);
  }
}

@keyframes dotLoadingThree {
  0% {
    background-color: rgba(var(--primary-color), 0.2);
  }

  25% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  50% {
    background-color: rgba(var(--primary-color), 1);
  }

  75% {
    background-color: rgba(var(--primary-color), 0.5);
  }

  100% {
    background-color: rgba(var(--primary-color), 0.2);
  }
}

@keyframes line1 {
  0% {
    height: 50%;
  }

  25% {
    height: 60%;
  }

  50% {
    height: 70%;
  }

  75% {
    height: 60%;
  }

  100% {
    height: 50%;
  }
}

@keyframes line2 {
  0% {
    height: 60%;
  }

  25% {
    height: 50%;
  }

  50% {
    height: 40%;
  }

  75% {
    height: 50%;
  }

  100% {
    height: 60%;
  }
}

@keyframes line3 {
  0% {
    height: 70%;
  }

  25% {
    height: 60%;
  }

  50% {
    height: 50%;
  }

  75% {
    height: 40%;
  }

  100% {
    height: 50%;
  }
}