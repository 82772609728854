.geminiOp {
  display: flex;
  height: 100%;
  box-sizing: border-box;

  svg {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {

      path,
      rect {
        fill: rgb(var(--primary-color));
      }
    }
  }
}

.upIcon svg {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {

    path,
    rect {
      fill: rgb(var(--primary-color));
    }
  }
}

.hasFile {
  padding-top: 115px !important;

  .fileList {
    position: absolute;
    top: 10px;
    left: 30px;
    margin-bottom: 15px;
    display: flex;

    .fileItem {
      width: 90px;
      height: 90px;
      border-radius: 14px;
      position: relative;
      background: var(--secondary-bg);
      margin-right: 30px;

      &.max {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
          width: 26px;
          height: 26px;
          margin-bottom: 4px;
        }

        .maxText {
          width: 60px;
          font-weight: 400;
          text-align: center;
          color: var(--secondary-text);
          font-size: calc(var(--font-size-add) + 12px);
        }
      }

      .closeIcon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -5px;
        right: -5px;
        cursor: pointer;
      }

      .img {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        object-fit: cover;
      }
    }

  }
}

.inputImgs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  .img {
    width: 100px;
    height: 100px;
    border-radius: 14px;
    object-fit: cover;
  }
}

.outputText {
  background-color: rgb(var(--primary-color));
  color: #fff;
  padding: 18px 16px;
  border-radius: 4px 20px 20px 20px;
  -webkit-user-select: text;
  user-select: text;

  &.hasImg {
    margin-top: 20px;
  }
}

.outputImgs {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  max-width: 1080px;
  padding-bottom: 10px;

  .item {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }

}