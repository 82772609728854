.content {
  display: flex;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--secondary-bg);
  position: relative;
  padding-bottom: 112px;
  justify-content: center;

  .info {
    position: relative;
    width: 600px;
    padding: 0 30px;
    margin: 80px auto 0;

    .selectBox {
      position: absolute;
      flex-direction: column;
      font-size: 18px;
      left: -80px;

      p {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    .params {
      margin-bottom: 30px;
    }

    .avatarBox {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      .avatar {
        width: 110px;
        height: 110px;
        border-radius: 30px;
        background-color: rgba(var(--primary-color), 0.1);
        position: relative;
        cursor: pointer;

        .img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .camera {
          position: absolute;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: -6px;
          right: -6px;
          background-color: rgb(var(--primary-color));
          border-radius: 50%;
          cursor: pointer;

          .icon,
          .loading {
            width: 20px;
            height: 20px;

            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .inputBox {
      width: 100%;
      position: relative;

      .input {
        border: 1px solid transparent;
        width: 100%;
        height: 60px;
        border-radius: 20px;
        padding: 16px;
        box-sizing: border-box;
        font-size: calc(var(--font-size-add) + 16px);

        &:focus {
          border-color: rgb(var(--primary-color));
        }
      }

      .tips {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0, 5;
        color: rgb(var(--secondary-text-rbg));
      }
    }

    .promptBox {
      width: 100%;
      height: 144px;
      overflow: hidden;
      background-color: #fff;
      border-radius: 20px;

      .prompt {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        overflow: hidden;
        min-height: 144px;
        border: 1px solid transparent;
        border-radius: 20px;

        &:empty::before {
          content: attr(content);
          color: rgb(var(--secondary-text-rbg));
        }

        &:focus {
          border-color: rgb(var(--primary-color));
        }
      }
    }

    .bottomTips {
      position: absolute;
      right: 16px;
      bottom: 12px;
      color: rgb(var(--secondary-text-rbg));
    }
  }

  .title {
    font-size: calc(var(--font-size-add) + 18px);
    color: var(--text-color);
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .require {
      color: #FF2922;
      display: inline-block;
      margin-left: 4px;
    }

    svg {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .slider {
    margin-top: 40px;
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
      width: 120px;
      height: 120px;
    }

    .text {
      opacity: 0.5;
      font-size: calc(var(--font-size-add) + 16px);
      margin-top: 25px;
      color: rgb(var(--secondary-text-rbg));
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    height: 112px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(245, 245, 245, 0.86);
    border-top: 1px solid var(--border-color);

    .btn {
      margin-right: 30px;

      &.exp {
        background: #FF7B00;
        color: #fff;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tipsContent {
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 20px;
  width: 360px;
}

.tipsFooter {
  margin-top: 30px;
  text-align: right;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 20px;
  font-weight: 400;
  color: var(--text-color);
  cursor: pointer;
}

.protocolInfo {
  background-color: var(--background-color);
  width: 932px;
  height: 700px;
  border-radius: 30px;
  padding: 30px 20px 30px 30px;
  box-sizing: border-box;
  position: relative;

  .cmodelTitleBox {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 20px !important;
      height: 20px !important;
      cursor: pointer;
    }
  }
}

.type {
  width: 580px;
  height: 70px;
  border-radius: 40px;
  padding: 0px 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin: 0px auto 50px;

  .chatType {
    width: 130px;
    padding: 5px 25px;
    height: 40px;
    border-radius: 30px;
    margin-right: 30px;
    font-size: calc(var(--font-size-add) + 16px);
    color: #999;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:last-of-type {
      margin-right: 0;
    }

    .icon {
      width: 0;
      height: 0;
      transition: all 0.3s ease;
    }

    &.active {
      color: #fff;
      background: #565CFA;
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 18px);

      .icon {
        width: 18px;
        height: 8px;
      }
    }
  }
}

.dataBox {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  box-shadow: none;
  background-color: #f8faff;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.updateBox {
  padding: 3px;
  border: 1px solid #999;
  margin: 0px 3px;
  padding: 2px 10px;
  border-radius: 5px;
}

.testInputBox {
  flex-direction: column;
  min-height: 100px;
  position: relative;

  .tableBox {
    width: 100%;
    border: 1px solid #fff;

    tr {
      position: relative;
    }

    tr:hover {
      background-color: #F8FAFF;
    }

    td {
      font-size: 18px;
      text-align: center;
      align-items: center;
      padding: 5px 0px;
    }

    .testText {
      max-height: 48px;
      overflow-y: auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .deleteicon {
      width: 20px;
      height: 20px;
      position: absolute;
      align-items: center;
      top: 1px;
      right: 5px;
    }
  }
}

.selectkbs {
  width: 100%;
  flex-direction: column;

  .kbsTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .item {
    width: 300px;
    margin: 10px 30px 20px;
    height: 86px;
    border-radius: 20px;
    background-color: var(--secondary-bg);
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    cursor: pointer;

    .avatar {
      width: 54px;
      height: 54px;
      border-radius: 16px;
      background-color: #e9e9e9;
      overflow: hidden;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.defaultAvatar {
        img {
          width: 34px;
          height: 34px;
        }
      }
    }

    .info {
      flex: 1;
      overflow: hidden;

      .name {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 16px);
        font-weight: bold;
        line-height: 20px;
      }

      .intro {
        width: 100%;
        color: rgb(var(--secondary-text-rbg));
        font-size: calc(var(--font-size-add) + 14px);
        line-height: 18px;
        opacity: 0.7;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 4px;
        height: 18px;
      }
    }

    &:hover,
    &.active {
      background-color: rgba(var(--primary-color), 0.1);

      .iteminfo {
        .name {
          color: rgb(var(--primary-color));
        }

        .intro {
          color: rgb(var(--primary-color));
        }
      }
    }
  }

  .shipitem {
    width: 290px;
    margin-bottom: 10px;
    height: 86px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgba(var(--primary-color), 0.1);

    .avatar {
      width: 54px;
      height: 54px;
      border-radius: 16px;
      background-color: #e9e9e9;
      overflow: hidden;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.defaultAvatar {
        img {
          width: 34px;
          height: 34px;
        }
      }
    }

    .info {
      flex: 1;
      overflow: hidden;

      .name {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 16px);
        font-weight: bold;
        line-height: 20px;
      }

      .intro {
        width: 100%;
        color: rgb(var(--secondary-text-rbg));
        font-size: calc(var(--font-size-add) + 14px);
        line-height: 18px;
        opacity: 0.7;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 4px;
        height: 18px;
      }
    }
  }
}

.nullkbs {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #9A99A5;
    font-size: 18px;
    margin: 10px 0px;
  }
}

.shipTitle {
  font-size: 18px;
  font-weight: bold;
}

.shipbutton {
  width: 150px !important;
  margin: 10px 0px;
}