:root {
  --nav-width: 279px
}

.history {
  height: 100%;
  overflow: auto;
  width: var(--nav-width);
  border-right: 1px solid var(--border-color);
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }
}


.title {
  margin: 30px 20px;
  height: 34px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: 900;
  font-size: 30px;
  color: var(--text-color);
  line-height: 35px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}

.titlenavigations {
  width: 280px;
  height: 64px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  background: #F5F5F5;

  .titlesvg {
    margin: 3px 14px 0px 28px;
    width: 36px;
    height: 36px;
  }

  .titlecontent {
    font-size: calc(var(--font-size-add) + 18px);
    font-weight: 500;
    color: #333333;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  }

  .titlecontent.select {
    font-weight: bold;
    font-size: 18px;
    color: #565CFA;
  }
}



.titlenavigations:hover {
  background: #EEEEEE;
}

.titlenavigations .navigations_select {
  background: #EEEFFE;
}

.navigations {
  width: 280px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;

  .svg {
    margin: 0px 10px 0px 28px;
    width: 30px;
    height: 30px;
  }

  .content {
    color: #666;
    font-size: calc(var(--font-size-add) + 18px);
    font-weight: 400;
  }
}

.outtions {
  width: 280px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;

  svg {
    margin: 0px 10px 0px 28px;
    width: 30px;
    height: 30px;

    path {
      fill: #FF5852
    }
  }

  div {
    font-size: calc(var(--font-size-add) + 18px);
    font-weight: 400;
    color: #FF5852;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  }
}

.navigations:hover {
  background: #F5F5F5;
}

.navigations_select {
  background-color: #EEEFFE;

  .content {
    color: #565CFA;
  }
}

.navigations_select:hover {
  background: #EEEFFE;
}

.selecticon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 30px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  opacity: 0.5;
}