.swiper {
  width: 800px;
  height: 800px;
  border-radius: 30px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(30px);
  position: relative;


  .mySwiper {
    width: 100%;
    height: 100%;

    .swiperSlide {
      overflow: hidden;
    }

    .previewImg {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ops {
    position: absolute;
    bottom: 48px;
    z-index: 9;
    display: flex;
    justify-content: center;
    width: 100%;

    .opItem {
      padding: 12px 22px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 16px;
      height: 46px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #fff;
      cursor: pointer;
      font-weight: 400;
      font-size: calc(var(--font-size-add) + 14px);
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      svg {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }

      .slider {
        width: 100px;
        margin-left: 20px;
        margin-right: 20px;
        height: 2px;
        position: relative;
        padding: 16px 0;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        touch-action: none;

        .sliderRail {
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          border-radius: 5px;
          background: rgba(229, 230, 235, 0.5);
        }

        .sliderTrack {
          display: block;
          position: absolute;
          height: 2px;
          border-radius: 5px;
          background: #fff;
        }

        .sliderThumb {
          position: absolute;
          margin-left: -6px;
          width: 12px;
          height: 12px;
          box-sizing: border-box;
          border-radius: 50%;
          outline: 0;
          background-color: #fff;
          transition-property: box-shadow, transform;
          transition-timing-function: ease;
          transition-duration: 120ms;
          transform-origin: center;
        }
      }
    }
  }

  .pre,
  .next {
    width: 50px;
    height: 50px;
    opacity: 0.8;
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 11;

    &:hover {
      opacity: 1;
    }
  }

  .pre {
    left: 24px;
  }

  .next {
    right: 24px;
    transform: rotate(180deg);
  }
}

.swiperPagination {
  bottom: 20px;
}