.seed {

  .num {
    display: flex;
    align-items: center;

    .value {
      width: 110px;
      height: 50px;
      background-color: var(--secondary-bg);
      border-radius: 18px;
      border: none;
      margin-left: 12px;
      margin-right: 12px;
      text-align: center;
      font-size: calc(var(--font-size-add) + 18px);
      color: var(--text-color);
    }

    .icon {
      width: 50px;
      height: 50px;
      border-radius: 18px;
      border: 1px solid rgba(var(--secondary-text-rbg), 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;

        path {
          stroke: var(--text-color);
        }
      }
    }
  }
}