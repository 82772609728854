.delle3Op {
  display: flex;
  height: 100%;
  box-sizing: border-box;

  svg {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {

      path,
      rect {
        fill: rgb(var(--primary-color));
      }
    }
  }
}

.dalle3 {
  display: flex;
}

.list {
  height: calc(100% - 80px);
  position: relative;
  padding-bottom: 122px;
  box-sizing: border-box;

  .container {
    padding: 30px 10px 0px 60px;
    margin-right: 50px;
  }

  .info {
    .item {
      margin-bottom: 30px;

      &.user {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .input {
          background-color: rgb(var(--primary-color));
          color: #fff;
          padding: 18px 16px;
          border-radius: 20px 4px 20px 20px;
          user-select: text;
        }

        .risk {
          background-color: #FF5852;
          color: #fff;
          padding: 18px 16px;
          border-radius: 20px 4px 20px 20px;
          user-select: text;
          position: relative;
          text-indent: 24px;

          svg {
            position: absolute;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            display: inline-block;
            vertical-align: middle;
            z-index: 9;
            position: absolute;
            top: 18px;
            left: 16px;
          }
        }
      }
    }
  }

  .inputArea {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 28px 30px 40px;
    box-sizing: border-box;
    display: flex;
    background-color: var(--background-color);
    border-top: 1px solid rgba(153, 153, 153, 0.2);
    align-items: center;

    .areaBox {
      flex: 1;
      max-height: 160px;
      background-color: var(--secondary-bg);
      box-sizing: border-box;
      border-radius: 20px;
    }

    .area {
      border-radius: 20px;
      border: none;
      padding: 19px 16px;
      border: 1px solid transparent;

      &:focus-within {
        border-color: rgb(var(--primary-color));
      }

      &:empty::before {
        content: attr(content);
        color: #555;
      }
    }

    .riskDisable {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      z-index: 9;
      font-size: calc(var(--font-size-add) + 16px);
      font-weight: 500;
    }

    .priceNum {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-color);
      font-size: calc(var(--font-size-add) + 16px);
      font-weight: 400;

      .num {
        font-weight: bold;
        font-size: calc(var(--font-size-add) + 18px);
        line-height: 21px;
      }


      .vip {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(var(--font-size-add) + 14px);
        color: rgb(var(--primary-color));
        line-height: 16px;
        cursor: pointer;
        margin-left: 20px;

        svg {
          margin-top: -4px;

          path {
            fill: rgb(var(--primary-color));
          }
        }
      }

      &.isVip {
        .num {
          text-decoration: line-through;
        }

        .vip {
          color: #FF7B00;

          svg {
            display: none;
          }
        }
      }
    }
  }
}

.historyTitle {
  height: 80px;
  border-bottom: 1px solid var(--border-color);
  padding: 28px 20px;
  box-sizing: border-box;

  .text {
    font-size: calc(var(--font-size-add) + 20px);
    font-weight: bold;
    color: var(--text-color);
    line-height: 20px;
  }
}

.historyContent {
  height: calc(100% - 80px);

  .historyBox {
    padding: 30px 30px 0 30px;

    .historyItem {
      width: 505px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: var(--background-color);
      margin: 0 auto 20px;
      position: relative;
      cursor: pointer;

      &:hover {

        .prompt,
        .time,
        .count {
          color: rgb(var(--primary-color));
        }
      }

      .risk {
        color: #FF5852;
        border-radius: 20px 4px 20px 20px;
        user-select: text;
        position: relative;
        text-indent: 24px;
        margin-bottom: 14px;

        svg {
          position: absolute;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          display: inline-block;
          vertical-align: middle;
          z-index: 9;
          position: absolute;
          top: 1px;
          left: 0;
        }
      }

      .prompt {
        font-weight: 400;
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--text-color);
        line-height: 24px;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .time,
      .count {
        font-size: calc(var(--font-size-add) + 14px);
        color: var(--secondary-text);
        line-height: 16px;
        margin-top: 10px;
      }

      .del {
        position: absolute;
        bottom: 28px;
        right: 20px;
        cursor: pointer;
        width: 26px;
        height: 26px;

        &:hover {
          opacity: 0.8;

          path {
            fill: rgb(var(--primary-color));
          }
        }
      }

      .aimark {
        position: absolute;
        right: 8px;
        bottom: 4px;
        padding: 4px 8px;
        border-radius: 20px;
        color: var(--secondary-text);
        font-size: calc(var(--font-size-add) + 12px);
        font-weight: 400;
      }
    }
  }
}

.logoTime {
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);

  .logo {
    width: 50px;
    height: 50px;
    border-radius: 20px;
    background-color: var(--secondary-bg);
    display: flex;
    justify-content: center;
    align-items: center;


    img {
      width: 30px;
      height: 30px;
    }
  }

  .time {
    margin-left: 10px;
    margin-right: 10px;

    &.human {
      margin-right: 0;
    }
  }
}

.aiCreation {
  margin-bottom: 30px;

  .loading {
    display: flex;
    align-items: center;
    padding: 18px 16px;
    width: 260px;
    box-sizing: border-box;
    border-radius: 4px 20px 20px 20px;
    background-color: var(--secondary-bg);
    color: rgb(var(--primary-color));
    font-size: calc(var(--font-size-add) + 16px);

    svg {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      animation: spin 1s ease-in-out infinite;
    }
  }

  .creationTips {
    background-color: var(--secondary-bg);
    border-radius: 4px 20px 20px 20px;
    padding: 18px 16px;
    min-width: 80%;
    color: var(--text-color);
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: 400;
    line-height: 24px;
  }

  .risk {
    background-color: #FF5852;
    color: #fff;
    padding: 18px 16px;
    border-radius: 4px 20px 20px 20px;
    user-select: text;
    position: relative;
    text-indent: 24px;

    svg {
      position: absolute;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
      z-index: 9;
      position: absolute;
      top: 18px;
      left: 16px;
    }
  }

  .creationImg {
    position: relative;
    width: 400px;
    height: 300px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 30px;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .aimark {
      position: absolute;
      right: 12px;
      bottom: 8px;
      padding: 4px 8px;
      border-radius: 20px;
      color: #ffffff;
      font-size: calc(var(--font-size-add) + 12px);
      font-weight: 400;
      background: rgba(0, 0, 0, 0.5);
    }

    .favorite {
      border-radius: 16px;
      position: absolute;
      width: 46px;
      height: 46px;
      right: -66px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      svg {
        width: 28px;
        height: 28px;
      }
    }

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80%);
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 20px;

      .favorite:hover {
        svg path {
          fill: rgb(var(--primary-color));
        }
      }

      .more:hover {
        circle {
          fill: rgb(var(--primary-color));
        }
      }

      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .favorite {
      background: rgba(var(--secondary-text-rbg), 0.5);

      svg path {
        fill: #fff;
      }

      &.active {
        background: rgba(255, 41, 34, 0.1);

        svg path {
          fill: rgb(255, 41, 34);
        }
      }
    }

    .download {
      top: 20px;
      background-color: rgb(var(--primary-color));
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 148px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;

  .moreItem {
    padding: 12px 4px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    &.del {
      color: #FF2922;
    }
  }

  .moveImg {
    color: #FF7B00;
  }
}

.onlyGen3 {

  .model {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-bg);
    border-radius: 16px;
    height: 54px;
    box-sizing: border-box;
    margin-top: 12px;
    margin-bottom: 20px;
    padding: 12px 16px;
    width: 100%;
    cursor: pointer;

    &.active {
      background-color: rgba(var(--primary-color), 0.1);

      .name,
      .price,
      .key {
        color: rgb(var(--primary-color)) !important;

        svg {
          path {
            fill: rgb(var(--primary-color));
          }
        }
      }
    }

    .info {
      display: flex;
      align-items: center;

      .icon {
        box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
        border-radius: 8px;
        background-color: #fff;
        width: 30px;
        height: 30px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .name {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: calc(var(--font-size-add) + 14px);
        font-weight: bold;
        white-space: nowrap;
        margin-left: 8px;
        margin-right: 8px;
      }

      .key {
        font-weight: 400;
        margin-left: 4px;
        color: var(--secondary-text);
      }

      .recommend {
        border-radius: 10px 15px 15px 0px;
        background-color: rgb(var(--primary-color));
        width: 44px;
        height: 22px;
        color: #fff;
        font-size: calc(var(--font-size-add) + 12px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .free {
        border-radius: 10px 15px 15px 0px;
        background: #FF7B00;
        width: 66px;
        height: 22px;
        color: #fff;
        font-size: calc(var(--font-size-add) + 12px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .price {
      display: flex;
      align-items: center;
      color: var(--secondary-text);

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: var(--secondary-text);
        }
      }
    }
  }
}

.fullbox {
  width: 100%;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    width: 150px;
    height: 150px;
  }

  .prompt {
    color: #999;
  }
}