.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  height: calc(100% - 110px);
  padding-bottom: 110px;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.previewBox {
  width: 50%;
  height: 100%;
}

.footbutton {
  width: 100%;
  position: absolute;
  border-top: 1px solid #DDDDDD;
  padding-top: 28px;
  bottom: 34px;
  right: 0px;
}