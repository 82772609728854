.home {
  // background: #EBFAFF;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  // box-sizing: border-box;

  .logobox {
    width: 62px;
    height: 62px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 4px 16px 40px 0px rgba(14, 0, 186, 0.1);
    border-radius: 18px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
  }

  * {
    position: relative;
  }

  .textlg {
    background: linear-gradient(to bottom right,
        #1237b3 0%,
        #3370ff 40%,
        #4e83fd 80%,
        #85b1ff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .titleNav {
    display: flex;
    align-items: center;

    div {
      width: 130px;
      height: 21px;
      border-right: 1px solid rgba(153, 153, 153, 0.3);
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      // color: #333333;
      cursor: pointer;
    }
  }

  .titleNav div:last-child {
    border-right: none;
  }

  .titleBox {
    width: 1200px;
    // margin: 0px auto;

    .title {
      font-weight: 900;
      font-size: 54px;
      color: #333333;
      margin-bottom: 30px;
    }

    .synopsis {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      max-width: 540px;
      margin-bottom: 30px;
    }

    .bannerBox {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        width: 709px;
        height: 461px;
        position: absolute;
        top: 110px;
        right: 0px;
        max-width: none;
        max-height: none;
        z-index: 2;
      }

      .bgBox {
        z-index: 1;
        width: 709px;
        height: 601px;
        position: absolute;
        top: 70px;
        // right: -40px;
        background-image: url('../../assets/png/homebanner.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .downloadbox {
    width: 1280px;

    .comingSoon {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto 100px;
      cursor: pointer;

      div {
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 50px;
          height: 50px;
        }
      }

      .texta {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
      }

      .textb {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
      }
    }

    .download {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px 100px;
      cursor: pointer;

      div {
        width: 120px;
        height: 120px;
        background: #F5F5F5;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        svg {
          width: 50px;
          height: 50px;
        }
      }

      p {
        margin-top: 30px;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
      }
    }
  }

  .contTitleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .cntitle {
      font-weight: 900;
      font-size: 46px;
      line-height: 54px;
      margin-bottom: 10px;

      span {
        background: linear-gradient(90deg, #565CFA 0%, #C656FB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .entitle {
      font-weight: 300;
      font-size: 18px;
      color: rgba(153, 153, 153, 0.5);
    }

    .cnContents {
      margin-top: 20px;
      font-weight: 350;
      font-size: 18px;
      color: #333333;
    }
  }

  .introductionBox {
    margin-top: 50px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &.reverse {
      flex-direction: row;
    }

    .bgBox {
      width: 1000px;
      height: 500px;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .introductionBox_title {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 500px;

      .title {
        font-weight: 900;
        font-size: 30px;
        color: #333333;
      }

      .contents {
        font-weight: 400;
        font-size: 20px;
        color: #999999;
      }
    }

    .introductionBoxImage {
      margin-top: 30px;
      position: relative;
      // width: fit-content;
      perspective: 1000px;
      transition: transform 0.5s;
      z-index: 2;

      img {
        position: absolute;
        transition: transform 0.5s ease;
      }

      .intImg {
        height: 400px !important;
        width: 650px !important;
      }

      &.hovered img {
        transform: rotateX(0deg);
        transform: rotateY(0deg);
      }
    }
  }

  .aboutBox {
    display: flex;
    margin-top: 100px;
    justify-content: space-between;

    .aboutBox_box {
      width: 256px;
      height: 376px;
      padding: 30px;
      background: #FFFFFF;
      border-radius: 40px 40px 40px 40px;
      border: 1px solid rgba(153, 153, 153, 0.3);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .iconbox {
        width: 90px;
        height: 90px;
        background: #333333;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 54px;
          height: 54px;
        }
      }

      .aboutBox_title {
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        margin: 30px 0px 20px;
      }

      .aboutBox_contents {
        width: 250px;
        font-weight: 400;
        font-size: 18px;
        color: #999999;
      }
    }
  }

  .bottomBoxs {
    width: 100%;
    min-width: 1520px;
    background: #333333;
    position: relative;
  }

  .bottomBox {
    width: 1200px;
    height: 216px;
    display: flex;
    justify-content: space-between;

    .bottomBox_title {
      font-weight: 600;
      font-size: 26px;
      color: #FFFFFF;
      margin-bottom: 30px;
    }

    .bottomBox_contents {
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      margin-right: 50px;
    }
  }

  .recordBox {
    position: absolute;
    left: 45%;
    bottom: 50px;

    p {
      a {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }

  .hometitle {
    background: #333;
    width: 220px;
    height: 64px;
    margin: 50px 0px;
    cursor: pointer;
    text-align: left;
    border-radius: 40px;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s ease;
    overflow: hidden;
  }

  .homearrowbox {
    position: absolute;
    right: 20px;
    top: 31%;
    height: 20px;
    width: 22px;
    overflow: hidden;
  }

  .homearrow {
    position: absolute;
    left: -22px;
    transition: transform 0.5s;
  }

  .hometitle:hover {
    width: 240px;

    .homearrow {
      transform: translate(22px, 0px);
    }
  }

  @keyframes slideInPC {
    from {
      opacity: 0;
      transform: translateY(50px);
      /* 开始时更远的位置 */
    }

    to {
      opacity: 1;
      transform: translateX(0px);
      /* 结束时的位置，即没有平移 */
    }
  }

  .pchometags {
    width: 180px;
    height: 228px;
    border-radius: 50px;
    padding: 30px;

    .titleText {
      font-weight: 900;
      font-size: 22px;
      color: #FFFFFF;
      margin: 30px 0px 15px;
    }

    .tipsText {
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }
  }

  .pchometags1,
  .pchometags2,
  .pchometags3,
  .pchometags4 {
    width: 180px;
    height: 228px;
    border-radius: 50px;
    padding: 30px;
    justify-content: flex-start;
    opacity: 0;
    animation: slideInPC 1s ease-out forwards;
    position: relative;
    /* 应用我们定义的动画 */


    .titleText {
      font-weight: 900;
      font-size: 22px;
      color: #FFFFFF;
      margin: 30px 0px 15px;
    }

    .tipsText {
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }

    .shadowBox {
      width: 180px;
      height: 100px;
      position: absolute;
      bottom: -20px;
      border-radius: 30px;
      opacity: 0.9;
      filter: blur(100px);
    }
  }

  .pchometags1 {
    animation-delay: 0.1s;
  }

  .pchometags2 {
    animation-delay: 0.2s;
  }

  .pchometags3 {
    animation-delay: 0.3s;
  }

  .pchometags4 {
    animation-delay: 0.4s;
  }

  .phonehometitle {
    background: linear-gradient(to right, #4844F0 0%, #807DFF 100%);
    width: 220px;
    cursor: pointer;
    padding-left: 30px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 14px 15px 50px 0px rgba(0, 10, 255, 0.3);
    color: #fff;
    position: relative;
    transition: all 1s ease;
    overflow: hidden;
  }

  .dropdown-enter {
    opacity: 0;
    transform: translateY(-20px);
  }

  .dropdown-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms, transform 300ms;
  }

  .dropdown-exit {
    opacity: 1;
  }

  .dropdown-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .workitemlist {
    :hover {
      border: '1px solid #FB9300';
      background: 'RGB(255,247,234)';
    }
  }

  .workitemlist:hover * {
    color: rgb(233, 154, 44);
  }

  .markmapclass {
    width: 100%;
    border-radius: 8px;
    /* 圆角边框 */
    // border: 1px solid rgb(205, 205, 205);
    /* 边框样式，可根据需要修改 */
    height: 100%;
    /* 高度，可根据需要修改 */
    overflow: auto;
    /* 横竖滚动条 */
    margin-bottom: 10px;
    /* 距离底部10px */
    background-color: var(---, #f8faff);
  }

  .markmapclasstoolbar {
    position: absolute;
    bottom: -70px;
    right: 0;
    flex: 1;
    column-gap: 1vw;
    display: flex;
    flex-direction: row;
    // padding-right: 2vw;
    align-items: center;
    padding-bottom: var(--chakra-space-5);
  }

  @media (max-width: 768px) {
    .markmapclasstoolbar {
      bottom: 10px;
      right: 5px;
    }
  }

  .markmapclasstoolbar_preview {
    position: absolute;
    bottom: -0.75rem;
    right: -0.75rem;
    flex: 1;
    column-gap: 1vw;
    display: flex;
    flex-direction: row;
    padding-right: 2vw;
    padding-bottom: var(--chakra-space-5);
  }

  .payimage {
    width: 128px !important;
    height: 128px !important;
    padding: 10px;
    margin-bottom: 20px;
  }
}

.phoneHome {
  padding: 30px 0px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow: auto;
  align-items: center;
  position: relative;

  .titleNav {
    width: calc(100% - 40px);
    justify-content: space-between;
    align-items: center;
    margin: 0px 20px 20px;

    .iconbox {
      text-align: left;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      color: #333333;

      .logoboxs {
        width: 40px;
        height: 40px;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 4px 16px 40px 0px rgba(14, 0, 186, 0.1);
        border-radius: 12px;
        margin-right: 10px;
        justify-content: center;
        align-items: center;
      }

      p {
        font-weight: bold;
      }
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }



  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(0px);
    }

    to {
      opacity: 1;
      transform: translateX(-500px);
    }
  }

  .phonehometags1,
  .phonehometags2,
  .phonehometags3,
  .phonehometags4,
  .phonehometags5 {
    opacity: 0;
    animation: slideIn 1s ease-out forwards;
    margin-left: 500px;
    white-space: nowrap;
  }

  .phonehometags1 {
    animation-delay: 0.1s;
  }

  .phonehometags2 {
    animation-delay: 0.2s;
  }

  .phonehometags3 {
    animation-delay: 0.3s;
  }

  .phonehometags4 {
    animation-delay: 0.4s;
  }

  .phonehometags5 {
    animation-delay: 0.5s;
  }

  .logobox {
    height: 500px !important;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 0px 20px;

    .logoimg {
      width: 369px !important;
      height: 234px;
      z-index: 2;
    }

    p {
      font-weight: 900;
      font-size: 30px;
      color: #333333;
    }

    .bgBox {
      z-index: 1;
      width: 100%;
      height: 552px;
      position: absolute;
      top: 70px;
      background-image: url('../../assets/png/phoneBGBanner.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .hometitle {
      background: #333;
      width: 160px;
      height: 40px;
      margin: 50px 0px;
      cursor: pointer;
      text-align: left;
      border-radius: 40px;
      font-weight: bold;
      font-size: 14px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      z-index: 2;
    }
  }

  .introducebox {
    flex-direction: column;
    margin: 0px 30px 60px;

    .introduce {
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      margin-bottom: 30px;
    }

    .titleintroduce {
      font-weight: 800;
      font-size: 16px;
      color: #333333;
      margin-bottom: 20px;
    }

    .dowbloadboxs {
      display: flex;
      margin: 0px auto;

      .dowbloadbox {
        width: 130px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #F9F9F9;
        border-radius: 20px;
        margin: 0px 15px;

        img {
          width: 40px;
          height: 40px;
        }

        p {
          margin-top: 20px;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }

  .titlebox {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .stylebox {
      position: relative;
      height: 38px;
      width: 10px;

      .topbox {
        position: absolute;
        left: 1px;
        top: 0px;
        width: 4px;
        height: 18px;
        background: #565CFA;
        border-radius: 2px;
      }

      .bottombox {
        position: absolute;
        left: 2px;
        top: 0px;
        width: 2px;
        height: 38px;
        background: #565CFA;
        border-radius: 8px 8px 8px 8px;
        opacity: 0.5;
      }
    }

    .spantext {
      font-weight: 900;
      font-size: 22px;
      color: #333333;
    }

    span {
      font-weight: 900;
      font-size: 22px;
      background: linear-gradient(90deg, #565CFA 0%, #C656FB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .colortext {
      font-weight: 300;
      font-size: 12px;
      color: #999999;
    }
  }

  .chooseBoxs {
    flex-direction: column;
    margin: 0px 25px 60px;

    .backcolor {
      height: 50px;
      border-radius: 20px;
      margin-bottom: 20px;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
        margin-right: 15px;
      }

      .title {
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 5px;
      }

      .content {
        font-weight: 400;
        font-size: 10px;
        color: #FFFFFF;
      }
    }

    .waphomebox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      position: relative;

      .title {
        font-weight: 900;
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
      }

      .content {
        font-weight: 300;
        font-size: 12px;
        color: #999999;
        margin-bottom: 10px;
      }

      .bgbnox {
        position: absolute;
        top: 70px;
        left: 0px;
        width: 100%;
        background-size: cover;
        background-position: center;
      }

      .img {
        width: 300px;
        height: 210px;
        border-radius: 0px;
        z-index: 2;
      }
    }

    .cnContents {
      font-weight: 300;
      font-size: 12px;
      color: #333333;
      margin: 0px 15px 30px;
    }

    .aboutBox_box {
      background: #FFFFFF;
      border-radius: 20px 20px 20px 20px;
      border: 1px solid rgba(153, 153, 153, 0.3);
      padding: 16px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .iconbox {
        display: flex;
        width: 40px;
        height: 40px;
        background: #333333;
        border-radius: 24px;
        margin-right: 12px;
        position: relative;

        svg {
          position: absolute;
          left: 10px;
          top: 9px;
          width: 21px;
          height: 21px;
        }
      }

      .textbox {
        display: flex;
        flex-direction: column;

        .aboutBox_title {
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          margin-bottom: 8px;
        }

        .aboutBox_contents {
          font-weight: 300;
          font-size: 12px;
          color: #999999;
        }
      }
    }

  }

  .bottomBoxs {
    width: calc(100% - 60px);
    height: 256px;
    padding: 20px 30px;
    background: #333333;
    border-radius: 0px;
    flex-direction: column;

    .bottomBox_title {
      font-weight: bold;
      font-size: 14px;
      color: #FFFFFF;
      margin-bottom: 10px;
    }

    .bottomBox_contents {
      font-weight: 400;
      font-size: 12px;
      color: #fff;
      margin-right: 40px;
      margin-bottom: 10px;
    }

    .recordBox {
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
    }
  }

}

.cmodelbox {
  width: 900px;
  height: 618px;
  background: #FFFFFF;
  border-radius: 30px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .cmodelTitleBox {
    width: calc(100% - 60px);
    padding: 30px 30px 0px;
    justify-content: flex-end;

    svg {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }

  .macboxbut {
    height: 42%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .macboxbut_tip {
      font-weight: 900;
      font-size: 30px;
      color: #333333;
      margin-bottom: 60px;
    }

    .macboxbut_tips {
      font-weight: 900;
      font-size: 30px;
      color: #333333;
      margin-bottom: 20px;
    }

    .macboxbut_text {
      text-align: center;
      width: 270px;
      font-weight: 400;
      font-size: 16px;
      color: #999999;
      margin-bottom: 20px;
    }

    .macboxbutton {
      width: 300px;
      height: 76px;
      background: #333333;
      border-radius: 16px;
      font-size: 20px;
      color: #fff;
      margin: 0px 40px;
    }
  }

  .macBoxTips {
    width: calc(100% - 220px);
    height: calc(50% - 120px);
    background: #f5f5f5;
    padding: 60px 110px;
    display: flex;
    justify-content: space-between;

    .macBoxTips_box {
      width: 288px;
      flex-direction: column;

      .texta {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
        margin-bottom: 40px;
      }

      .boxa {
        width: 100%;
        display: flex;
        margin-bottom: 20px;

        div {
          width: 18px;
          height: 18px;
          background: #999999;
          margin-top: 2px;
          margin-right: 5px;
          border-radius: 50%;
          color: #fff;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          width: 250px;
          font-weight: 400;
          font-size: 16px;
          color: #999999;

          span {
            font-weight: 500;
            font-size: 16px;
            color: #666666;
          }
        }
      }
    }

    img {
      width: 310px;
      height: 198px;
    }
  }

  .andBoxTip {
    width: calc(100% - 220px);
    height: calc(50% - 120px);
    background: #f5f5f5;
    padding: 60px 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 128px;
      height: 128px;
      margin-bottom: 30px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #999999;
    }
  }
}

.logoboxs {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 16px 40px 0px rgba(14, 0, 186, 0.1);
  border-radius: 12px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 20px 5px 60px;
  color: #333;
  font-size: 18px;
  font-weight: 400;
  width: 140px;
  height: 22px;
}

.opendown {
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 20px 5px 60px;
  width: 140px;
  height: 22px;
  background: rgba(86, 92, 250, 0.06);
  border-radius: 4px;
  font-size: 18px;
  font-weight: 400;
  color: #565CFA;
}

.arrow {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  transition: transform 0.3s;
  transform: rotate(-90deg);

  &.open {
    transform: rotate(90deg);
  }
}

.dropdownMenu {
  position: absolute;
  background-color: white;
  color: #333;
  top: 50px;
  width: 100px;
  height: 20px;
  z-index: 1;
  border-radius: 4px;
  padding: 10px 10px;
}