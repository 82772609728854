.hisFlex {
  background: #f9f9f9;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  border-left: 1px solid #DDDDDD;

  .centerItemsbox {
    height: 31px !important;
    padding: 24px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    border-left: 0px;

    .title {
      height: 31px;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      margin-right: 10px;
    }

    .prompt {
      height: 31px;
      margin-top: 10px;
      margin-left: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #FF7B00;
    }
  }

  .hisBox {
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .nullBox {
      width: 100%;
      height: 400px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;

      svg {
        width: 120px;
        height: 120px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        color: rgba(153, 153, 153, 0.5);
      }
    }

    .historyValue {
      width: 530px;
      background: #FFFFFF;
      border-radius: 20px;
      margin: 0px auto 20px;
      position: relative;

      .historyValuebox {
        width: 490px;
        padding: 20px;
        flex-direction: column;

        .hisTitleBox {
          display: flex;
          justify-content: space-between;

          p {
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }

          .more {
            width: 24px;
            height: 24px;
            cursor: pointer;

            // path {
            //   fill: #000
            // }
          }
        }

        .hisContentsBox {
          margin: 12px 0px 8px;
          height: 40px;
          max-width: 450px;
          font-size: 14px;
          color: #999999;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .hisDateBox {
          align-items: center;

          div {
            justify-content: center;
            align-items: center;

            svg {
              margin-right: 3px;

              path {
                fill: #ccc
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 14px;
            color: #999999;
          }

          svg {
            cursor: pointer;
          }

          .viptext {
            font-weight: 400;
            font-size: 14px;
            color: #FF7B00;

            svg {
              width: 20px;
              height: 20px;
              margin-left: 3px;

              path {
                fill: #FFA500
              }
            }
          }

          .notviptext {
            font-weight: 400;
            font-size: 14px;
            color: #565CFA;
            margin-left: 3px;

            svg {
              width: 12px;
              height: 12px;
              margin-top: 1px;

              path {
                fill: #565CFA
              }
            }
          }
        }
      }
    }
  }
}

.prohibitBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(254, 254, 254, 0.4);
  z-index: 2;
}

.aimark {
  padding-top: 4px;
  border-radius: 20px;
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 12px);
  font-weight: 400;
  margin-left: 20px;
}