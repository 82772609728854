.allbox {
  flex-direction: column;
  width: 556px;
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #fff;

    .titletips {
      color: red;
      margin: 0px 3px;
    }
  }

  .titlemax {
    margin-left: 3px;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-left: 3px;
    cursor: pointer;
  }

  .titletipsbox {
    width: 300px;
    height: 50px;
    position: absolute;
    padding: 5px;
  }
}

.textareabox {
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  .Onetextarea {
    padding: 10px 15px;
    color: #fff;
    font-size: 16px;
    width: calc(100% - 34px);
    background: transparent;
    border-radius: 40px 40px 20px 20px;
    border: 0px solid #F5F5F5;
    resize: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      display: none;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }
  }

  .Onetextarea::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .maxText {
    position: absolute;
    bottom: 5px;
    right: 20px;
    font-size: 14px;
    color: rgba(153, 153, 153, 0.6);
  }

  .tipstext {
    position: absolute;
    bottom: 3px;
    left: 20px;
    color: #FF5852;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    p {
      height: 20px;
      font-weight: 500;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .tipsOnetext {
    position: absolute;
    bottom: 14px;
    right: 10px;
    color: #FF5852;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    p {
      height: 20px;
      font-weight: 500;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

.focused {
  border: 1px solid #565CFA;
}

.error {
  border: 1px solid #FF2922;
}

.notbalancebox {
  width: 344px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 24px 30px;
  border-radius: 30px;
  position: relative;
}

.notbalanceTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  p {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}

.texttips {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}


.modelbuttonbox {
  margin-top: 30px;
  justify-content: end;
  align-items: center;
}

.Savebutton {
  background: #565CFA;
  color: var(--text-reverse-color);
  width: 130px;
  margin-left: 10px;
}

.Savebutton:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
}

.Cancelbutton {
  background-color: transparent;
  width: 130px;
  border: 1px solid #999;
}

.cmodelbox {
  width: 330px;
  // height: 450px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 30px 40px;
  border-radius: 30px;
  position: relative;

  .cmodelTitleBox {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    p {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
      cursor: pointer;
    }
  }
}


.date_picker {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.selector {
  height: 150px;
  overflow-y: scroll;
  width: 70px;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }
}

.option {
  height: 20px;
  font-size: 18px;
  text-align: center;
  padding: 6px 0px;
  opacity: 0.5;
  cursor: pointer;
}

.selected {
  height: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 6px 0px;
  opacity: 1;
  cursor: pointer;
}