.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 23px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-color);

  .type {
    display: flex;
    align-items: center;

    .chatType {
      margin-right: 50px;
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--secondary-text);
      font-weight: 400;
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &:last-of-type {
        margin-right: 0;
      }

      .icon {
        width: 0;
        height: 0;
        transition: all 0.3s ease;
      }

      &.active {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 18px);

        .icon {
          width: 18px;
          height: 8px;
        }
      }
    }
  }
}