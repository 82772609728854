.swtich {
  width: 42px;
  height: 24px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:global(.base--checked) {
    .thumb {
      left: 18px;
    }

    .track {
      background: rgb(var(--primary-color));
    }
  }

  .track {
    border: none;
    box-sizing: border-box;
    background: rgb(243, 246, 249);
    border: 1px solid rgb(218, 226, 237);
    border-radius: 40px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px inset;
  }

  .thumb {
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 50%;
    display: block;
    top: 1px;
    left: 1px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  .input {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    z-index: 1;
    margin: 0px;
  }
}