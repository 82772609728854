.welcome {
  width: 852px;
  margin: 140px auto 0;


  .cases {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .case {
      display: flex;
      background-color: var(--background-color);
      border-radius: 16px;
      padding: 10px 16px;
      width: 172px;
      box-sizing: border-box;
      cursor: pointer;

      .icon {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }

      .text {
        color: var(--tips-text);
      }
    }

    .change {
      color: rgb(var(--primary-color));
      font-size: calc(var(--font-size-add) + 14px);
      font-weight: 400;
      width: 90px;
      height: 56px;
      background: #E4E6FF;
      border-radius: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      .icon {
        width: 18px;
        height: 18px;
        margin-bottom: 2px;
      }
    }
  }

  .pluginCases {
    display: flex;
    flex-wrap: wrap;
    width: 600px;
    margin-top: 30px;

    .item {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 10px 16px;
      box-sizing: border-box;
      border: 1px solid var(--secondary-text);
      border-radius: 14px;
      margin-right: 14px;
      margin-bottom: 14px;
      color: --text-color;
      cursor: pointer;

      svg {
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:hover {
        border-color: rgb(var(--primary-color));
        color: rgb(var(--primary-color));

        svg path {
          fill: rgb(var(--primary-color));
        }
      }
    }
  }

  .pluginBottomCase {
    position: absolute;
    bottom: 140px;
    left: 50%;
    transform: translateX(-50%);
    width: 852px;
    display: flex;

    .list {
      width: 740px;
      display: flex;

      .item {
        display: flex;
        align-items: flex-start;
        width: 170px;
        margin-right: 20px;
        border-radius: 16px;
        background-color: var(--background-color);
        padding: 10px 16px;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .change {
      color: rgb(var(--primary-color));
      font-size: calc(var(--font-size-add) + 14px);
      font-weight: 400;
      width: 90px;
      height: 56px;
      background: #E4E6FF;
      border-radius: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      margin-left: 20px;

      .icon {
        width: 18px;
        height: 18px;
        margin-bottom: 2px;
      }
    }
  }
}

.title {
  font-weight: bold;
  color: var(--title-color);
  font-size: calc(var(--font-size-add) + 30px);
  line-height: 40px;
}

.desc {
  color: var(--text-color);
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 24px;
  margin-top: 10px;
  width: 550px;
}


:root {
  --title-color: #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    // --title-color: #fff;
    --title-color: #000;
  }
}