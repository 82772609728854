.container {
  width: 430px;
  height: 580px;
  border: 1px solid #ccc;
  border-radius: 30px;
  background-color: #fff;
  padding: 10px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  overflow: hidden;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
}

.header span {
  font-weight: bold;
  font-size: 22px;
  color: #333333;
}

.header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.calendar {
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 0px 30px;
}

.weekdays {
  width: 350px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px 35px;
  border-bottom: 1px solid #eee;
}

.weekday {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  width: 40px;
  text-align: center;
}

.month_container {
  margin-bottom: 20px;
}

.month_container span {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  display: block;
  margin-bottom: 5px;
}

.days {
  width: 350px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* 每行7个元素 */
  // gap: 5px;

  div {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    height: 38px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.day,
.empty {
  height: 46px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;
}

// .day.selected {
//   color: #fff;
//   background: #000;
//   border-top-left-radius: 40%;
//   border-bottom-left-radius: 40%;

// }

// .day.in_range {
//   color: #fff;
//   background: #ccc;
//   // border-radius: 50%;
// }

.day {
  cursor: pointer;
}

.selected {
  background-color: #333;
  color: white !important;
}

.in_range {
  background-color: rgba(153, 153, 153, 0.15);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.start,
.end {
  background-color: #333;
  color: white !important;
}

.start {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.end {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.start::before {
  // content: '始';
  display: block;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.end::before {
  // content: '终';
  display: block;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.years {
  background: #F9F9F9;
  border: 1px solid rgba(153, 153, 153, 0.2);
  overflow: hidden;
  z-index: 3;
  position: absolute;
  top: 50px;
  left: 0px;
  width: 450px;
  height: 280px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* 每行7个元素 */
  gap: 5px;

  div {
    margin: 10px 0px 0px;
    height: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.year {
  width: 80px;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  justify-content: center;
  margin-bottom: 5px;
}

.year.current {
  font-weight: bold;
  color: #007bff;
}

.closebut {
  width: 20px !important;
  height: 20px !important;
}