.layout {
  display: flex;
  height: 100%;

  .content {
    flex: 1;
  }
}

.nav {
  width: 120px;
  padding: 35px 30px;
  box-sizing: border-box;
  border-right: 1px solid #DDDDDD;
  display: flex;
  flex-direction: column;
  background: var(--background-color);

  .navs {
    text-align: center;

    .navItem {
      cursor: pointer;
      margin-bottom: 40px;
      position: relative;

      &.active {
        .svgContent {
          width: 50px;
          height: 50px;
        }
      }

      .svgContent {
        margin: 0 auto;
        width: 34px;
        height: 34px;
        transition: width 0.2s linear;

        .svg {
          width: 100%;
          height: 100%;
        }
      }

      .unread {
        height: 20px;
        min-width: 20px;
        box-sizing: border-box;
        position: absolute;
        text-align: center;
        border-radius: 20px;
        box-shadow: 0px 4px 10px 0px rgba(186, 0, 0, 0.24);
        background: #FF2922;
        color: #FFFFFF;
        font-size: calc(var(--font-size-add) + 12px);
        padding: 3px 6px 5px;
        top: 0;
        right: 0;
      }

      .text {
        color: var(--text-color);
        margin-top: 4px;
        height: 20px;
      }
    }
  }

  .bottomNavs {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .newVersion {
      margin-bottom: 30px;
      text-align: center;
      cursor: pointer;

      .icon {
        width: 34px;
        height: 34px;
      }

      .text {
        color: #FF6320;
        font-weight: bold;
        font-size: calc(var(--font-size-add) + 14px);
      }
    }

    .feedback {
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--secondary-border-color);
      cursor: pointer;

      .svg {
        width: 28px;
        height: 28px;
      }

      .text {
        color: var(--secondary-text);
        font-size: calc(var(--font-size-add) + 12px);
        padding-left: 4px;
      }
    }

    .vipbox {
      flex-direction: column;
      position: relative;
      cursor: pointer;

      &:hover {
        .vipBoxModal {
          display: flex;
          position: absolute;
          left: -10px;
          top: -50px;
        }
      }

      .vipuserbox {
        width: 54px;
        height: 18px;
        background: linear-gradient(133deg, #FCD670 0%, #FDF8D4 50%, #FCD670 100%);
        border-radius: 40px;
        border: 0px solid;
        border-image: linear-gradient(171deg, rgba(255, 164, 58, 1), rgba(251, 237, 170, 1), rgba(255, 164, 58, 1)) 0 0;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        svg {
          width: 12px;
          height: 12px;
          margin: 0;
        }

        p {
          height: 12px;
          font-weight: bold;
          font-size: 8px;
          color: #D54D00;
        }
      }

      .notvipuserbox {
        width: 54px;
        height: 18px;
        background: linear-gradient(133deg, #D2D2D2 0%, #F3F3F3 47%, #D2D2D2 100%);
        border-radius: 40px;
        border: 0px solid;
        border-image: linear-gradient(131deg, rgba(164, 164, 164, 1), rgba(206, 206, 206, 1), rgba(176, 176, 176, 1)) 1 1;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        svg {
          width: 12px;
          height: 12px;
          margin: 0;
        }

        p {
          height: 12px;
          font-weight: bold;
          font-size: 8px;
          color: #666666;
        }
      }

      svg {
        width: 50px;
        height: 50px;
        margin: 0px auto;
        justify-content: center;
      }

      p {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
      }

      .noviptext {
        color: #333333;
      }

      .viptext {
        color: #D54D00;
      }
    }

    .vipmodel {
      background-image: url('../../assets/png/vipbg.png');
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: space-between;
      width: 268px;
      height: 80px;
      padding: 46px 26px 22px;
      border-radius: 20px;
      margin-left: 80px;

      .contentbox {
        justify-content: flex-start;
        flex-direction: column;

        p {
          text-align: left;
        }

        .title {
          font-weight: 900;
          font-size: 20px;
          color: #A9530C;
        }

        .date {
          font-weight: 400;
          font-size: 14px;
          color: #DA8742;
          margin: 6px 0px 10px;
        }

        .points {
          font-size: 16px;
          color: #A9530C;

          span {
            font-weight: bold;
            font-size: 16px;
            color: #A9530C;
          }
        }

        svg {
          margin: 5px;
          width: 13px;
          height: 13px;
        }
      }

      .goOpenbox {
        margin-top: 55px;
        margin-right: 6px;
        width: 87px;
        height: 31px;
        color: #FFF59C;
        background: linear-gradient(96deg, #DD9F26 0%, #B67B09 100%);
        border-radius: 35px 35px 35px 35px;
        justify-content: center;
        align-items: center;
      }
    }

    .vipBoxModal {
      position: absolute;
      left: 0;
      bottom: 0px;
      display: none;
      z-index: 9;
    }

    .notvipmodel {
      background-image: url('../../assets/png/novipbg.png');
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: space-between;
      left: 0;
      top: -50px;
      z-index: 999;
      width: 268px;
      height: 80px;
      padding: 46px 26px 22px;
      border-radius: 20px;
      margin-left: 80px;

      .contentbox {
        justify-content: flex-start;
        flex-direction: column;

        p {
          text-align: left;
        }

        .title {
          font-weight: 900;
          font-size: 20px;
          line-height: 23px;
        }

        .date {
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          margin: 6px 0px 10px;
        }

        .points {
          font-size: 16px;
          color: #666666;

          span {
            font-weight: bold;
            font-size: 16px;
            color: #666666;
          }
        }

        svg {
          margin: 4px 5px;
          width: 13px;
          height: 13px;
        }
      }

      .goOpenbox {
        margin-top: 55px;
        margin-right: 6px;
        width: 87px;
        height: 31px;
        color: #fff;
        background: linear-gradient(105deg, #999999 0%, #666666 100%);
        border-radius: 35px 35px 35px 35px;
        justify-content: center;
        align-items: center;
      }
    }

    .userInfo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      margin: 30px auto 0;
      position: relative;

      .userAvatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .whiteUser {
        margin-top: 65%;
        width: 100%;
        height: 35%;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.9);
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: bold;
        color: #000;
      }
    }

    .login {
      text-align: center;
      margin-top: 20px;
      cursor: pointer;

      .svg {
        width: 34px;
        height: 34px;
      }
    }
  }
}

.logo {
  width: 60px;
  height: 60px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 60px;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
  }
}

@media (prefers-color-scheme: light) {
  .logo {
    background: #1C1E67;
  }
}

@media (prefers-color-scheme: dark) {
  .logo {
    background: #1C1E67;
    // background: #ccc;
  }
}

.vipuserbox {
  width: 54px;
  height: 18px;
  background: linear-gradient(133deg, #FCD670 0%, #FDF8D4 50%, #FCD670 100%);
  border-radius: 40px 40px 40px 40px;
  border: 0px solid;
  border-image: linear-gradient(171deg, rgba(255, 164, 58, 1), rgba(251, 237, 170, 1), rgba(255, 164, 58, 1)) 0 0;
}