.allbox {
  flex-direction: column;
  width: 556px;
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;

    .titletips {
      color: red;
      margin: 0px 3px;
    }
  }
}

.scrollStyle {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: pointer;

  &::-webkit-scrollbar {
    // width: 4px;
    height: 4px;
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }

  .imgsbox {
    flex: 1;
    width: 70px;
    max-width: 70px;
    height: 96px;
    margin: 0px 50px 10px 5px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    position: relative;

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-bottom: 5px;
    }

    .selectIcon {
      width: 24px;
      height: 24px;
      background: #565CFA;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 20px;
      right: 0px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}