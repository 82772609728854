.nav {
  width: 340px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  overflow: hidden;

  &.hide {
    width: 0px;
    border-right: none;
  }

  .title {
    width: 290px;
    margin: 0px 25px;
    font-size: calc(var(--font-size-add) + 26px);
    font-weight: bold;
    color: var(--text-color);
    line-height: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .add {
    background: var(--secondary-bg);
    width: 290px;
    margin: 0px 25px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: rgb(var(--primary-color));
    cursor: pointer;
    font-size: calc(var(--font-size-add) + 18px);
    margin-bottom: 50px;

    &:hover {
      background: rgba(var(--primary-color), 0.1);
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-right: 8px;

      path {
        fill: rgb(var(--primary-color));
      }
    }
  }

  .list {
    flex: 1;
    width: 100%;
    padding: 0px 5px 0 25px;
    overflow: hidden;
    box-sizing: border-box;

    .listBox {
      padding-right: 20px;
    }

    .item {
      width: 100%;
      margin-bottom: 20px;
      height: 86px;
      border-radius: 20px;
      background-color: var(--secondary-bg);
      display: flex;
      align-items: center;
      padding: 16px;
      box-sizing: border-box;
      cursor: pointer;

      .avatar {
        width: 54px;
        height: 54px;
        border-radius: 16px;
        background-color: #e9e9e9;
        overflow: hidden;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.defaultAvatar {
          img {
            width: 34px;
            height: 34px;
          }
        }
      }

      .info {
        flex: 1;
        overflow: hidden;

        .name {
          color: var(--text-color);
          font-size: calc(var(--font-size-add) + 16px);
          font-weight: bold;
          line-height: 20px;
        }

        .intro {
          width: 100%;
          color: rgb(var(--secondary-text-rbg));
          font-size: calc(var(--font-size-add) + 14px);
          line-height: 18px;
          opacity: 0.7;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-top: 4px;
          height: 18px;
        }
      }

      &:hover,
      &.active {
        background-color: rgba(var(--primary-color), 0.1);

        .info {
          .name {
            color: rgb(var(--primary-color));
          }

          .intro {
            color: rgb(var(--primary-color));
          }
        }
      }
    }
  }
}