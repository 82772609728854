.message {
  font-size: calc(var(--font-size-add) + 16px);
  border-radius: 16px;
  margin-top: 18px 26px;
  // background-color: var(--background-color);
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 20px;
  // box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: auto;
  position: relative;

  .statusText {
    color: #09AD19;
  }

  // &.tips {
  //   color: #565CFA;
  // }

  // &.warning {
  //   color: #FF7B00;
  // }

  .title {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }


  .btn {
    width: 75px;
    height: 32px;
    background: #09AD19;
    border-radius: 29px 29px 29px 29px;
    margin-left: 60px;
    color: #fff;
  }


  &.success {
    .statusText {
      color: #09AD19;
    }
  }

  &.error {
    .statusText {
      color: #FF5852;
    }

    .btn {
      background: #FF5852;
    }
  }

  .close {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }
  }
}