.toollistBox {
  height: 100%;
  justify-content: center;
  align-items: center;

  .listBox {
    width: 50%;
    height: calc(100% - 110px);
    padding-bottom: 110px;
    flex-direction: column;
    position: relative;
    background-color: #fff;

    .tabs {
      width: 556px;
      margin: 0px auto 10px;

      .tabList {

        .tab {
          border: 0px;
          background: transparent;
          flex-direction: column;
          margin-bottom: 10px;
          cursor: pointer;
          position: relative;

          .modeliconboxs {
            display: flex;
            width: 68px;
            height: 68px;
            background: transparent;
            border: 1px solid #565CFA;
            border-radius: 14px;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            svg {
              width: 46px;
              height: 46px;
            }
          }

          .selectSpeechModel {
            position: absolute;
            right: 12px;
            bottom: 35px;
            width: 18px;
            height: 18px;
          }

          .modeliconbox {
            display: flex;
            width: 70px;
            height: 70px;
            background: #F5F5F5;
            border-radius: 14px;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            svg {
              width: 46px;
              height: 46px;
            }
          }

          p {
            font-weight: 500;
            font-size: 14px;
            color: #333333;
          }
        }
      }

      .tabPanels {
        .tabPanel {
          .titlebox {
            display: flex;
            justify-content: start;
            align-items: center;
            margin: 15px 0px;
            position: relative;
          }
        }
      }
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }

    .allbox {
      flex-direction: column;
      width: 556px;
      margin: 0px auto 10px;

      .titlebox {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 15px;
        position: relative;
      }

      .listboxs {
        z-index: 0;

        .selectBox {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 6px;

          .selectbox {
            width: 10px;
            height: 10px;
            background: #FFFFFF;
            border-radius: 50%;
          }
        }

        .selbox {
          width: 91px;
          height: 42px;
          color: #565CFA;
          background: rgba(86, 92, 250, 0.1);
          border-radius: 14px;
          font-weight: 400;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
          cursor: pointer;

          svg {
            width: 18px;
            height: 18px;
            margin-right: 3px;
          }
        }

        .sexbox {
          width: 91px;
          height: 42px;
          border-radius: 14px;
          font-weight: 400;
          font-size: 16px;
          color: #999;
          background: #F5F5F5;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
          cursor: pointer;

          svg {
            width: 18px;
            height: 18px;
            margin-right: 3px;
          }
        }
      }
    }
  }

  .previewBox {
    width: 50%;
    height: 100%;
  }

  .footbutton {
    background-color: #fff;
    width: 100%;
    position: absolute;
    border-top: 1px solid #DDDDDD;
    bottom: 0;
    right: 0px;
    justify-content: center;
    padding: 28px 87px;
    box-sizing: border-box;

  }
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 140px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;

  .moreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .deletemoreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;

      path {
        fill: #FF2922
      }
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #FF2922;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}