.preview {
  display: flex;

  .info {
    width: 450px;
    margin-left: 20px;

    .topCard {
      border-radius: 30px;
      height: 270px;
      background-color: var(--background-color);
      padding: 30px 30px 20px;
      margin-bottom: 20px;
      box-sizing: border-box;

      .user {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .userInfo {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: calc(var(--font-size-add) + 16px);
          color: var(--text-color);

          .avatar {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }

        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .type {
        font-size: calc(var(--font-size-add) + 20px);
        color: var(--text-color);
        margin-top: 20px;
        margin-bottom: 30px;
        font-weight: bold;
      }

      .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .btn {
          width: 188px;
          height: 54px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &.all {
            width: 100%;
          }

          &.expBtn {
            background: #F18333;
          }

          .icon {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
        }
      }


      .ops {
        display: flex;
        align-items: center;

        .opItem {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: calc(var(--font-size-add) + 14px);
          font-weight: 400;
          color: var(--text-color);
          padding: 8px 0;
          margin-right: 39px;

          &:hover {
            color: rgb(var(--primary-color));

            .icon path {
              fill: rgb(var(--primary-color));
            }
          }

          .loading,
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }
        }
      }
    }

    .bottomCard {
      height: 510px;
      background-color: var(--background-color);
      padding: 30px 5px 0px 20px;
      border-radius: 30px;
      box-sizing: border-box;
      overflow: hidden;

      .bottomScroll {
        padding-right: 15px;
      }
    }
  }
}

.infoItem {
  margin-bottom: 30px;

  .titleBox {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 18px);
      color: var(--text-color);
    }

    svg {
      width: 22px;
      height: 22px;
      cursor: pointer;

      path {
        fill: #fff;
      }
    }
  }

  .content {
    background-color: var(--secondary-bg);
    border-radius: 20px;
    padding: 16px;
    min-height: 60px;
    box-sizing: border-box;
    color: var(--secondary-text);
    font-weight: 400;
    font-size: calc(var(--font-size-add) + 14px);
    overflow: hidden;
    line-height: 20px;
    display: flex;
    align-items: center;

    &.middle {
      .img {
        width: 40px;
        height: 40px;
        border-radius: 14px;
        margin-right: 14px;
      }
    }

    &.large {
      padding: 16px 20px;

      .img {
        margin-right: 16px;
        width: 60px;
        height: 60px;
        border-radius: 18px;
      }
    }

    .text {
      max-height: 80px;
    }
  }
}