.drawType,
.calendar,
.price {
  display: flex;
  align-items: center;
  color: #BBBBBB;
  font-size: calc(var(--font-size-add) + 14px);
  line-height: 16px;
  margin-right: 14px;
  white-space: nowrap;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .freeNum {
    text-decoration: line-through;
  }

  .free {
    color: #FF7B00;
  }

  .notfree {
    color: #565CFA;
    margin-left: 3px;
  }

  .imgnotfree {
    width: 12px;
    height: 12px;
    margin-top: 1px;

    path {
      fill: #565CFA
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .text {
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: bold;
    line-height: 20px;
    color: var(--text-color);
  }
}

.op {
  display: flex;

  .opItem {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: calc(var(--font-size-add) + 14px);
    line-height: 16px;
    cursor: pointer;

    &:hover {

      .icon path {
        fill: rgb(var(--primary-color));
      }

      &.del {
        color: #FF2922;

        .icon path {
          fill: #FF2922;
        }
      }

      .text {
        color: rgb(var(--primary-color));

      }
    }

    .icon,
    .loading {
      width: 16px;
      height: 16px;
    }

    .text {
      font-size: calc(var(--font-size-add) + 14px);
      color: var(--text-color);
      font-weight: 400;
      margin-left: 6px;

    }

    &.redrawIng .icon {
      animation: redrawing 0.5s ease-in;
    }

    &.del .text {
      color: #FF2922;
    }
  }
}

@keyframes redrawing {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}