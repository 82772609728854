.subnav {
  height: 100%;
  overflow: hidden;
  width: 340px;
  border-right: 1px solid var(--border-color);
  padding-top: 25px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;

  &.hide {
    width: 0;
    border-right: none;
    padding: 25px 0px;
  }


  .squareBtn {
    width: 260px;
    display: flex;
    align-items: center;
    height: 70px;
    border-radius: 20px;
    background: rgba(255, 123, 0, 0.1);
    padding: 16px 28px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 18px);
    color: #FF7B00;
    margin-bottom: 40px;
    margin-left: 40px;

    &:hover {
      opacity: 0.8;
    }

    .icon {
      width: 36px;
      height: 36px;
      margin-right: 16px;
    }
  }

  .scrollBox {
    flex: 1;
    height: 0;
    padding-left: 40px;
  }

  .drawType {
    width: 260px;
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 20px 30px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 25px;

    &.active {
      background: #EEEFFE !important;

      .name {
        color: rgb(var(--primary-color)) !important;
        font-weight: bold;
      }

      .desc {
        color: rgba(var(--primary-color), 0.5) !important;
      }
    }

    &.disable {

      .name,
      .desc {
        color: #CCCCCC !important;
      }
    }

    &:hover {
      background-color: var(--secondary-bg);
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .text {
      font-weight: 400;

      .name {
        display: flex;
        align-items: center;
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 18px);

        .maintain {
          background: #E2E2E2;
          width: 56px;
          height: 21px;
          border-radius: 26px;
          color: var(--secondary-text);
          text-align: center;
          line-height: 21px;
          font-size: calc(var(--font-size-add) + 12px);
          margin-left: 8px;
        }
      }

      .desc {
        font-size: calc(var(--font-size-add) + 12px);
        color: var(--secondary-text);
      }
    }
  }
}

.titleText {
  width: 240px;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}

.toolBox {
  width: 260px;
  height: 70px;
  justify-content: space-between;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;


  &.disable {
    cursor: not-allowed;

    .contName,
    .contSynopsis {
      color: #CCCCCC !important;
    }
  }

  .contName {
    width: 140px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    color: #333333;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .maintain {
      background: #E2E2E2;
      width: 56px;
      height: 21px;
      border-radius: 26px;
      color: var(--secondary-text);
      text-align: center;
      line-height: 21px;
      font-size: calc(var(--font-size-add) + 12px);
      margin-left: 8px;
    }
  }

  .contSynopsis {
    width: 180px;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.selecttoolBox {
  width: 260px;
  height: 70px;
  justify-content: space-between;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #EEEFFE;
  box-sizing: border-box;

  .contName {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    color: #565CFA;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .maintain {
      background: #E2E2E2;
      width: 56px;
      height: 21px;
      border-radius: 26px;
      color: var(--secondary-text);
      text-align: center;
      line-height: 21px;
      font-size: calc(var(--font-size-add) + 12px);
      margin-left: 8px;
    }
  }

  .contSynopsis {
    width: 180px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(86, 92, 250, 0.4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.toolBox:hover {
  background-color: #f5f5f5;
}

.avatar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 16px;
  margin-right: 8px;
}

.contentbox {
  width: calc(100%);
  height: 40px;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}

.noData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding-right: 40px;
  color: #999999;
  box-sizing: border-box;
}