.DragBox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgb(var(--primary-color));
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);

  .icon {
    width: 36px;
    height: 36px;
    margin-bottom: 20px;
  }
}