.container {
  background-color: rgba(var(--secondary-bg-rgb), 0.86);
  width: 100%;
}

.header {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  align-items: center;

  .headerItem {
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: 400;
    color: var(--secondary-text);
    line-height: 19px;
    margin-right: 70px;

    &.active {
      color: var(--text-color);
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 18px);
    }
  }

  .headerBack {
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}


.chatCollects {
  width: 600px;
  margin: 0 auto;
  margin-top: 30px;

  .chatItem {
    background-color: #ffffff;
    border-radius: 14px;
    padding: 16px;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;

    &.menu {
      background: #EEEEEE;
    }

    .first,
    .second {
      display: flex;
      margin-bottom: 16px;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--text-color);
        line-height: 24px;
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--secondary-text);

      .left {
        display: flex;
        align-items: center;

        svg {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }
  }
}

.detailContent {
  width: 960px;
  margin: 30px auto 0;
}

.contentItem {
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;

  &.collect {
    opacity: 0.3;
  }

  &.collected {
    opacity: 1;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 20px;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    max-width: 80%;

    .app {
      color: var(--text-color);
      font-size: calc(var(--font-size-add) + 16px);
      font-weight: bold;
    }

    .docFiles {
      display: flex;
      margin-bottom: 20px;

      .docFile {
        width: 235px;
        height: 106px;
        border-radius: 14px;
        background: #fff;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 30px;
        cursor: pointer;

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
          line-height: 1;
        }

        .fileType {
          display: flex;
          align-items: center;
          color: var(--secondary-text);
          font-size: calc(var(--font-size-add) + 16px);

          svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }
      }
    }

    .appDesc {
      display: flex;

      .model {
        color: var(--secondary-text);
        margin-right: 15px;
      }
    }

    .time {
      font-size: calc(var(--font-size-add) + 14px);
      font-weight: 500;
      color: var(--secondary-text);
      line-height: 1;
      margin-top: 6px;
      margin-bottom: 15px;
    }

    .content {
      padding: 16px 18px;
      position: relative;

      .reasonTime {
        background: var(--secondary-border-color);
        padding: 8px 18px;
        border-radius: 49px;
        margin-bottom: 10px;
        display: inline-flex;
        align-items: center;
        font-size: calc(var(--font-size-add) + 14px);
        color: var(--text-color);

        .light {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        .arrow {
          width: 12px;
          height: 12px;
          margin-left: 4px;
          cursor: pointer;
          transition: all 0.3s linear;

          &.down {
            transform: rotate(180deg);
          }
        }
      }

      .reason {
        border-left: 1px solid #CCCCCC;
        padding-left: 8px;
        white-space: pre-line;
        margin-bottom: 10px;
        color: var(--secondary-text);
        font-size: calc(var(--font-size-add) + 14px);
        line-height: 24px;
        height: auto;
        overflow: hidden;
        transition: all 0.3s linear;

        &.hiddReason {
          height: 0;
        }
      }

      &.risk {
        .text {
          text-indent: 24px;
          display: flex;
        }

        .operates {
          display: none;
        }
      }

      &.risk .text:not(.ai) {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none !important;
      }

      .text {
        font-size: calc(var(--font-size-add) + 16px);
        user-select: text;
        position: relative;

        .riskIcon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
          display: inline-block;
          vertical-align: middle;
          z-index: 9;
          position: absolute;
          top: 4px;
        }
      }
    }

    .quote {
      background-color: var(--secondary-border-color);
      padding: 10px 16px;
      border-radius: 14px;
      margin-top: 20px;
      font-size: calc(var(--font-size-add) + 14px);
      color: var(--secondary-text);
      font-weight: bold;

      p {
        max-width: 100%;
        overflow: hidden;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: pre-wrap;
        display: -webkit-box;
      }
    }
  }

  &.ai {
    justify-content: flex-start;

    .info .content {
      background-color: var(--background-color);
      color: var(--text-color);
      border-radius: 4px 30px 30px 30px;

      &.risk {
        background-color: #FF5852;
        color: #fff;

        .price {
          svg path {
            fill: #fff;
          }
        }

        .price,
        .viptext,
        .vipprice {
          color: #fff;
        }
      }
    }



    .avatar {
      margin-right: 10px;
      width: 40px;
      height: 40px;

      img {
        width: 28px;
        height: 28px;
      }
    }

    .icon {
      background: rgba(153, 153, 153, 0.1);
    }
  }

  &.user {
    justify-content: flex-end;

    .avatar {
      margin-left: 10px;
    }

    .info {

      .app,
      .desc {
        text-align: right;
      }

      .appDesc {
        justify-content: flex-end;
      }

      .userImg {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .content {
        background-color: rgb(var(--primary-color));
        border-radius: 30px 4px 30px 30px;
        color: #fff;

        &.risk {
          background-color: #FF5852;
        }
      }
    }

    .icon {
      background: rgba(255, 255, 255, 0.1);

      svg path,
      svg rect {
        fill: #fff;
      }

      &.copy {
        svg path {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }
}

.rightClickMenu {
  width: 225px;
  height: 50px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  border-radius: 14px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--text-color);
  line-height: 16px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  &:hover {
    color: rgb(var(--primary-color));

    svg path {
      fill: rgb(var(--primary-color));
    }
  }
}