.allbox {
  flex-direction: column;
  width: 556px;
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
  }
}

.menuList {
  width: 536px;
  padding: 5px 10px;
  background: #FFFFFF;
  border: 1px solid #ccc;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  border-radius: 18px
}

.selectmenu {
  width: 556px;
  height: 52px;
  padding: 18px 20px;
  background: #F5F5F5;
  border-radius: 18px;
  border: 0px;
  cursor: pointer;

  .top {
    transform: rotate(270deg);
  }

  .bottom {
    transform: rotate(90deg);
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
}

.scrollbar {
  max-height: 320px;
}

.menuitem {
  width: 516px;
  height: 50px;
  border-radius: 0px;
  border: 0px;
  background-color: #fff;

  .uploadIcon {
    width: 14px;
    height: 14px;
    margin-left: 20px;
  }
}

.menuitem:hover {
  background: #F5F5F5;
  cursor: pointer;
}