.contentlistbox {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #000 !important;
  }
}

.slider_container {
  position: relative;
  width: 560px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slider_labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-size: calc(var(--font-size-add) + 16px);
}

input[type=range] {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #f1f1f1;
  outline: none;
  border-radius: 5px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 24px;
  border-radius: 6px;
  background: #3c6df0;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb {
  width: 10px;
  height: 24px;
  border-radius: 6px;
  background: #3c6df0;
  cursor: pointer;
}

.slider_marks {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.mark {
  font-size: calc(var(--font-size-add) + 14px);
}

.slider_value {
  color: #3c6df0;
}

.slider {
  color: #ccc;
}