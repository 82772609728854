.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-height: 900px) {
  .parent {
    margin-top: 50px;
  }
}

.child {
  padding: 20px;

  .tips {
    width: 600px;
    height: 66px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 400;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--secondary-text);
    line-height: 22px;
    text-align: justified;
    font-style: normal;
    text-transform: none;
    margin-bottom: 30px;
  }

  .suggestions {
    flex-direction: column;
    margin-bottom: 30px;

    .sugtextarea {
      padding: 16px 21px;
      width: 558px;
      height: 112px;
      background-color: var(--background-color);
      resize: none;
      border: 0px;
      border-radius: 20px
    }
  }

  .imgsbox {
    flex-direction: column;

    .imagesbox {
      flex-direction: row;
      align-items: center;
      justify-content: start;
      margin: 10px 0px 30px;
    }

    .previewImage {
      width: 120px;
      height: 120px;
      margin: 0px 5px;
      border-radius: 10px;
      object-fit: cover;
    }

    .uploadBox {
      width: 120px;
      height: 120px;
      border: 0px solid #ccc;
      border-radius: 10px;
      margin: 0px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--background-color);
      position: relative;
      cursor: pointer;
    }

    .uploadBoxicon {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .fileUploadInput {
      display: none;
    }

    .plusIcon {
      font-size: calc(var(--font-size-add) + 24px);
      color: #ccc;
    }

    .previewImage {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .phonebox {
    flex-direction: column;
    margin-bottom: 80px;

    .phonetextarea {
      padding: 16px 21px;
      width: 558px;
      height: 28px;
      background-color: var(--background-color);
      resize: none;
      border: 0px;
      border-radius: 20px
    }
  }

  .cbutton {
    background: #565CFA;
    color: var(--text-reverse-color);
    width: 600px;
  }
}

.text {
  height: 20px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 20px;
}