.creationImgItem {
  position: relative;
  overflow: hidden;
  border-radius: 20px;

  .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80%);
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    .favorite:hover {
      svg path {
        fill: rgb(var(--primary-color));
      }
    }

    .more:hover {
      circle {
        fill: rgb(var(--primary-color));
      }
    }
  }

  .aimark {
    position: absolute;
    right: 8px;
    bottom: 4px;
    padding: 4px 8px;
    border-radius: 20px;
    color: #ffffff;
    font-size: calc(var(--font-size-add) + 12px);
    font-weight: 400;
    background: rgba(0, 0, 0, 0.5);
  }

  .view {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.Midjourney {
    width: 120px;
    height: 120px;


    .mask {
      padding: 10px;

      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &.StableDiffusion {
    width: 160px;
    height: 160px;

    .mask {
      padding: 12px;

      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  &.GEN3 {
    width: 280px;
    height: 186px;
    border-radius: 30px;

    .mask {
      padding: 12px;

      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  &.StableDiffusion3,
  &.Flux {
    width: 160px;
    height: 160px;
    border-radius: 30px;

    .mask {
      padding: 12px;

      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}

.imgContent {
  display: flex;
  justify-content: space-between;
}

.imgBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.fail {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
  line-height: 18px;

  svg {
    width: 100px;
    height: 100px;
  }

  &.Midjourney {
    height: 120px;
  }

  &.StableDiffusion,
  &.StableDiffusion3,
  &.Flux {
    height: 160px;

    svg {
      width: 56px;
      height: 56px;
    }
  }

  &.GEN3 {
    height: 186px;
  }

  .btns {
    display: flex;
    justify-content: center;
    margin-top: 14px;

    .btn {
      width: 60px;
      height: 30px;
      font-size: calc(var(--font-size-add) + 12px);
      border-radius: 8px;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

.risk {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    width: 40px;
    height: 40px;
  }

  .riskText {
    color: #FF5852;
    margin-top: 10px;
  }

  &.Midjourney {
    height: 120px;
  }

  &.StableDiffusion,
  &.StableDiffusion3,
  &.Flux {
    height: 160px;
  }
}

.process {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: rgb(var(--primary-color));
  line-height: 16px;

  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    animation: loading 1s ease infinite;
  }

  &.Midjourney {
    height: 120px;
  }

  &.StableDiffusion {
    height: 160px;
  }

  &.GEN2,
  &.GEN3 {
    height: 186px;
  }

  &.StableDiffusion,
  &.StableDiffusion3,
  &.Flux {
    height: 160px;
  }
}

.queue {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .queueText {
    font-size: 14px;
    color: var(--text-color);
    margin-top: 10px;
    text-align: center;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.progress {
  display: flex;
  align-items: center;

  &.GEN2,
  &.GEN3 {
    height: 186px;
  }

  &.StableDiffusion,
  &.StableDiffusion3,
  &.Flux {
    height: 160px;
  }

  .img {
    width: 120px;
    height: 120px;
    background: #EEEEEE;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    .icon {
      width: 40px;
      height: 40px;
    }
  }

  .line {
    display: flex;
    flex: 1;
    align-items: center;

    .progressLineBg {
      background: #EEEEEE;
      height: 7px;
      flex: 1;
      border-radius: 20px;
      position: relative;
      margin-right: 20px;
      max-width: 400px;

      .progressLine {
        position: absolute;
        height: 100%;
        background: rgb(var(--primary-color));
        border-radius: 20px;
      }
    }
  }
}