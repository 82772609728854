.allbox {
  flex-direction: column;
  width: 556px;
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;

    .titletips {
      color: red;
      margin: 0px 3px;
    }
  }
}

.button {
  height: 38px;
  width: auto !important;
  padding: 10px 18px;
  border-radius: 14px;
  margin-right: 15px;
  background: #fff;
  margin-bottom: 15px;
  border: 1px solid rgba(153, 153, 153, 0.4);
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}

.selectButton {
  height: 38px;
  width: auto !important;
  padding: 10px 19px;
  border-radius: 14px;
  margin-right: 15px;
  background: rgba(86, 92, 250, 0.1);
  margin-bottom: 15px;
  border: 0px;
  font-weight: 400;
  font-size: 16px;
  color: #565CFA;
}