.container {
  height: calc(100% - 200px);
}

.content {
  padding: 30px 87px 30px;
  box-sizing: border-box;
  width: 730px;
  margin: 0 auto;

  .titleBox {
    flex-direction: column;
    justify-content: start;

    .title {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      margin-bottom: 20px;

      span {
        color: #FF2922
      }
    }
  }

  .progressBox {
    flex-direction: column;
    justify-content: start;
    width: 590px;
    max-width: 100%;
    padding: 0px 5px 0px 0px;
    position: relative;
    margin-top: 30px;

    .title {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      margin-bottom: 40px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #999
      }
    }
  }

  .leftBoxs {
    width: 84px;
    height: 34px;
    background: #565CFA;
    color: #fff;
    cursor: pointer;
    border-radius: 23px 0px 0px 23px;
    border: 1px solid #565CFA;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .leftBox {
    width: 84px;
    height: 34px;
    cursor: pointer;
    border-radius: 23px 0px 0px 23px;
    border: 1px solid #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightBox {
    width: 84px;
    height: 34px;
    background: #565CFA;
    color: #fff;
    cursor: pointer;
    border-radius: 0px 23px 23px 0px;
    border: 1px solid #565CFA;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightBoxs {
    width: 84px;
    height: 34px;
    cursor: pointer;
    border-radius: 0px 23px 23px 0px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .publicBoxWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .publicBox {
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .publicBox::-webkit-scrollbar {
    display: none;
  }

  .scrollButton {
    position: absolute;
    width: 32px;
    height: 32px;
    background: #F5F5F5;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: #999999;
      }
    }
  }

  .newBox {
    display: flex;
    // height: 130px;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-right: 10px;

    .createNew {
      width: 80px;
      height: 80px;
      background: #F5F5F5;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }

      svg {
        width: 26px;
        height: 26px;
        position: absolute;
        right: 8px;
        bottom: 8px;
      }

      .createIcon {
        width: 22px;
        height: 22px;
        position: static;
      }
    }

    .voiceName {
      width: 80px;
      height: 20px;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .listeningBox {
      width: 56px;
      height: 22px;
      background: rgba(51, 51, 51, 0.1);
      border-radius: 38px;
      margin-top: 10px;
    }
  }
}

.create {
  position: sticky;
  bottom: 0;
  height: 112px;
  background-color: var(--background-color);
  border-top: 1px solid rgba(var(--secondary-text-rbg), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 87px;

  .btn {
    width: 442px;
    height: 60px;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 16px);
    line-height: 20px;

    .start {
      margin-right: 6px;
    }

    .price {
      display: flex;
      align-items: center;

      svg {
        margin-left: 3px;
        width: 16px;
        height: 16px;

        path {
          fill: #fff;
        }
      }
    }
  }

  .vipbtn {
    width: 442px;
    height: 59px;
    background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%);
    border-radius: 20px;

    .vipprice {
      display: flex;
      align-items: center;

      svg {
        width: 22px;
        height: 22px;
      }

      p {
        margin-left: 5px;
        height: 22px;
        font-weight: bold;
        font-size: 16px;
        color: #A54500;
      }
    }

  }
}

.previewBox {
  flex: 1
}

.cmodelbox {
  width: 932px;
  height: 630px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 30px 40px;
  border-radius: 30px;
  position: relative;

  .cmodelTitleBox {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .selectVoice {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      p {
        font-weight: 500;
        font-size: 16px;
        color: #999999;
      }
    }

    .selectVoiceselect {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      p {
        font-weight: bold;
        font-size: 18px;
        color: #333333;
      }
    }

    p {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
      cursor: pointer;
    }
  }

  .cmodelContents {
    // padding: 50px 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .uploadBox {
      width: 90px;
      height: 90px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        width: 40px;
        height: 40px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .cmodelTitle {
      font-weight: bold;
      font-size: 30px;
      color: #333333;
      margin-bottom: 50px;
    }

    .cmodelContent {
      font-weight: 400;
      font-size: 20px;
      color: #333333;
      margin-bottom: 150px;
    }

    .ptitle {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      margin-bottom: 15px;

      span {
        color: #FF2922;
      }
    }

    .textarea {
      padding: 15px;
      width: 370px;
      background: #F5F5F5;
      border-radius: 14px;
      resize: none;
      font-weight: 400;
      font-size: 14px;
      color: #333;
      border: 0px solid transparent;
      margin-bottom: 30px;
    }
  }

  .cmodelFillIn {
    height: 500px;
    padding: 50px 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .fillinicon {
      width: 80px;
      height: 80px;
    }

    .fillintext {
      font-weight: bold;
      font-size: 30px;
      color: #09AD19;
    }
  }

  .modelbuttonBox {
    margin-top: 30px;
    justify-content: end;
    align-items: center;

    .Savebutton {
      background: #565CFA;
      color: var(--text-reverse-color);
      width: 130px;
      margin-left: 10px;
    }

    .Savebutton:disabled {
      background-color: gray;
      cursor: not-allowed;
      opacity: 0.6;
    }

    .Cancelbutton {
      background-color: transparent;
      border: 1px solid #999;
    }
  }

  .modelbuttonbox {
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Savebutton {
      width: 400px;
      height: 52px;
      background: #565CFA;
      color: var(--text-reverse-color);
      margin-bottom: 30px;
    }

    .modeltips {
      font-weight: 400;
      font-size: 12px;
      color: #999;
      margin-bottom: 20px;

      span {
        color: #FF2922
      }
    }
  }

  .modelcenbutton {
    width: 556px;
    margin: 0px auto;

    .editButton {
      width: 556px;
      height: 48px;
      background: rgba(86, 92, 250, 0.1);
      border-radius: 14px;
      font-weight: 500;
      font-size: 14px;
      color: #565CFA;
      margin-bottom: 50px;
    }

    .Cancelbutton {
      width: 188px;
      height: 46px;
      background: #fff;
      border-radius: 14px;
      border: 1px solid #FF2922;
      margin-right: 24px;
      margin-left: 78px;
      font-weight: 500;
      font-size: 14px;
      color: #FF2922;
    }

    .Savebutton {
      width: 188px;
      height: 46px;
      background: #565CFA;
      border-radius: 14px;
      font-weight: bold;
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 78px;
    }
  }

  .input_group {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 430px;
    overflow-y: auto;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 30px;
      margin-bottom: 30px;

      .createItem {
        width: 120px;
        height: 120px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;

        img {
          width: 116px;
          height: 116px;
          border-radius: 20px;
        }

        svg {
          position: absolute;
        }

        .selectIcon {
          width: 26px;
          height: 26px;
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }

      .name {
        width: 110px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 12px 0px;
      }

      .listeningBox {
        width: 56px;
        height: 24px;
        background: rgba(51, 51, 51, 0.1);
        border-radius: 38px;
      }
    }
  }

  .input_group::-webkit-scrollbar {
    width: 6px;
  }

  .input_group::-webkit-scrollbar-thumb {
    background-color: rgb(var(--secondary-text-rbg), 0.2);
    border-radius: 6px;
  }

  .input_group::-webkit-scrollbar-track {
    background: transparent;
  }
}

.audioWave {
  display: flex;
  align-items: flex-end;
  gap: 3px;
}

.ExampleWave {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background: rgba(1, 1, 1, 0.5);
  display: flex;
  align-items: flex-end;
  gap: 3px;
  justify-content: center;
  align-items: center;
}

.bigbar {
  width: 3px;
  height: 20px;
  border-radius: 25%;
  background-color: #fff;
  animation: bounce 1s infinite ease-in-out;
}

.bigbar:nth-child(2) {
  animation-delay: -0.2s;
}

.bigbar:nth-child(3) {
  animation-delay: -0.4s;
}

.bigbar:nth-child(4) {
  animation-delay: -0.6s;
}

.bar {
  width: 2px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  animation: bounce 1s infinite ease-in-out;
}

.bar:nth-child(2) {
  animation-delay: -0.2s;
}

.bar:nth-child(3) {
  animation-delay: -0.4s;
}

.bar:nth-child(4) {
  animation-delay: -0.6s;
}

.bar:nth-child(5) {
  animation-delay: -0.8s;
}

.bar:nth-child(6) {
  animation-delay: -1s;
}

.bar:nth-child(7) {
  animation-delay: -0.2s;
}

.bar:nth-child(8) {
  animation-delay: -0.4s;
}

.bar:nth-child(9) {
  animation-delay: -0.6s;
}

.bar:nth-child(10) {
  animation-delay: -0.8s;
}

.bar:nth-child(11) {
  animation-delay: -1s;
}

.bar:nth-child(12) {
  animation-delay: -0.2s;
}

.bar:nth-child(13) {
  animation-delay: -0.4s;
}

.bar:nth-child(14) {
  animation-delay: -0.6s;
}

.bar:nth-child(15) {
  animation-delay: -0.8s;
}

.bar:nth-child(16) {
  animation-delay: -1s;
}

.bar:nth-child(17) {
  animation-delay: -0.2s;
}

.bar:nth-child(18) {
  animation-delay: -0.4s;
}

@keyframes bounce {

  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(1.5);
  }
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 140px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;

  .moreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .deletemoreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;

      path {
        fill: #FF2922
      }
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #FF2922;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.creatbutton {
  width: 158px !important;
  height: 46px !important;
  background: #565CFA;
  border-radius: 14px;
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

.addVoiceIcon {
  width: 18px;
  height: 18px;

  path {
    fill: #999999 !important
  }
}