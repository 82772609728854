.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 111.11vh;
}


.overlay {
  background-color: rgb(0 0 0 / 0.5);
  height: 111.11vh !important;
  width: 111.11vw !important;
}