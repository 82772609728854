.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 100vh;
}

@media (max-height: 900px) {
  .parent {
    margin-top: 50px;
  }
}

.child {
  padding: 20px;

  .selectbox {
    flex-direction: column;
    margin-bottom: 30px;
  }

  /* 样式文件 styles.css */
  .checkbox_custom {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: calc(var(--font-size-add) + 18px);
    margin-bottom: 12px;
    margin-right: 38px;
  }

  /* 隐藏默认的复选框 */
  .checkbox_custom input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* 创建自定义复选框 */
  .checkbox_custom input[type="checkbox"]+label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid #999;
    opacity: 0.5;
    border-radius: 6px;
    background: white;
  }

  /* 复选框选中时的样式 */
  .checkbox_custom input[type="checkbox"]:checked+label::before {
    border-color: blue;
    background-color: blue;
    content: "✔";
    color: white;
    text-align: center;
    font-size: calc(var(--font-size-add) + 16px);
    line-height: 24px;
  }

  .checkbox_custom label {
    padding-left: 35px;
    line-height: 24px;
  }

  .questionbox {
    flex-direction: column;
    margin-bottom: 30px;

    .questiontextarea {
      padding: 16px 21px;
      width: 558px;
      height: 144px;
      background-color: var(--background-color);
      resize: none;
      border: 0px;
      border-radius: 20px
    }
  }

  .uploadContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }

  .imagesbox {
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin: 10px 0px 30px;
  }

  .previewImage {
    width: 120px;
    height: 120px;
    margin: 0px 5px;
    border-radius: 10px;
    object-fit: cover;
  }

  .uploadBox {
    width: 120px;
    height: 120px;
    border: 0px solid #ccc;
    border-radius: 10px;
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    position: relative;
    cursor: pointer;
  }

  .uploadBoxicon {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .fileUploadInput {
    display: none;
  }

  .plusIcon {
    font-size: calc(var(--font-size-add) + 24px);
    color: #ccc;
  }


  .phonebox {
    flex-direction: column;
    margin-bottom: 80px;

    .phonetextarea {
      padding: 16px 21px;
      width: 558px;
      height: 28px;
      background-color: var(--background-color);
      resize: none;
      border: 0px;
      border-radius: 20px
    }
  }


  .cbutton {
    background: #565CFA;
    color: var(--text-reverse-color);
    width: 600px;
  }

}

.text {
  height: 20px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 20px;
}