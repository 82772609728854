.writing {
  background-color: var(--background-color);
  border-radius: 20px;
  padding-bottom: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1280px;

  .titleBox {
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);

    .icon {
      width: 40px;
      height: 40px;
      margin-top: 3px;
      border-radius: 8px;
      margin-right: 10px;
    }

    .subTitle,
    .record {
      width: 50%;
      box-sizing: border-box;
      padding: 20px 30px;
      height: 80px;
      border-right: 1px solid var(--border-color);
    }

    .record {
      padding-left: 40px;
      box-sizing: border-box;
      border-right: none;
      background: #F9F9F9;
    }
  }

  .containerBox {
    height: calc(100% - 80px);
    display: flex;
  }

  .content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow: hidden;
    height: 100%;
    border-right: 1px solid var(--border-color);
    position: relative;

    .textContent {
      border-radius: 12px;
      padding: 30px 30px 40px 40px;
      flex: 1;
      overflow-y: auto;
      position: relative;
      cursor: text;

      .titleInputBox {
        overflow: hidden;
        padding-top: 30px;
      }

      .titleInput {
        border: none;
        width: 100%;
        font-size: calc(var(--font-size-add) + 30px) !important;
        margin-bottom: 10px;
        font-weight: bold;
        text-indent: 44px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        outline-offset: 2px;

        &:focus-visible {
          border: none;
        }

        &:focus {
          border: none;
        }

        &::placeholder {
          color: var(--secondary-text);
        }
      }
    }

    .footer {
      border-top: 1px solid var(--border-color);
      padding: 34px 87px;
      height: 112px;
      box-sizing: border-box;
      justify-content: space-between;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: #ffffff;
    }

    .welcome {
      position: absolute;
      right: -38px;
      width: 100px;
      rotate: -45deg;
      bottom: 160px;
    }

    .welcome_text {
      position: absolute;
      bottom: 160px;
      right: 40px;
      transition: all 0.3s;
      width: 152px;
      height: 46px;
      animation: toZero 3s ease-in-out infinite;

      .welBox {
        width: 100%;
        height: 100%;
        position: relative;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      &.hidden {
        width: 0;
        height: 0;
      }
    }
  }

  .history {
    width: 50%;
    padding: 0px 20px;
    box-sizing: border-box;
    background: #F9F9F9;

    :global(.ms-container) {
      padding: 30px 20px;
      box-sizing: border-box;
    }

    .item {
      padding: 20px;
      border-radius: 20px;
      width: 310px;
      height: 150px;
      box-sizing: border-box;
      flex-direction: column;
      background-color: #ffffff;
      transition: all 0.3s;
      cursor: pointer;
      margin-right: 25px;
      margin-bottom: 25px;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:hover {
        scale: 1.05;
      }

      .itemTitle {
        align-items: center;
        margin-bottom: 10px;
        width: 100%;

        .title {
          font-weight: bold;
          font-size: calc(var(--font-size-add) + 16px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 80%;
          margin-right: 20px;
        }
      }

      .textContent {
        color: var(--secondary-text);
        font-size: calc(var(--font-size-add) + 14px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .itemBottom {
        justify-content: space-between;
        margin-top: 16px;
        width: 100%;
        position: relative;

        .aitext {
          position: absolute;
          right: 5px;
          bottom: -15px;
          font-size: 10px;
          font-weight: bold;
          color: #999;
        }
      }
    }
  }
}

.generateBtn {
  width: 330px !important;
  height: 50px !important;
  box-shadow: 0px 4px 10px 0px rgba(97, 84, 255, 0.4);
  border-radius: 16px;
  padding: 5px 16px;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  color: #ffffff;

  &:hover {
    background: linear-gradient(90deg, #3D2EF0 0%, #7E68FF 100%);
  }
}

.input {
  background-color: transparent;
  width: 100%;
  font-size: calc(var(--font-size-add) + 16px);
}

.spanBox {
  color: var(--secondary-text) !important;
  font-size: calc(var(--font-size-add) + 16px);
}

.editInput {
  padding: 0 10px;
  font-size: calc(var(--font-size-add) + 16px);

  &:empty::before {
    content: attr(content);
    color: var(--secondary-text);
    opacity: 0.5;
  }
}

.stopIcon {
  animation: zoomStopIcon 0.6s infinite alternate;
}

@keyframes zoomStopIcon {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.2);
  }
}

.generateContent,
.textContent {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar:hover {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--secondary-text-rbg), 0.5);
    border-radius: 11px;
  }
}

.selectmodels {
  position: absolute;
  top: -160px;
  left: 0px;
  width: 272px;
  height: 128px;
  padding: 5px 20px 10px;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  border-radius: 20px;
  overflow: hidden;

  .selectmodel {
    padding: 14px 10px;
    // border-bottom: 1px solid #ddd;
    position: relative;
    cursor: pointer;

    .Select {
      position: absolute;
      left: -10px;
      top: 30px;
    }

    .selectName {
      font-weight: bold;
      font-size: 14px;
      color: #565CFA;
    }

    .selectTips {
      font-size: 14px;
      color: rgba(86, 92, 250, 0.60);
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .selectname {
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }

    .selecttips {
      font-size: 14px;
      color: #999;
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .selectmodel:first-child {
    padding: 14px 10px 16px;
    border-bottom: 1px solid #ddd;
  }

  .tagtext {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 17px;
    width: 70px;
    height: 24px;
    color: #fff;
    background: #FF7A00;
    border-radius: 10px 10px 10px 0px;
  }

  .selectprice {
    margin-left: 13px;
    justify-content: center;
    align-items: center;
    color: #565CFA;

    .free {
      text-decoration: line-through;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    svg {
      margin-top: 2px;
      padding: 0px 1px 0px 2px;
    }

    svg path {
      fill: #565CFA;
    }
  }

  .pricetext {
    margin-left: 13px;
    justify-content: center;
    align-items: center;

    svg {
      margin-top: 2px;
      padding: 0px 1px 0px 2px;
    }
  }
}

.firstModal {
  width: 906px;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 30px;
  box-sizing: border-box;

  .swiperBox {
    width: 846px;
    height: 400px;
    overflow: hidden;
    position: relative;

    .swiper {
      width: 846px;
      height: 400px;
    }

    .pre,
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      width: 30px;
      height: 30px;
      z-index: 9;

      &:hover path {
        fill: rgb(var(--primary-color));
        fill-opacity: 1;
      }
    }

    .pre {
      left: 0;
    }

    .next {
      right: 0;
    }
  }

  .content {
    text-align: center;
    max-width: 432px;
    margin: 0 auto 40px;

    .title {
      font-size: calc(var(--font-size-add) + 22px);
      color: var(--text-color);
      margin-bottom: 14px;
    }

    .desc {
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--secondary-text);
    }
  }

  .footer {
    text-align: center;
    padding-bottom: 20px;

    .start {
      width: 220px;
      border-radius: 25px;
    }
  }
}

.bullet {
  opacity: 0.3;
  background-color: var(--text-color) !important;

  &:global(.swiper-pagination-bullet-active) {
    opacity: 1;
  }
}

.menus {
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 140px;
  border-radius: 20px;
  position: fixed;
  background-color: #ffffff;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 10;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);

  .menusItem {
    padding: 14px 13px;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-top: -2px;
    }

    .stroke path {
      stroke: var(--text-color);
      stroke-opacity: 1;
    }

    &:hover {
      color: rgb(var(--primary-color));

      .fill path {
        fill: rgb(var(--primary-color));
      }

      .stroke path {
        stroke: rgb(var(--primary-color));
      }
    }
  }
}

.opBtn {
  width: 110px !important;
  height: 46px !important;
  border-radius: 16px !important;
}

@keyframes toZero {
  0% {
    scale: 0;
    width: 0;
  }

  10% {
    scale: 1;
    width: 152px;
  }

  100% {
    scale: 1;
  }
}

.aitext {
  position: absolute;
  right: 0px;
  bottom: -20px;
  font-size: 10px;
  font-weight: bold;
  color: #999;
}


.selectprice {
  justify-content: flex-start;
  align-items: center;
  color: var(--secondary-text);

  .free {
    text-decoration: line-through;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .vip {
    color: #FF7B00;
  }

  svg {
    margin-top: 2px;
    padding: 0px 1px 0px 2px;
  }

  svg path {
    fill: var(--secondary-text);
  }
}

.fullbox {
  height: 500px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    width: 150px;
    height: 150px;
  }

  .prompt {
    color: #999;
  }
}