.hisFlex {
  background: #f9f9f9;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  border-left: 1px solid #DDDDDD;

  .centerItemsbox {
    height: 31px !important;
    padding: 24px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    border-left: 0px;

    .title {
      height: 31px;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      margin-right: 10px;
    }
  }

  .hisBoxs {
    width: 100%;
    padding: 30px 0px;

    .hisbox {
      width: 490px;
      margin: 0px auto 30px;

      .headbox {
        width: 100%;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;

        svg {
          width: 22px;
          height: 22px;
          margin-right: 3px;
        }

        .filename {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .imgboxs {
        width: 100%;
        margin-bottom: 10px;
        justify-content: space-between;

        .imgbox {
          width: 190px;
          height: 190px;
          position: relative;
          background: #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          overflow: hidden;

          .brforeimg {
            // border-radius: 30px;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            // background-repeat: no-repeat;
          }

          .afterimg {
            width: 190px;
            height: 190px;
            border-radius: 30px;
            object-fit: contain;
            background: #EEEEEE;
          }

          .downloadicon {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 42px;
            background: rgba(0, 0, 0, 0.6);
            justify-content: center;
            align-items: center;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            svg {
              width: 30px;
              height: 30px;

              path {
                fill: #fff;
              }
            }
          }
        }

        .errorbox {
          width: 130px;
          height: 190px;
          padding: 0px 30px;
          position: relative;
          background: #EEEEEE;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          svg {
            width: 60px;
            height: 60px;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-clamp: 3;
            -webkit-line-clamp: 3;
          }
        }

        .loadingbox {
          width: 190px;
          height: 190px;
          position: relative;
          background: #EEEEEE;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            font-weight: 400;
            font-size: 12px;
            color: #999999;
          }
        }
      }

      .tipstext {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 86px;
        height: 38px;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 30px 0px 20px 0px;
        justify-content: center;
        align-items: center;
      }

      .footbox {
        flex-direction: row;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        p {
          margin-right: 20px;
        }
      }

      .points {
        width: 20px;
        height: 20px;

        path {
          fill: #ddd;
        }
      }

      .viptext {
        font-weight: 400;
        font-size: 14px;
        color: #FF7B00;

        svg {
          width: 20px;
          height: 20px;
          margin-left: 3px;

          path {
            // fill: linear-gradient(348deg, #FF8A00 0%, #FFB629 100%);
            fill: #FFA500
          }
        }
      }

      .notviptext {
        font-weight: 400;
        font-size: 14px;
        color: #565CFA;
        margin-left: 3px;

        svg {
          width: 12px;
          height: 12px;
          margin-top: 3px;

          path {
            fill: #565CFA
          }
        }
      }
    }

    .fullbox {
      width: 100%;
      height: 300px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      svg {
        width: 150px;
        height: 150px;
      }

      .prompt {
        color: #999;
      }
    }
  }
}