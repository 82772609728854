.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  height: 100%;
  // padding-bottom: 110px;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.notVipBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 3;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  overflow: hidden;

  .robotimgbox {
    margin-right: 30px;
    padding: 0px 50px;
    position: relative;

    img {
      width: 360px;
      height: 781px;
    }

    svg {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .robotleftsvg {
      position: absolute;
      top: 50%;
      left: -5px;
    }

    .robotrightsvg {
      position: absolute;
      top: 50%;
      right: -5px;
    }

    .indicators {
      position: absolute;
      bottom: -20px;
      left: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .indicators span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background-color: rgba(153, 153, 153, 0.5);
      border-radius: 50%;
    }

    .indicators span.active {
      background-color: #565CFA;
    }
  }

  .titleText {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    margin-bottom: 16px;
  }

  .ulbox {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #bbb;
  }

  .ulbox li {
    margin-bottom: 7px;
  }

  .priceText {
    justify-content: start;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    color: #565CFA;
    margin-bottom: 10px;

    svg {
      width: 16px;
      height: 16px;
      margin: 3px 4px 0px;

      path {
        fill: #565CFA;
      }
    }
  }

  .previewBox {
    width: 128px;
    height: 52px;
    background: #565CFA;
    border-radius: 16px;
    font-size: 16px;
    color: #FFFFFF;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }

  .unlockBox {
    width: 170px;
    height: 52px;
    background: #09AD19;
    border-radius: 16px;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 20px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
}

.contentsBox {
  display: contents;
  height: calc(100% - 10px);

  .loginbox {
    width: 370px;
    height: 50px;
    padding: 20px;
    background: rgba(86, 181, 250, 0.1);
    border-radius: 18px;
    justify-content: space-between;

    .loginstatus {
      .loginedbox {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 26px;
          height: 26px;
          padding-top: 4px;
        }

        p {
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
      }

      .wxname {
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        text-align: center;
      }
    }

    .statusbox {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        color: #09AD19;
      }
    }
  }
}

.Unloginbox {
  width: 370px;
  height: 50px;
  padding: 20px;
  // background: rgba(86, 181, 250, 0.1);
  background: rgba(255, 41, 34, 0.1);
  border-radius: 18px;
  justify-content: space-between;

  .Unloginedbox {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 26px;
      height: 26px;
      padding-top: 4px;
    }

    p {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }
  }

  .Unstatusbox {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      color: #FF5852;
    }
  }
}

.previewBox {
  width: 50%;
  height: 100%;

  .flexColumn {
    background: #f9f9f9;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  .centerItemsbox {
    height: 31px !important;
    padding: 24px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;

    .title {
      height: 31px;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      margin-right: 10px;
    }

    .prompt {
      height: 31px;
      margin-top: 10px;
      margin-left: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #FF7B00;
    }
  }

  .iframeBox {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    border-left: 1px solid #ddd;
  }
}

.cmodelbox {
  width: 680px;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 40px;
  flex-direction: column;
  align-items: center;

  .cmodelTitleBox {
    width: 100%;
    justify-content: space-between;

    p {
      font-weight: bold;
      font-size: 20px;
      color: #333333;
    }

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.vipPaybox {
  width: 390px;
  background: linear-gradient(180deg, #FFF5DA 0%, #FFFCF2 100%);
  border-radius: 30px;
  padding: 30px 40px;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../assets/png/robot_BG.png');
  background-size: cover;
  background-repeat: no-repeat;

  .paytips {
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
  }

  .vippaytips {
    font-weight: 400;
    font-size: 14px;
    color: #D6AE8A;
    margin-bottom: 5px;
  }

  .vipPayTitleBox {
    width: 100%;
    justify-content: space-between;

    p {
      font-weight: bold;
      font-size: 20px;
      color: #974800;
    }

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}


.buttonBox {
  margin-top: 20px;
  width: 100%;
  justify-content: end;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #974800;

  svg {
    width: 13px;
    height: 13px;
    margin: 0px 3px 1px;

    path {
      fill: #974800
    }
  }

  .button {
    width: 128px;
    height: 46px;
    margin-left: 15px;
    background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%);
    border-radius: 16px;
    font-weight: bold;
    font-size: 16px;
    color: #974800;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.wxewmbox {
  width: 360px;
  height: 336px;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 40px;
  flex-direction: column;
  align-items: center;
  position: relative;

  .wxewmTitleBox {
    width: 100%;
    justify-content: space-between;

    p {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .wxtitleText {
    margin: 10px 0px;
    font-size: 16px;
  }

  .wxewm {
    width: 170px;
    height: 170px;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .loadingicon {
    width: 40px;
    height: 40px;
  }

  .refreshEwm {
    font-weight: 400;
    font-size: 16px;
    color: #565CFA;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
  }

  .limitBox {
    position: absolute;
    left: -7px;
    top: 14px;
    width: 180px;
    height: 180px;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    border-radius: 14px 14px 14px 14px;
  }

  .ewmbutton {
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;

  }

  .tipstext {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(153, 153, 153, 0.5);
  }
}

.notbalancebox {
  width: 344px;
  height: 168px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 24px 30px;
  border-radius: 30px;
  position: relative;
}

.modelbuttonbox {
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
}

.Savebutton {
  background: #565CFA;
  color: var(--text-reverse-color);
  width: 130px;
}

.texttips {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}

.notbalanceTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  p {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}