.funcModal {
  position: relative;
  max-width: 600px;
  width: 736px;
  background: #fff;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: center;
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: bold;
    color: var(--text-color);

    svg {
      margin-right: 8px;
      width: 30px;
      height: 30px;
    }
  }

  .closeIcon {
    cursor: pointer;
    color: #666;
    transition: color 0.2s;

    &:hover {
      color: #333;
    }
  }
}

.switchRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .switchText {
    margin-left: 8px;
    color: var(--text-color);
    font-size: calc(var(--font-size-add) + 12px);
    font-weight: 400;
    line-height: 14px;
  }
}

.functionGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.functionItem {
  display: flex;
  padding: 16px;
  background: #f6f6f6;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #f0f0f0;
  }

  .iconWrapper {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
  }

  .functionContent {
    display: flex;
    flex-direction: column;

    .functionTitle {
      font-weight: 500;
      margin-bottom: 4px;
      color: #333;
    }

    .functionDesc {
      font-size: 12px;
      color: #666;
    }
  }
}


.speechToTextDialog {
  width: 500px;
  height: 70px;
  position: absolute;
  top: -85px;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  background: #fff;
  border-radius: 24px;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--font-size-add) + 18px);
    font-weight: bold;
  }

  .stopButton {
    color: #FF2922;
    font-size: calc(var(--font-size-add) + 18px);
    font-weight: 400;
    cursor: pointer;
  }
}