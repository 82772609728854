.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  min-width: 556px;
  height: calc(100% - 110px);
  padding-bottom: 110px;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: #fff;
}

.previewBox {
  width: 50%;
  height: 100%;
  flex-direction: column;
}

.toolbox {
  width: 100%;
  height: 39px;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0px solid #DDDDDD;
  z-index: 2;
}

.contentsBox {
  width: 100%;
  // height: calc(100% - 83px);
  height: 100vh;

  .comBoxs {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.footbutton {
  width: 100%;
  position: absolute;
  border-top: 1px solid #DDDDDD;
  padding-top: 28px;
  bottom: 34px;
  right: 0px;
}

.databox {
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    background: rgba(241, 244, 255, 0.5);
    border-radius: 10px;
    margin-right: 10px;
  }

  p {
    font-weight: 900;
    font-size: 20px;
    color: #333333;
  }
}

.hisbut {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  cursor: pointer;
}

.tabs {
  width: 556px;
  background-color: #fff;
  margin: 10px auto 0px;

  .tabList {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    color: #999999;
    background-color: transparent;
    border: 0px;
    // transition: all 0.1s ease;
  }

  .selectedTab {
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }
}

.tabPanels {
  height: calc(100% - 40px);
}

.tabPanel {
  padding: 0px;
  height: 100%;
  overflow-y: auto;
  background-color: transparent;
  flex-direction: column;
}

.buttonBox {
  width: 556px;
  margin: 0px auto 10px;
  justify-content: end;
  position: relative;

  .aitext {
    position: absolute;
    right: 0px;
    bottom: -20px;
    font-size: 10px;
    font-weight: bold;
    color: #999;
  }
}

.fullbox {
  width: 516px;
  // height: 480px;
  height: calc(100% - 105px);
  // max-height: 480px !important;
  min-height: 480px;
  padding: 20px;
  background: #F5F5F5;
  border-radius: 20px;
  flex-direction: column;
  overflow-y: hidden;
  user-select: text;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }

  &::-webkit-scrollbar {
    width: 4px;
    // display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    color: red;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }
}

.nullBox {
  width: 100%;
  height: 480px;
  background: #F5F5F5;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  svg {
    width: 120px;
    height: 120px;
  }

  img {
    width: 120px;
    height: 120px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: rgba(153, 153, 153, 0.5);
  }
}

.titleText {
  font-weight: 400;
  font-size: 14px;
  color: #FF7B00;
  margin: 10px 0px;
}

.videotabs {
  margin-bottom: 16px;

  img {
    width: 34px;
    height: 34px;
    border-radius: 12px;
    margin-right: 20px;
  }
}

.tabTextareaBox {
  width: 523px;
  height: 25px;
  border: 0px;
  padding: 10px 15px;
  background: #F5F5F5;
  border-radius: 18px;
  border: 1px solid #F5F5F5;
  position: relative;

  .tipstext {
    position: absolute;
    right: 10px;
    bottom: 12px;
    font-size: calc(var(--font-size-add) + 14px);
    color: #FF5852;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 2px;
    }
  }
}

.focused {
  border: 1px solid #565CFA;
}

.error {
  border: 1px solid #FF2922;
}

.tabTextarea {
  width: 526px;
  height: 24px;
  border: 0px;
  font-size: 16px;
  resize: none;
  background: #F5F5F5;
  border-radius: 18px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }
}

.uploadBoxx {
  width: 404px;
  height: 130px;
  background: #F5F5F5;
  border-radius: 18px;
  margin: 3px 5px 0px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #999999;

  .loader {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 4px solid #fff;
    margin: 0px 30px;
    border-radius: 50%;
    border-top: 4px solid transparent;
    animation: spin 1s linear infinite;
    position: relative;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .defaultbox {
    width: 90px;
    height: 90px;
    margin-right: 20px;
    background: #DDDDDD;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .uploadIcon {
      width: 30px;
      height: 30px;
    }

    .errplusIcon {
      font-weight: 500;
      font-size: 14px;
      color: #FF5852;
    }
  }

  .previewVideo {
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }

  .SucessBox {
    justify-content: start;
    align-items: center;
    margin-top: 15px;

    svg {
      width: 14px;
      height: 14px;
    }

    .errsvg {
      width: 22px;
      height: 22px;
      margin-right: 3px;
    }

    .againText {
      font-weight: 500;
      font-size: 16px;
      color: #565CFA;
    }
  }

  .textWithEllipsis {
    width: 200px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
  }

  .uploadSucess {
    font-weight: 400;
    font-size: 14px;
    color: #09AD19;
  }

  .uploadBoxicon {
    width: 28px !important;
    height: 28px !important;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.uploadBox {
  width: 120px;
  height: 120px;
  background: #F5F5F5;
  border-radius: 18px;
  margin: 3px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  cursor: pointer;

  .previewVideo {
    width: 104px;
    height: 104px;
    border-radius: 18px
  }

  .uploadIcon {
    width: 40px;
    height: 40px;
  }

  .plusIcon {
    font-weight: 500;
    font-size: 16px;
  }

  .ulbox {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 400px;
    left: 130px;

    p {
      margin-left: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }

    li {
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }
  }

  .errplusIcon {
    margin-top: 3px;
    font-size: 14px;
    color: #FF5852;
  }

  .againText {
    position: absolute;
    right: -100px;
    font-weight: 500;
    font-size: 16px;
    color: #565CFA;
  }
}

.uploadBoxicon {
  width: 28px !important;
  height: 28px !important;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.uploadBoxicon {
  width: 28px;
  height: 28px;
  position: absolute;
  top: -5px;
  right: -10px;
  cursor: pointer;
}

.fileUploadInput {
  display: none;
}

.centerItemsbox {
  height: 31px !important;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;
  justify-content: space-between;

  .title {
    height: 31px;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    white-space: nowrap;
  }

  .prompt {
    height: 31px;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FF7B00;
  }

  .cleartext {
    font-weight: 400;
    font-size: 16px;
    color: rgba(153, 153, 153, 0.5);
    cursor: pointer;
  }
}

.chatlistBox {
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - 190px);
  border-left: 1px solid #ddd;

  .loadMore {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }

  .agent {
    width: 600px;
    margin: 40px auto;
    text-align: center;

    .avatar {
      width: 150px;
      height: 150px;
      border-radius: 50px;
    }

    .title {
      font-size: calc(var(--font-size-add) + 24px);
      color: var(--text-color);
      font-weight: bold;
      margin-top: 30px;
    }

    .desc {
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--secondary-text);
      margin-top: 16px;
      text-align: left;
    }
  }
}

.chatfoot {
  width: 100%;
  height: 111px !important;
  background: #F5F5F5;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid rgba(153, 153, 153, 0.2);
  justify-content: center;
  align-items: center;
  position: relative;

  .chattextarea {
    width: 80%;
    height: 24px;
    padding: 12px;
    background: #FFFFFF;
    font-size: 16px;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #DDDDDD;
    resize: none;
  }

  .footbut {
    position: absolute;
    right: 12%;
    width: 34px;
    height: 34px;
    background: linear-gradient(90deg, #5344FF 0%, #9C8BFF 88%);
    box-shadow: 0px 4px 10px 0px rgba(97, 84, 255, 0.4);
    border-radius: 25px;
    cursor: pointer;
  }

  .notfootbut {
    position: absolute;
    right: 12%;
    width: 34px;
    height: 34px;
    background: #CCCCCC;
    border-radius: 25px;
  }
}


.button {
  z-index: 0;
  height: 40px;
  width: 92px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  pointer-events: all;
  overflow: hidden;
  border-radius: 14px;
  box-shadow: 0px 4px 10px 0px rgba(110, 110, 110, 0.24);
  margin-left: 1rem;

  svg {
    margin-top: 1px;
    margin-right: 3px;
  }

  &.disabled {
    color: #999;
    pointer-events: none;
    background-color: rgba(153, 153, 153, 0.2);
    box-shadow: none;
  }
}

.loadicon {
  position: absolute;
  left: 40px;
  top: 40px;
  width: 30px !important;
  height: 30px !important;
  margin-bottom: 20px;
  animation: loading 1s ease infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nullbox {
  height: calc(100% - 230px);
  min-height: 480px;
  padding: 20px;
  background: #F5F5F5;
  flex-direction: column;
  overflow-y: hidden;
  user-select: text;
  justify-content: center;
  align-items: center;

  svg {
    width: 150px;
    height: 150px;
  }

  .prompt {
    color: #999;
  }
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 140px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;
  z-index: 999;

  .moreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .deletemoreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;

      path {
        fill: #FF2922
      }
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #FF2922;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}