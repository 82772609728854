.chat {
  display: flex;
  height: 100%;
  position: relative;

  .nav {
    position: absolute;
    width: 20px;
    height: 100px;
    left: calc(var(--chat-nav-width) + 1px);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    transition: left 0.3s ease;

    &.hide {
      left: 0 !important;

      .navOpIcon {
        transform: translate(-50%, -50%) !important;
      }
    }

    .navBox {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;

      .navOp {
        width: 20px;
      }

      .navOpIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-bg);
  position: relative;

  .scrollContent {
    overflow: hidden;
    height: 100%;
  }
}