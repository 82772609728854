.realtimeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #0F0E27;
  color: #ffffff;
  position: relative;

  .contentContainer {
    display: flex;
    flex: 1;
    height: 100%;
    padding-right: 334px;
    overflow: hidden;
    flex-direction: column-reverse;

    .leftSection {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px;
      overflow: hidden;
    }
  }

  .realtimeWelcome {
    width: 770px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .callBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 240px;
  }

  .audioCallSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.3s ease;
    width: 240px;
    height: 158px;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    .micIcon {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-top: -32px;
    }

    .buttonText {
      font-size: calc(var(--add-size) + 16px);
      color: #ffffff;
      margin-bottom: 8px;
      margin-top: 20px;
    }

    .callCredit {
      font-size: calc(var(--add-size) + 14px);
      color: rgba(255, 255, 255, 0.3);
      margin-bottom: 16px;
    }

    .creditCost {
      color: #ffffff;
      font-size: calc(var(--add-size) + 14px);
      display: flex;
      align-items: center;

      .creditIcon {
        width: 12px;
        height: 12px;
        margin: -2px 2px 0;
      }
    }
  }

  .realtimeStatus {
    position: absolute;
    box-sizing: border-box;
    bottom: 180px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    background: #ffffff;
    z-index: 10;
    width: 450px;
    height: 64px;
    box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
    border-radius: 16px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .status {
      display: flex;
      align-items: center;

      .statusTitle {
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--text-color);
        margin-right: 10px;
        display: block;

        .time {
          width: 62px;
          display: inline-block;
        }
      }

      .statusIcon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .operates {
      display: flex;
      align-items: center;

      .operateItem {
        color: var(--text-color);
        text-align: center;
        font-size: calc(var(--font-size-add) + 12px);
        margin-left: 40px;
        cursor: pointer;

        .icon {
          width: 26px;
          height: 26px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.inputContainer {
  padding: 26px 0 26px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(15, 14, 39, 0.9);
  z-index: 9;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .inputBox {
    width: 820px;
    height: 60px;
    border-radius: 16px 16px 16px 16px;
    padding: 20px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border: none;
    font-size: calc(var(--add-size) + 16px);
    position: relative;

    .input {
      background: transparent;
      color: #ffffff;
      border: none;
      font-size: calc(var(--add-size) + 16px);
      width: 100%;
      height: 100%;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .sendBox {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgb(var(--primary-color));
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &:hover {
        background: rgba(var(--primary-color), 0.8);
      }

      .sendIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.agentInfo {
  display: flex;
  flex-direction: column;
  margin-top: 160px;
  width: 100%;

  .agentAvatarBox {
    display: flex;
    align-items: center;
  }

  .agentAvatar {
    width: 110px;
    height: 110px;
    border-radius: 30px;
    margin-right: 20px;
  }

  .agentName {
    font-size: calc(var(--font-size-add) + 20px);
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 14px;
  }

  .agentDescription {
    margin-top: 40px;

    .descriptionTitle {
      font-size: calc(var(--font-size-add) + 16px);
      color: #ffffff;
      margin-bottom: 16px;
    }

    .descriptionContent {
      font-size: calc(var(--font-size-add) + 14px);
      color: rgba(255, 255, 255, 0.5);
    }
  }
}


// 实时对话
.realtimeChat {
  width: 820px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}