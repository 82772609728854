.newtoast {
  min-width: 110px;
  max-width: 230px;
  margin-bottom: 20px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--font-size-add) + 16px);
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 30px 0px rgba(15, 11, 54, 0.2);
  text-align: center;

  &.success {
    color: #09AD19;
  }

  &.error {
    color: #FF5852;
  }

  &.tips {
    color: #565CFA;
  }

  &.warning {
    color: #FF7B00;
  }
}

.modalcontent {
  top: 50px;
  margin: 0 40%;
}

.icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}

@media (prefers-color-scheme: dark) {
  .newtoast {
    background: #1C1E67;
  }
}

@media (prefers-color-scheme: light) {
  .newtoast {
    // background: #fff;
    background: #1C1E67;
  }
}