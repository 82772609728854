.drawer {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 334px;
  background: #27263D;
  z-index: 10;
  padding: 40px 20px;
  box-sizing: border-box;
}

.modelSelector,
.agentSelector {
  margin-bottom: 30px;
}

.selector {
  position: relative;

  .title {
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 12px;
  }

  .selectorValue {
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 56px;
    border-radius: 14px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;

    .img {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      margin-right: 10px;
    }

    .value {
      margin-right: auto;
      color: #FFFFFF;
      font-size: calc(var(--font-size-add) + 14px);
    }

    .arrow {
      transition: transform 0.3s ease-in-out;
      width: 16px;
      height: 16px;

      &.open {
        transform: rotate(180deg);
      }

      path {
        fill: var(--secondary-text);
      }
    }
  }

  .selectorContent {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 100%;
    transform: rotateX(90deg);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    z-index: 1;

    &.open {
      transform: rotateX(0deg);
    }
  }
}

.options {
  display: flex;
  flex-direction: column;
  background: #3D3C50;
  border-radius: 14px;

  .option {
    padding: 20px 16px;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    border-bottom: 1px solid #505062;
    display: flex;
    align-items: center;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      margin-right: 10px;
    }

    &.selected {
      color: #FFFFFF;
    }

    .play {
      width: 24px;
      height: 24px;
      margin-left: auto;

      path {
        fill: #FFFFFF;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

/* 音频波形动画容器 */
.audioWaves {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 24px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;
}

/* 音频波形的每个条 */
.audioWaves .bar {
  background-color: #FFFFFF;
  width: 2px;
  height: 15px;
  border-radius: 4px;
  animation: sound-wave 1s infinite ease-in-out;
}

/* 为每个条设置不同的动画延迟 */
.audioWaves .bar:nth-child(1) {
  animation-delay: 0s;
}

.audioWaves .bar:nth-child(2) {
  animation-delay: 0.2s;
}

.audioWaves .bar:nth-child(3) {
  animation-delay: 0.4s;
}

.audioWaves .bar:nth-child(4) {
  animation-delay: 0.6s;
}

.audioWaves .bar:nth-child(5) {
  animation-delay: 0.8s;
}

/* 定义波形动画 */
@keyframes sound-wave {
  0% {
    height: 3px;
  }

  50% {
    height: 15px;
  }

  100% {
    height: 3px;
  }
}