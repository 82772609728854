.content {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  position: relative;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.child {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 20px;
  overflow-y: hidden;

  .selectDate {
    width: 400px;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0px 100px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: var(--background-color);
    border-radius: 20px;
    cursor: pointer;

    p {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }
  }

  .listbox {
    flex: 1;

    .itmebox {
      width: 552px;
      height: 86px;
      padding: 20px 24px;
      background-color: var(--background-color);
      border-radius: 20px;
      margin-bottom: 20px;
      flex-shrink: 0;

      .billbox {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        position: relative;

        .billsboxicon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          border-radius: 0px 0px 0px 0px;
        }

        .billsboxadd {
          height: 20px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: bold;
          font-size: calc(var(--font-size-add) + 18px);
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .vipbillsboxadd {
          height: 20px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          font-size: calc(var(--font-size-add) + 14px);
          color: #FF7B00;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .vippointsicon {
          width: 12px;
          height: 17px;
          margin-left: 5px;

          path {
            fill: rgba(153, 153, 153, 0.5)
          }
        }

        .pointsicon {
          width: 12px;
          height: 17px;
          margin-left: 5px;
        }
      }

      .textbox {
        padding: 5px 20px;
      }
    }
  }
}

.noChat {
  height: 100%;
  margin-top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 18px;
    color: rgba(86, 92, 250, 0.3);
  }
}

.fasttext {
  width: 200px;
  height: 18px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.endtext {
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
  text-align: left;
  font-style: normal;
  text-transform: none;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}