.container {
  height: calc(100% - 192px);
}

.content {
  padding: 30px 87px 30px;
  box-sizing: border-box;
  width: 730px;
  margin: 0 auto;
}

.typeChange {
  display: flex;
  justify-content: space-between;
  background-color: var(--secondary-bg);
  width: 350px;
  border-radius: 49px;
  margin: 30px auto;
  height: 64px;
  padding: 10px 14px;
  box-sizing: border-box;

  .typeItem {
    font-size: calc(var(--font-size-add) + 18px);
    line-height: 21px;
    width: 146px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border-radius: 39px;
    cursor: pointer;
    color: var(--secondary-text);

    &.active {
      background-color: rgb(var(--primary-color));
      color: #FFFFFF;
    }
  }
}

.models {
  display: flex;
  align-items: center;

  .modelItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 110px;
  }

  .radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid var(--secondary-text);
    display: flex;
    align-items: center;
    justify-content: center;

    .selected {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: rgb(var(--primary-color));
    }
  }
}

.create {
  position: sticky;
  bottom: 0;
  height: 112px;
  background-color: var(--background-color);
  border-top: 1px solid rgba(var(--secondary-text-rbg), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 87px;
}

.historyTitle {
  height: 80px;
  border-bottom: 1px solid var(--border-color);
  padding: 28px 20px;
  box-sizing: border-box;

  .text {
    font-size: calc(var(--font-size-add) + 20px);
    font-weight: bold;
    color: var(--text-color);
    line-height: 20px;
  }
}

.listBox {
  padding: 30px 0px 110px;
  width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  .historyItem {
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 160px;
    position: relative;

    &.historyItemGen3 {
      width: 280px;
    }


    &:not(:nth-last-of-type(-n+3)):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 220px;
      height: 1px;
      background-color: rgba(221, 221, 221, 0.5);
    }

    .bottomInfo {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      .infoBox {
        display: flex;

        &:first-child {
          margin-bottom: 6px;
        }
      }
    }
  }
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 148px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;

  .moreItem {
    padding: 12px 4px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    &.del {
      color: #FF2922;
    }
  }

  .moveImg {
    color: #FF7B00;
  }
}


.onlyGen3 {

  .model {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-bg);
    border-radius: 16px;
    height: 54px;
    box-sizing: border-box;
    margin-top: 12px;
    margin-bottom: 20px;
    padding: 12px 16px;
    width: 100%;
    cursor: pointer;

    &.active {
      background-color: rgba(var(--primary-color), 0.1);

      .name,
      .price,
      .key {
        color: rgb(var(--primary-color)) !important;

        svg {
          path {
            fill: rgb(var(--primary-color));
          }
        }
      }
    }

    .info {
      display: flex;
      align-items: center;

      .icon {
        box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
        border-radius: 8px;
        background-color: #fff;
        width: 30px;
        height: 30px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .name {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: calc(var(--font-size-add) + 14px);
        font-weight: bold;
        white-space: nowrap;
        margin-left: 8px;
        margin-right: 8px;
      }

      .key {
        font-weight: 400;
        margin-left: 4px;
        color: var(--secondary-text);
      }

      .recommend {
        border-radius: 10px 15px 15px 0px;
        background-color: rgb(var(--primary-color));
        width: 44px;
        height: 22px;
        color: #fff;
        font-size: calc(var(--font-size-add) + 12px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .free {
        border-radius: 10px 15px 15px 0px;
        background: #FF7B00;
        width: 66px;
        height: 22px;
        color: #fff;
        font-size: calc(var(--font-size-add) + 12px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .price {
      display: flex;
      align-items: center;
      color: var(--secondary-text);

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: var(--secondary-text);
        }
      }
    }
  }
}

.fullbox {
  width: 100%;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    width: 150px;
    height: 150px;
  }

  .prompt {
    color: #999;
  }
}

.modelChoose {
  .title {
    display: flex;

    svg {
      margin-left: 4px;
      cursor: pointer;
    }
  }
}

.tipsFooter {
  margin-top: 30px;
  text-align: right;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 20px;
  font-weight: 400;
  color: var(--text-color);
  cursor: pointer;
}

.tipsContent {
  font-size: calc(var(--font-size-add) + 14px);
  line-height: 20px;
  color: var(--secondary-text);
  margin-top: 20px;
  max-width: 400px;
}