.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  min-width: 556px;
  height: calc(100% - 110px);
  padding-bottom: 110px;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.contentsBox {
  display: contents;
  height: calc(100% - 100px);
}

.allbox {
  flex-direction: column;
  width: 556px;
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #fff;

    .titletips {
      color: red;
      margin: 0px 3px;
    }
  }

  .titlemax {
    margin-left: 3px;
  }

  svg {
    margin-left: 3px;
    cursor: pointer;
  }

  .titletipsbox {
    width: 300px;
    height: 50px;
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
  }
}

.textareabox {
  width: 100%;
  position: relative;
  border-radius: 20px 20px 20px 20px;
  background: rgba(255, 255, 255, 0.2);
  padding-top: 2px;

  .textarea {
    padding: 10px 16px 0px;
    font-size: 16px;
    width: calc(100% - 34px);
    background: transparent;
    color: #fff;
    border-radius: 20px 20px 20px 20px;
    border: 0px solid #F5F5F5;
    resize: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }
  }
}

.tipstext {
  position: absolute;
  bottom: 3px;
  left: 20px;
  color: #FF5852;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  p {
    height: 20px;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
  }
}

.maxText {
  position: absolute;
  bottom: 5px;
  right: 20px;
  font-size: 14px;
  color: rgba(153, 153, 153, 0.6);
}

.imgboxs {
  z-index: 2;
  width: 516px;
  height: 264px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  display: flex;

  .imgbox {
    width: 160px;
    height: 236px;
    border-radius: 16px;
    overflow: hidden;
    flex-direction: column;
    position: relative;

    .image {
      width: 180px;
      height: 255px;
      position: absolute;
      top: -10px;
      left: -10px;
    }
  }

  .imgtext {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin: 5px auto 0px;
  }
}

.bgbox {
  background-image: url('../../../assets/png/tarotBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.footbutton {
  width: 100%;
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 28px;
  bottom: 34px;
  right: 0px;
}

.previewBox {
  width: 50%;
  height: 100%;
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 140px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;
  z-index: 999;

  .moreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .deletemoreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;

      path {
        fill: #FF2922
      }
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #FF2922;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}